.dashboard_sent_statistics {
    &.value_generated_statistics {
        .recharts-responsive-container {
            height: 74px!important;
            .recharts-legend-wrapper {
                display: flex;
                align-items: center;
                gap: 30px;
                padding-left: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .dashboard_sent_statistics {
        &.value_generated_statistics {
            .recharts-responsive-container {
                height: 100px!important;
                .recharts-legend-wrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 4px;
                }
            }
        }
    }
}
