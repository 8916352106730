.video_card_wrapper {
    &--items {
        display: grid;
        grid-template-columns: 2fr 3fr 3fr;
        gap: 20px;
    }
}

@media screen and (max-width: 991px) {
    .video_card_wrapper {
        &--items {
            grid-template-columns: 2fr 2fr;
        }
    }
}

@media screen and (max-width: 767px) {
    .video_card_wrapper {
        &--items {
            grid-template-columns: 1fr;
        }
    }

    .video_card {
        .ant-card-body {
            padding: 15px;
        }
    }
}