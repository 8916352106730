.header-toggle-icon-container {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  transition: all 0.3s ease-in-out;
  &.icon-rotate {
    transform: rotate(180deg);
  }
}
