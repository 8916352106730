.my_default_inside_page_left_panel {
  background-color: var(--white);
  max-width: 257px;
  min-width: 257px;
  overflow-y: auto;
  width: 100%;
  &--menu {
    border-right: 1px solid var(--borderLineColor);
    height: 100%;
    .ant-menu-item {
      align-items: center;
      border-radius: 0;
      display: flex;
      height: 48px;
      line-height: normal;
      margin: 0;
      padding-left: 15px !important;
      padding: 0 20px;
      width: 100%;
      .ant-menu-title-content {
        color: var(--textColor);
        font-size: 16px;
        font-weight: 400;
        white-space: pre-line;
      }
      &-selected {
        background-color: var(--newBackgroundGrey);
        .ant-menu-title-content {
          font-weight: 500;
        }
        &::before {
          border-radius: 0 100px 100px 0;
          border-right: 3px solid var(--mainColor);
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
        }
      }
    }
    .ant-menu-submenu-title {
      border-radius: 0;
      color: var(--black) !important;
      height: 48px !important;
      line-height: 48px !important;
      margin: 0;
      padding-left: 14px !important;
      width: 100%;
      .ant-menu-title-content {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .ant-menu-submenu {
      .ant-menu-item-only-child {
        padding-left: 46px !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .my_default_inside_page_left_panel {
    height: auto;
    max-width: 100%;
    overflow-y: hidden;
    &--menu {
      width: auto;
      display: flex;
      border-right: none;
      height: auto;
      .ant-menu-item {
        height: 40px;
        justify-content: center;
        padding: 7px 10px 10px !important;
        margin: 0 0 3px 0;
        &.active {
          background-color: var(--newLightGrey);
          border-bottom: 3px solid var(--mainColor);
        }
        .ant-menu-title-content {
          font-size: 0;
          overflow: hidden;
          margin: 0;
          display: flex;
          justify-content: center;
        }
        &-selected {
          &::before {
            border-bottom: 3px solid var(--mainColor);
            border-radius: 0;
            border-right: 0 solid #0000;
            bottom: 0;
            content: '';
            position: absolute;
            width: 100%;
          }
        }
        .ant-menu-item-icon,
        .ant-dropdown-trigger {
          display: flex;
          position: absolute;
        }
      }
    }
  }
}
