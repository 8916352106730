.mat-upload {
  .ant-upload-list-item {
    border-radius: 9px;
    border: 1px solid var(--borderLineColor);
    padding: 0;
    overflow: hidden;
  }
  .ant-upload-list-item-file {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .ant-upload-list-item-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: flex;
    }
  }
  .ant-upload-select {
    border-radius: 9px;
    border: 1px solid transparent;
    padding: 0;
    overflow: hidden;
    &:hover {
      border: 1px dashed var(--mainColor);
    }
  }
  .ant-upload-select-picture-card {
    border-radius: 8px;
    background-color: var(--lighter);
    border-style: none;
  }
}

.big-upload-wrapper {
  background-color: #fff;
  display: flex;
  box-shadow: 2px 20px 40px rgba(83, 85, 128, 0.05);
  padding: 20px;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  .ant-upload-list-picture-card {
    width: 100%;
  }
  .ant-upload-select {
    max-width: 100%;
    border: 2px dashed #93bdff !important;
    background-color: #f9fbff !important;
    border-radius: 8px;
    height: 193px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100% !important;
    cursor: pointer;
    height: 193px !important;
    .ant-upload {
      width: 100%;
    }
    &.ant-upload-disabled {
      filter: grayscale(1);
      .big-upload-text {
        .big-upload-title {
          .no-file {
            max-width: 100%;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .big-upload-text {
    .big-upload-title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      .no-file {
        color: var(--secondaryTextColor);
        font-weight: 400;
        font-size: 14px;
        max-width: 160px;
        text-align: center;
        white-space: initial;
        line-height: 25px;
        margin-top: 20px;
        strong {
          color: var(--mainColor);
        }
      }
    }
  }
}

.ant-upload-list-picture-card
  .ant-upload-list-item-file
  + .ant-upload-list-item-name {
  display: none;
}
