.send-invitation-modal-image-previews {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    max-height: 300px;
  
    .lang-card + .lang-card {
      margin-left: 20px;
    }
  
    .ant-empty {
      margin: 0;
    }
  
    .lang-card {
      width: 50%;
  
      h3 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 12px;
        color: var(--black);
        width: 90%;
        text-align: center;
      }
  
      .image-wrapper {
        display: flex;
        height: 250px;
  
        padding: 5px;
        border: dashed 2px transparent;
        border-radius: 3px;
        cursor: pointer;
  
        img {
          opacity: 0.5;
          object-fit: contain;
          width: 100%;
          max-width: 100%;
  
          height: auto;
        }
      }
  
      &.selected {
        .image-wrapper {
          border-color: var(--secondaryTextColor);
  
          img {
            opacity: 1;
          }
        }
      }
    }
}

@media screen and (max-width: 991px) {
  .send-invitation-modal-image-previews {
    .lang-card {
      .image-wrapper {
        height: 230px;
      }
    }
  }
}