.superhuman_snoozed_block_wrapper {
  margin: 0 15px;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  color: var(--thridyTextColor);
  border: 2px solid transparent;
  position: relative;
  background: var(--whiteBgGradient);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-size: 200%;
  animation: borderAnimation 2s infinite linear;
  .ant-tag {
    border-radius: 100px;
    border-style: none;
    display: flex;
    align-items: center;
  }
  .title-tag {
    padding: 3px 8px 4px 8px;
    background-color: var(--mainColorLighter);
    color: var(--mainColor);
    font-size: 10px;
    font-weight: 600;
  }
  .snooze-tag {
    font-size: 12px;
    font-weight: 500;
    color: var(--black);
    background-color: var(--mainColorLighter);
    padding: 3px 12px;
    height: 24px;
    &:hover,
    &:disabled {
      color: var(--black) !important;
      background-color: var(--mainColorLighter) !important;
    }
    &:disabled {
      opacity: 0.25;
    }
  }
}

.center-sms-drip-wrapper + .superhuman-snoozed-block-wrapper {
  margin-top: 5px;
}
