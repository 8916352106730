.mat-switch-wrapper {
  .switch-text {
    margin-left: 12px;
  }
  .switch-label {
    height: auto;
    text-align: left;
    letter-spacing: 0.58px;
    color: var(--thridyTextColor);
    text-transform: uppercase;
    opacity: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    svg {
      position: relative;
      bottom: 2px;
      margin-right: 12px;
    }
  }
}

.mat-switch {
  height: 24px;
  line-height: 24px;
  width: 42px;
  min-width: 42px;
  &.ant-switch-checked {
    background-color: var(--mainColor) !important;
    .ant-switch-handle {
      inset-inline-start: calc(100% - 21px);
    }
  }
  &:focus {
    box-shadow: none;
  }
  .ant-switch-handle {
    width: 18px;
    height: 18px;
    top: 3px;
    inset-inline-start: 4px;
    &::before {
      box-shadow: -3px 3px 11px fade(#000, 25%);
      border-radius: 50%;
    }
  }
}

.mat-switch-black {
  .switch-label {
    color: var(--textColor);
    font-weight: 600;
    text-transform: capitalize;
  }
}
