

.mat-user-profile-letters {
  position: relative;
  text-align: center;
  border-radius: 50%;
  font-family: 'SFPro', sans-serif;
  font-size: 10px;
  font-weight: 700;
  color: #2e7ef6;
  background-color: #edf4ff;
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: 7px;
  line-height: 22px;
  &.medium {
    width: 36px;
    min-width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 12px !important;
  }
  &.red {
    color: var(--red);
    background-color: var(--lightRedSecondary);
  }
  &.green {
    color: var(--green);
    background-color: var(--greenLight);
  }
  &.yellow {
    color: #ffa820;
    background-color: var(--orangeLight);
  }
  &.blue {
    color: #2e7ef6;
    background-color: #edf4ff;
  }
  .active-icon {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--green);
  }
}
[data-theme='dark'] {
  .mat-user-profile-letters {
    color: var(--blue);
    background: rgba(63, 140, 255, 0.30);
    &.red {
      color: var(--red);
      background: rgba(224, 93, 76, 0.30);
    }
    &.green {
      color: var(--green);
      background: rgba(72, 175, 128, 0.30);
    }
    &.yellow {
      color: #ffa820;
      background: rgba(234, 153, 87, 0.30);
    }
    &.blue {
      color: var(--blue);
      background: rgba(63, 140, 255, 0.30);
    }
  }
}

.mat-user-profile-letters-customer-wrapper {
  width: 52px;
  height: 52px;
  border: 4px solid rgba(120, 84, 246, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-user-profile-letters-customer {
  background-color: #f1edfe;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #7854f6;
  font-weight: bold;
  line-height: 140%;
}

.mat-user-profile-letters-customer {
  .active-icon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    position: absolute;
    bottom: 2px;
    right: 2px;
    &::before {
      content: '';
      position: absolute;
      background-color: var(--green);
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
}

.mat-user-profile-numbers {
  font-size: 12px;
}

.mat-user-profile-letters-wrapper {
  &.with-icon {
    position: relative;
    svg {
      width: 62px;
      height: 62px;
    }
    .mat-user-profile-letters {
      width: 52px;
      height: 52px;
      line-height: 52px;
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
}

.mat-user-profile-letters.temprature-letter {
  background-color: #cce1ff;
}

.mat-user-profile-letters {
  .temperature-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
  &.temprature-letter {
    .mat-user-profile-letters {
      line-height: 43px;
    }
  }
}

.mat-user-profile-letter-progress {
  .ant-progress-text {
    display: flex;
    align-items: center;
    justify-content: center;
    .mat-user-profile-letters-customer {
      span {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        text-transform: capitalize;
        // p {
        //   font-size: 16px;
        //   margin: 0;
        // }
      }
    }
  }
}

.mat-user-profile-letters.very_cold,
.mat-user-profile-letters-customer.very_cold {
  color: #0049c6;
  background-color: #edf4ff;
}

.mat-user-profile-letters.cold,
.mat-user-profile-letters-customer.cold {
  color: #3f8cff;
  background-color: #edf4ff;
}

.mat-user-profile-letters.warm,
.mat-user-profile-letters-customer.warm {
  color: #ea9957;
  background-color: #fff8f0;
}

.mat-user-profile-letters.hot,
.mat-user-profile-letters-customer.hot {
  color: #e06f4c;
  background-color: #ffeee8;
}

.mat-user-profile-letters.very_hot,
.mat-user-profile-letters-customer.very_hot {
  color: #d44431;
  background-color: #ffeee8;
}
