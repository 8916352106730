.message_main_wrapper {
    .message-create-new-button {
        position: fixed;
        bottom: 30px;
        left: 305px;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        background-color: var(--mainColor);
        color: var(--white);
        text-align: center;
        justify-content: center;
        align-items: center;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        display: flex;
        cursor: pointer;
        transition: all 0.2s;
    
        &.message-button-menu-open {
          left: 473px;
        }
    }
}

@media screen and (max-width: 1190px) {
  .message_main_wrapper {
    .message-create-new-button {
      left: 252px;
    }
  }
}

@media screen and (max-width: 991px) {
  .message_main_wrapper {
    .message-create-new-button {
      left: 245px;
    }
  }
}

@media screen and (max-width: 767px) {
  .message_main_wrapper {
    .message-create-new-button {
      right: 20px;
      left: auto;
      &.message-create-new-button {
        left: auto;
      }
    }
  }
}