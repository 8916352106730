.trigger-tag {
  padding: 2px 14px;
  align-items: center;
  border-radius: 200px;
  background: #eeeffc;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: #5b66ea;
  font-size: 12px;
}

.badge-count {
  display: flex;
  height: 20px;
  padding: 5px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 200px;
  background: #3f8cff;
  font-size: 10px;
  color: #fff;
  margin-left: 12px;
}

.welcome_title {
  font-size: 16px;
}

.mat-confirm-popup {
  z-index: 999999;
}

video {
  border-radius: 4px;
  object-fit: cover;
}

.triggers-tooltip {
  max-height: 200px;
  overflow-y: auto;

  .trigger-item {
    padding: 4px 8px;
    white-space: nowrap;
    color: #fff;
    
    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

// Override antd tooltip styles
.ant-tooltip-inner {
  min-width: 150px;
  max-width: 300px;
}