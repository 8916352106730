.dashboard_block {
  background-color: var(--purple);
  border: 1px solid var(--darkPurple);
  color: var(--white);
  overflow: hidden;
  border-radius: 16px;
  .ant-card-body {
    height: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 22px 24px;
    &::after, &::before {
      display: none;
    }
  }
  &--blurred-part {
    width: 100%;
    height: 162px;
    border-radius: 100px;
    opacity: 0.2;
    filter: blur(70px);
    position: absolute;
    bottom: -116px;
    &.green {
      background-color: #2ed12a;
    }
    &.purple {
      background-color: #777cfd;
    }
    &.pink {
      background-color: #ee30b9;
    }
    &.yellow {
      background-color: #eed030;
    }
    &.gradient {
      background: linear-gradient(
        91deg,
        #18f5f5 8.74%,
        #f5b7d2 29.88%,
        #e744f6 52.82%,
        #862dfb 69.91%,
        #00dbf9 95.1%
      );
    }
  }
  &--header {
    margin-bottom: 32px;
    &--right {
      z-index: 2;
      width: 100%;
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        width: calc(100% - 20px);
        // display: inline-block;
        & + span {
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          color: var(--secondaryTextColor);
        }
        // span {
        //     display: inline;
        // }
      }
    }
  }
  .ant-typography {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.32px;
    color: var(--white);
  }
  &--tooltip-progress {
    position: relative;
    width: 220px;
    .ant-progress {
      width: auto;
      display: flex;
      .ant-progress-outer {
        width: 220px !important;
        .ant-progress-inner {
          width: 100%;
          height: 32px;
          border-radius: 8px;
          background-color: rgba(246, 247, 251, 12%);
          .ant-progress-bg-inner {
            border-radius: 0px;
            height: 100% !important;
          }
        }
      }
    }
    .ant-tooltip {
      .ant-tooltip-inner {
        background-color: #363050;
        border: 1px solid rgba(255, 255, 255, 0.12);
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.4px;
        min-width: 45px;
        max-width: 45px;
        width: 45px;
        border-radius: 5px;
        text-align: center;
        padding: 6px 8px;
      }
      .ant-tooltip-arrow {
        bottom: 1px !important;
        &::before {
          border-radius: 0;
          background-color: #363050;
        }
        &::after {
          border-radius: 0;
          border: 1px solid rgba(255, 255, 255, 0.12);
        }
      }
      &::after {
        content: '';
        position: absolute;
        height: 30px;
        width: 1px;
        left: 48%;
        background: repeating-linear-gradient(
            to bottom,
            transparent 0 2px,
            black 10px 10px
          )
          80%/2px 100% no-repeat;
        top: 45px;
      }
      &::before {
        content: '';
        position: absolute;
        border-top: 6px solid black;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        height: 0;
        width: 5px;
        bottom: -14px;
        left: 39.5%;
      }
      &.purple {
        &::after {
          background: repeating-linear-gradient(
              to bottom,
              transparent 0 2px,
              #7d71ff 10px 10px
            )
            80%/2px 100% no-repeat;
        }
        &::before {
          border-top-color: #7d71ff;
        }
      }
      &.pink {
        &::after {
          background: repeating-linear-gradient(
              to bottom,
              transparent 0 2px,
              #f97e7b 10px 10px
            )
            80%/2px 100% no-repeat;
        }
        &::before {
          border-top-color: #f97e7b;
        }
      }
      &.green {
        &::after {
          background: repeating-linear-gradient(
              to bottom,
              transparent 0 2px,
              #48af81 10px 10px
            )
            80%/2px 100% no-repeat;
        }
        &::before {
          border-top-color: #48af81;
        }
      }
    }
  }
  &.underline_animation {
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 26.7px 0px rgba(91, 102, 234, 0.32);
      .ant-typography,
      .underline_animation--text {
        background-size: 100% 100%;
      }
    }
    .underline_animation--text,
    .ant-typography {
      background-image: linear-gradient(
        transparent calc(100% - 2px),
        var(--white) 2px
      );
      background-repeat: no-repeat;
      background-size: 0% 100%;
      transition: background-size 1s;
    }
  }
  &.underline_animation_parent {
    &:has(.underline_animation--text-first:hover) {
      box-shadow: 0px 0px 26.7px 0px rgba(91, 102, 234, 0.32);
      .underline_animation--text-first {
        .dashboard_block--header--right, article[level="1"] {
          background-size: 100% 100%;
        }
      }
    }
    &:has(.underline_animation--text-second:hover) {
      box-shadow: 0px 0px 26.7px 0px rgba(91, 102, 234, 0.32);
      .underline_animation--text-second {
        p, span {
          background-size: 100% 100%;
        }
      }
    }
    &:has(.underline_animation--text-third:hover) {
      box-shadow: 0px 0px 26.7px 0px rgba(91, 102, 234, 0.32);
      .underline_animation--text-third {
        p, span {
          background-size: 100% 100%;
        }
      }
    }
    .underline_animation--text {
      &-first, &-second, &-third {
        cursor: pointer;
        p, span, .dashboard_block--header--right, article[level="1"] {
          background-image: linear-gradient(
            transparent calc(100% - 2px),
            var(--white) 2px
          );
          background-repeat: no-repeat;
          background-size: 0% 100%;
          transition: background-size 1s;
        }
        span {
          background-image: linear-gradient(
           transparent calc(100% - 2px),
           var(--secondaryTextColor) 2px
         );
        }
      }
    }
  }
  &--tooltip {
    position: relative;
    bottom: 1px;
    display: inline-block;
    &-block {
      display: inline-block;
    }
    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: -12px;
      margin-left: 4px;
      background-image: url('../../../../icons/dashboard_info_icon.png');
    }
    &.white {
      &::after {
        background-image: url('../../../../icons/dashboard_info_icon_white.png');
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard_block {
    .ant-card-body {
      padding: 15px;
    }
  }
}
