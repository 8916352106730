.my_default_table_list {
  .ant-table-container {
    border: 1px solid var(--newGrey);
    border-start-start-radius: 12px;
    border-start-end-radius: 12px;
    &::after {
      border-radius: 0 12px 0 0;
    }
    &::before {
      border-radius: 12px 0 0 0;
    }
  }
  .ant-table {
    line-height: normal;
    .ant-table-header {
      border-radius: 12px 12px 0 0;
    }
  }
  .ant-table-thead > tr > th {
    background-color: var(--newLightGrey);
    padding: 13px 20px;
    font-size: 12px;
    font-weight: 500;
    color: var(--thridyTextColor);
    border-bottom: 1px solid var(--newGrey);
    line-height: 18px;
    &:first-child {
      border-start-start-radius: 12px !important;
    }
    &:last-child {
      border-start-end-radius: 12px !important;
    }
    &::before {
      display: none;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 20px;
    background-color: var(--white);
    border-bottom: 1px solid var(--newGrey);
    &:has(.ant-table-expanded-row-fixed) {
      background-color: var(--newLightGrey);
    }
    .ant-table-expanded-row-fixed {
      padding: 20px;
      margin: -20px;
    }
  }
  .ant-table-column-sorter {
    color: var(--thridyTextColor);
    .ant-table-column-sorter-up, .ant-table-column-sorter-down {
      &.active {
        color: var(--mainColor);
      }
    }
  }
  .ant-table-sticky-scroll {
    bottom: -23px!important;
  }
  .ant-table-empty {
    .ant-table-tbody > tr > td:has(.ant-table-expanded-row-fixed) {
      background-color: var(--white);
    }
  }
}

@media screen and (max-width: 479px) {
  .my_default_table_list {
    .ant-table-empty {
      .ant-table-header {
        table {
          table-layout: auto!important;
        }
      }
    }
    .ant-table-tbody > tr > td {
      padding: 15px;
    }
    .ant-table-thead > tr > th {
      padding: 13px 15px;
    }
    .ant-table-sticky-scroll {
      display: none;
    }
  }
}
