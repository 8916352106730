.my_default_block_collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
      .ant-collapse-header-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--black);
      }
    }
    .ant-collapse-content-box {
      padding: 0;
      padding-block: 16px 0 !important;
      border-top: 1px solid var(--borderLineColor);
      margin-top: 16px;
    }
  }
  &.first_item_switch {
    .ant-collapse-content-box {
      padding-block: 12px 0 !important;
    }
  }
}
