.message_right_panel {
  &--customer-info {
      display: flex;
    &-letters {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-details {
        width: 100%;
        padding-left: 10px;
        overflow: hidden;
        &-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .customer_name {
                font-size: 16px;
                color: var(--black);
                padding-right: 10px;
                font-weight: 600;
                line-height: 140%;
                display: flex;
                align-items: center;
                gap: 8px;
                overflow: hidden;
                .name {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .anticon {
                display: flex;
                cursor: pointer;
            }
        }
        .customer_contact {
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--secondaryTextColor);
            margin-top: 4px;
            white-space: nowrap;
            width: 100%;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            display: flex;
            align-items: center;
            gap: 8px;
            .anticon {
                cursor: pointer;
            }
            + .customer_contact {
                margin-top: 0;
            }
        }
    }
    .mat-btn + .mat-btn {
        margin-left: 0;
    }
  }
}