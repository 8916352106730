.my_default_button_ai_reply_button_wrapper {
  position: relative;
  display: inline-flex;
  margin-right: 4px;
  &:hover {
    .my_default_button_ai_reply_button_wrapper--background {
      opacity: 1;
      inset: -1px;
      transition-duration: 0.2s;
    }
  }
  &--background {
    position: absolute;
    inset: 0;
    border-radius: 1rem;
    background: linear-gradient(to right, #44bcff, #ff44ec, #ff675e);
    filter: blur(2px);
    opacity: 0.7;
    transition: all 1s;
  }
  .my_default_button_ai_reply_button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
    font-weight: 500;
    font-size: 14px;
    background: var(--whiteBgGradient);
    transition: all 0.2s;
    outline: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    padding: 0 10px !important;
    height: 30px !important;
    border: 1px solid transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 200%;
    animation: borderAnimation 2s infinite linear;

    &:disabled {
      cursor: not-allowed;
    }
  }
}
