.message_page_broadcast_filter {
    border: 1px solid var(--mainColor);
    background-color: var(--mainColorLighter);
    padding: 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 10px 16px;
    .ant-space-item {
      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
      &:first-child {
        span {
          color: var(--thridyTextColor);
        }
      }
      &:last-child {
        span {
          font-weight: 600;
          color: var(--black);
        }
      }
    }
}