.intent_preview_left {
  padding: 20px;
  width: 50%;
  .header {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .title {
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
    margin-bottom: 4px;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    max-width: 235px;
    margin-bottom: 26px;
  }
  .button {
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    height: 46px;
    line-height: 20px;
    max-width: 250px;
    width: 100%;
    margin: 12px 0;
  }
  .terms {
    color: var(--secondaryTextColor);
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    max-width: 302px;
    a {
      color: var(--secondaryTextColor);
      text-decoration: underline;
      margin-left: 2px;
    }
  }
  .copyright {
    margin-top: 90px;
    p {
      color: var(--thridyTextColor);
      font-size: 10px;
      padding-right: 5px;
    }
    img {
      max-width: 100%;
      height: auto;
      width: 65px;
    }
  }
}

.intent_preview_right {
  width: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
