.conversation_page_center_footer_mobile {
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-content-wrapper,
  .ant-drawer-content {
    border-radius: 32px 32px 0 0;
  }
  .swipeable-container {
    display: grid;
    gap: 12px;
  }
  .ant-drawer-body {
    padding: 30px 16px 16px 16px;
  }
  .message_footer_small_ui_button {
    position: absolute;
    right: 15px;
    top: 15px;
    margin: 0 !important;
    background-color: transparent;
    &--mobile {
      width: 49px;
      height: 5px;
      background-color: #D9D9D9;
      border-radius: 100px;
      margin: -10px auto 0;
      svg {
        display: none;
      }
    }
  }
  .icon-size,
  .mat-btn.icon-size {
    min-width: auto;
    max-width: none;
    width: fit-content;
    height: auto;
    padding: 0;
    gap: 8px;
    &.active {
      background-color: transparent !important;
    }
    .ant-btn-icon {
      width: 36px;
      min-width: 36px;
      max-width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: var(--mainColor);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        path,
        circle,
        ellipse, 
        rect {
          stroke: var(--white);
        }
      }
      .anticon-spin {
        svg {
          path {
            fill: var(--white);
          }
        }
      }
      .icon_size_reminder {
        svg {
          path {
            stroke: transparent;
            fill: var(--white);
          }
        }
      }
    }
    &.only-stroke {
      .ant-btn-icon {
        svg {
          path {
            stroke: var(--white);
          }
        }
      }
      &:hover {
        .ant-btn-icon {
          svg {
            path,  circle,
            ellipse, rect {
              stroke: var(--white);
            }
          }
        }
      }
    }
    span + span {
      color: var(--black);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    &.ant-popover-open {
      background-color: transparent !important;
      .ant-btn-icon {
        svg {
          path,
          circle,
          ellipse {
            stroke: var(--white)!important;
          }
        }
      }
    }
    &:hover {
      background-color: transparent !important;
      .ant-btn-icon {
        svg {
          path,
          circle,
          ellipse {
            stroke: var(--white);
          }
        }
        .icon_size_reminder {
          svg {
            path {
              stroke: transparent;
              fill: var(--white);
            }
          }
        }
      }
    }
  }
  .mat-template-select:not(.ant-select-customize-input) {
    height: auto !important;
    display: flex;
    align-items: center;
    width: fit-content;
    .ant-select-selector {
      min-width: auto;
      max-width: none;
      width: 105px;
      height: 36px;
      padding: 0;
      display: block;
      z-index: 100;
    }
    .ant-select-arrow {
      right: auto;
      left: auto;
      min-width: auto;
      max-width: none;
      height: 36px;
      width: fit-content;
      .anticon {
        min-width: 36px;
        max-width: 36px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: var(--mainColor);
        padding: 0;
        svg {
          path {
            fill: var(--white);
          }
        }
      }
      span {
        display: flex;
        align-items: center;
        gap: 8px;
       + span {
          color: var(--black);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
    &:hover {
      .anticon {
        background-color: var(--mainColor);
      }
    }
  }
  .mat-upload {
    display: flex;
    .ant-upload-select:hover {
      border: 1px solid transparent;
    }
  }
}
