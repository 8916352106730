.my_default_table_header {
  margin-bottom: 20px;
  &--left {
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
  }
  &--right {
    span {
      font-size: 14px;
      font-weight: 600;
    }
    &-action_title {
      color: var(--thridyTextColor);
    }
    &-action_by {
      color: var(--black);
    }
  }
}

@media screen and (max-width: 479px) {
  .my_default_table_header {
    &--right {
      gap: 10px !important;
    }
  }
}
