.dashboard_users_page {
    &--user-type {
        height: 24px;
        border-radius: 20px;
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 0px 14px;
    }
    &--inputs {
        width: 40%;
        margin-bottom: 24px;
        gap: 12px;
    }
    &--email-phone {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--secondaryTextColor);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .mat-table-wrapper {
        .ant-table-column-title {
            flex: content;
            white-space: pre-line;
            width: 100%;
        }
    }
}

@media screen and (max-width: 991px) {
    .dashboard_users_page {
        &--inputs {
            width: 50%;
        }
    }
}

@media screen and (max-width: 767px) {
    .dashboard_users_page {
        &--inputs {
            width: 100%;
        }
    }
}
