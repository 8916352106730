.dashboard_sent_statistics_bar_chart {
    &--header {
        justify-content: space-between;
        margin-left: 10px;
        width: 100%;
        min-height: 116px;
        &-select-item {
            width: 100%;
            border-right: 1px solid rgb(56, 51, 77);
            padding-right: 32px;
            margin-right: 32px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            .mat-select-wrapper {
                .mat-select-sub-wrapper.select-with-icon {
                    height: 44px;
                    .select-left-icon {
                        width: fit-content;
                        height: fit-content;
                        left: 4px;
                        border-right: none;
                    }
                    .mat-select {
                        .ant-select-selector {
                            padding-left: 50px;
                            .ant-select-selection-placeholder, .ant-select-selection-item {
                                color: var(--white);
                            }
                        }
                        .ant-select-arrow {
                            color: var(--white);
                        }
                    }
                }
            }
        }
        &-leads-item {
            width: 100%;
            .dashboard_sent_statistics--information--hours {
                margin-top: 0;
                display: grid;
                grid-template-columns: 2fr 1fr 2fr;
                > :nth-child(n + 4) {
                    margin-top: 20px;
                }
                .ant-divider {
                    margin: 0 15px 0 10px;
                }
                .dashboard_block--tooltip {
                    bottom: 0;
                    &::after  {
                        margin-left: -4px;
                    }
                }
            }
        }
    }
    .recharts-wrapper {
        .recharts-legend-wrapper {
            display: flex;
            justify-content: center;
            .recharts-default-legend {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgba(255, 255, 255, 0.12);
                border-radius: 100px;
                padding: 2px 12px!important;
                width: fit-content;
                .recharts-legend-item {
                    display: flex!important;
                    align-items: center;
                    margin-right: 16px!important;
                    .recharts-surface {
                        margin-right: 8px!important;
                    }
                    &:last-child {
                        margin-right: 0!important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .dashboard_sent_statistics_bar_chart {
        &--header {
            flex-direction: column;
            padding-right: 10px;
            &-select-item {
                margin-right: 0;
                padding-right: 0;
                border-right: none;
                .mat-select-wrapper  {
                    margin-bottom: 16px;
                }
            }
            &-leads-item {
                margin-top: 16px;
            }
        }
    }
}

@media screen and (max-width: 479px) {
    .dashboard_sent_statistics_bar_chart {
        .recharts-wrapper {
            .recharts-legend-wrapper {
                .recharts-default-legend {
                    flex-wrap: wrap;
                }
            }
        }
    }
}