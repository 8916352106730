

.mat-input-number-wrapper {
  border: 1px solid var(--grey);
  border-radius: 4px;
  height: 48px;
  padding: 10px;
  cursor: text;
  width: 100%;
  outline: none;

  .ant-input-number-input {
    height: 100%;
  }

  &.ant-input-number-focused {
    border-color: var(--mainColor);
  }

  &:hover,
  &.mat-custom-input-number-wrapper:focus {
    border-color: var(--mainColor);
    background-color: var(--mainColorLighter);
    box-shadow: none;
  }
}

.mat-custom-input-number-wrapper {
  padding: 10px 10px 10px 17px;
  font-size: 14px;
  font-weight: 400;
  color: var(--textColor);

  &:disabled {
    cursor: not-allowed;

    &:hover {
      border-color: rgba(118, 118, 118, 0.3);
      background-color: var(--lighter);
    }
  }
}

.ant-input-number-input-wrap {
  display: flex;
  align-items: center;
}

.ant-input-number-affix-wrapper {
  input.ant-input-number-input {
    height: auto;
  }

  &-status-error {
    &:not(&-disabled):not(&-borderless) {
      background-color: #ffebe9 !important;
      border-color: #e05d4c !important;
    }
  }
}

.mat-input-affix-secondary-wrapper {
  > div.ant-input-number {
    background-color: transparent;
  }
}
