

.mat-label {
  padding: 0 0 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  label {
    height: auto;
    text-align: left;
    color: var(--thridyTextColor);
    text-transform: uppercase;
    opacity: 1;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    .anticon {
      margin-left: 3px;
    }
  }
  &.black {
    padding: 0 0 6px;
    line-height: 18px;
    label {
      color: var(--textColor);
      font-weight: 600;
      text-transform: capitalize;
    }
  }
  svg {
    margin-right: 3px;
    padding: 2px;
    border-radius: 50%;
    background-color: var(--mainColorLighter);
  }
}

[data-theme='dark'] {
  .mat-label {
    &.black {
      label {
        color: var(--secondaryTextColor);
      }
    }
  }
}
