.center_message_interests {
    display: flex;
    align-items: center;
    margin-top: 12px;
    .anticon {
      margin-top: 4px;
    }
    &--title {
      font-size: 12px;
      font-weight: 600;
      line-height: 140%;
      margin-left: 7px;
      color: var(--black);
      margin-top: 4px;
    }
    .info_value {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .border_item {
        margin: 4px 0px 0px 4px;
        height: 26px;
        padding: 4px 10px 6px 10px;
        background-color: var(--newLightGrey);
        border-radius: 100px;
        color: var(--black);
        font-size: 10px;
        line-height: 10px;
        font-weight: 600;
        display: flex;
        align-items: center;
        white-space: nowrap;
        letter-spacing: 0.2px;
      }
    }
    .ant-carousel {
      width: 100%;
      padding: 0 0 0 12px;
      overflow: hidden;
      .slick-track {
        display: flex;
        align-items: center;
        width: 100% !important;
      }
    }
}