.message_center_panel {
    &--center-list {
        .message {
            .show-all-media {
                display: flex;
                cursor: pointer;
                color: var(--mainColor);
                margin-bottom: 7px;
            }
            &.right {
                .show-all-media {
                    flex-direction: row-reverse;
                }
            }
        } 
    }
}