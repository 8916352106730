.tag_item {
  background-color: var(--newBackgroundGrey);
  border-radius: 12px;
  overflow: hidden;
  height: 50px;
  padding: 0px 15px;
  position: relative;
  &--left {
    &-pin {
      height: 100%;
      position: absolute;
      left: 0;
      width: 3px;
      top: 0;
    }
    span + span {
      line-break: anywhere;
      line-height: 14px;
    }
    .my_default_input {
      height: 34px;
      padding: 5px 8px;
    }
  }
  &--right {
    &--colors {
      &-item {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        transition: 0.3s ease-in-out;
        &.selected {
          transform: scale(1.8);
        }
      }
    }
  }
}
