.message_center_panel {
    .message-center-type-wrapper {
        .message-center-text-input-wrapper {
            .send-message-img {
                .upload-files {
                    width: 100%;
                    border-bottom: 1px solid var(--borderLineColor);
                    display: flex;
                    padding: 4px 0 4px 10px;
                    overflow-x: auto;
                }
                  background-color: var(--lighter);
                  border-radius: 10px 10px 0 0;
                  video {
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    background-color: var(--white);
                  }
                  .single-img {
                    position: relative;
                  }
                  .img-close {
                    z-index: 2;
                    top: 4px;
                    right: 3px;
                  }
            }
            .invalid-file {
              display: flex;
              padding: 4px 0;
            
              .img-close {
                margin-top: -5px !important;
                margin-left: 41px !important;
            
                &:hover {
                  display: block;
                }
              }
            }
        }
    }
}

.single-pdf {
  text-decoration: none;
  color: var(--red);

  &.file-center {
    width: 70px;
    height: 65px;
    margin: 4px;
    background-color: var(--lighter);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .anticon-file-pdf {
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    border-radius: 6px;
  }

  &:hover {
    .img-close {
      display: flex;
    }
  }
}

.single-video {
  background-color: var(--white);
  border-radius: 6px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}