.message_main_wrapper {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: space-between;
  height: 100%;
  .anticon,
  svg {
    overflow: visible;
  }
}

.drop-menu-auto-detect-language-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        .drop-item {
          .drop-item-icon-with-text {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.empty-mobile-conversation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  p {
    color: var(--black);
    font-size: 24px;
    font-weight: 500;
    width: 294px;
    text-align: center;
    line-height: 31px;
    margin-top: 12px;
  }
}
