.messages_left_panel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 319px;
  min-width: 319px;
  overflow-x: hidden;
  border-top: 1px solid var(--borderLineColor);
  border-right: 1px solid var(--borderLineColor);
  &--header {
    &--search-panel {
      padding: 10px 16px;
    }
  }
  .messages_left_conversations_list {
    overflow-x: hidden;
    height: calc(100vh - 48px);
    padding: 0 16px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .messages_left_panel {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .mat_layout_content_for_extension {
    .message_main_wrapper {
      .messages_left_panel {
        .messages_left_conversations_list {
          height: calc(100vh - 160px);
        }
      }
    }
  }
}