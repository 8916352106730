.mat-select-tree-form-dropdown {
  padding: 10px 0;
  border: 1px solid var(--grey);
  border-radius: 8px;
  box-shadow: 4px 7px 40px fade(#000, 5%);
  .ant-select-tree-checkbox-checked {
    .ant-select-tree-checkbox-inner {
      background-color: var(--mainColor);
      border-color: var(--mainColor);
    }
  }
  .ant-select-tree-checkbox {
    margin: 2px 3px 0 0;
    .ant-select-tree-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 5px;
    }
    .ant-select-tree-checkbox-checked {
      &::after {
        border-radius: 5px;
      }
      .ant-select-tree-checkbox-inner::after {
        top: 45%;
        left: 33%;
        width: 5px;
        height: 10px;
      }
    }
  }
  .ant-select-tree-node-content-wrapper:hover {
    background-color: var(--mainColorLighter);
  }
  .ant-select-tree-checkbox-indeterminate {
    .ant-select-tree-checkbox-inner:after {
      background-color: var(--mainColor);
    }
  }
}

.mat-select-tree-form-dropdown
  .ant-select-tree-checkbox-wrapper:hover
  .ant-select-tree-checkbox-inner,
.mat-select-tree-form-dropdown
  .ant-select-tree-checkbox-wrapper:hover
  .ant-select-tree-checkbox-inner,
.mat-select-tree-form-dropdown
  .ant-select-tree-checkbox:hover
  .ant-select-tree-checkbox-inner,
.mat-select-tree-form-dropdown
  .ant-select-tree-checkbox-input:focus
  + .ant-select-tree-checkbox-inner,
.mat-select-tree-form-dropdown .ant-select-tree-checkbox-checked::after {
  border-color: var(--mainColor);
}

.ant-select-tree-switcher {
  line-height: 20px;
}

.ant-select-tree-checkbox-indeterminate {
  .ant-select-tree-checkbox-inner::after {
    background-color: var(--mainColor);
  }
}
