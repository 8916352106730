element {
  -webkit-overflow-scrolling: touch;
}

element > * {
  transform: translateZ(0px);
  -webkit-transform: translateZ(0px);
}

* {
  margin: 0px;
  padding: 0px;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  font-family: 'SFPro', sans-serif !important;
  [data-theme='dark'] {
    scrollbar-color: var(--secondaryTextColor) var(--purple);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

body {
  font-family: 'SFPro', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: var(--black);
  background-color: var(--white);
  &[data-theme='dark'] {
    background-color: var(--darkBackground);
  }
}

.body_class {
  touch-action: none;
  * {
    touch-action: none;
  }
}

p {
  margin-bottom: 0;
}

a {
  color: var(--black);
  text-decoration: none;
  transition:
    all,
    0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

// Fix for Safari inner shadows
textarea,
input {
  -webkit-appearance: none;
}

label {
  margin-bottom: 0;
}

button {
  &:focus {
    outline: none;
  }
}

textarea {
  box-shadow: none;
}

.w-100 {
  width: 100%;
}

.ant-row {
  width: 100%;
}

.notify-div-icon-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;

  .anticon {
    margin-right: 10px;
  }
}

.ant-popconfirm-buttons {
  .mat-btn:first-child {
    background-color: var(--white);
    font-size: 12px;
    color: var(--secondaryTextColor);
    border: 1px solid rgba(163, 166, 190, 50%);
  }
}

.ant-spin-dot-item {
  background-color: var(--mainColor);
}

.ant-steps {
  .ant-steps-item {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        width: 30px;
        height: 30px;
        background-color: transparent;
        border-radius: 30px;
        border: 2px solid var(--newLightGrey);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--white);

        .ant-steps-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: normal;
          left: 0;
          background-color: var(--newGrey);
          width: 10px;
          height: 10px;
          border-radius: 50%;

          svg {
            display: none;
          }
          .step-icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .ant-steps-item-tail {
        &::after {
          height: 2px;
          background-color: var(--newLightGrey);
        }
      }

      .ant-steps-item-content {
        margin-top: 13px;
      }

      .ant-steps-item-title {
        color: var(--thridyTextColor);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .ant-steps-item-description {
        font-size: 12px;
        color: rgba(119, 128, 141, 50%);
        font-weight: 400;
      }
    }

    &.ant-steps-item-process {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          border: 2px solid var(--mainColor);
          background-color: var(--mainColor);

          .ant-steps-icon {
            background-color: var(--white);
            width: 10px;
            height: 10px;
            border-radius: 50%;

            svg {
              display: none;
            }
          }
        }

        .ant-steps-item-title {
          color: var(--black);
          font-weight: bold;
        }

        &:hover {
          .ant-steps-item-icon {
            border-color: var(--mainColor) !important;
          }
        }
      }
    }

    &.ant-steps-item-finish {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          border: 2px solid var(--green);
          background-color: var(--green);

          .ant-steps-icon {
            background-color: transparent;
            svg {
              display: block;
            }
          }
        }

        .ant-steps-item-tail {
          &::after {
            background-color: var(--green);
          }
        }

        &:hover {
          .ant-steps-item-icon {
            border-color: var(--green) !important;
          }
        }
      }
    }

    &:not(.ant-steps-item-active):not(.ant-steps-item-process) {
      .ant-steps-item-container[role='button'] {
        &:hover {
          .ant-steps-item-icon {
            border-color: var(--grey);
          }
        }
      }
    }

    &:not(.ant-steps-item-active) {
      .ant-steps-item-container[role='button'] {
        &:hover {
          .ant-steps-item-title {
            color: var(--secondaryTextColor);
          }

          .ant-steps-item-description {
            color: rgba(163, 166, 190, 50%);
          }
        }
      }
    }
  }
}

.ant-popover-inner {
  max-width: 330px;
}

.ant-pagination {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    button {
      border-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      .anticon {
        color: var(--black);
      }
    }
    &.ant-pagination-disabled {
      button {
        .anticon {
          color: var(--secondaryTextColor);
        }
      }
      &:hover {
        background-color: transparent;
        button {
          .anticon {
            color: var(--secondaryTextColor);
          }
        }
      }
    }
    &:hover {
      background: var(--mainColor);
      button {
        .anticon {
          color: var(--white);
        }
      }
    }
  }
  .ant-pagination-item {
    background: transparent;
    font-weight: 700;
    min-width: 30px;
    height: 30px;
    border-radius: 3px;
    font-size: 12px;
    border-style: none;
    color: var(--black);
    transition: all 0.1s !important;
    &.ant-pagination-item-active,
    &:hover {
      background: var(--mainColor) !important;
      color: var(--white);
    }
  }
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    .ant-pagination-item-container {
      display: flex;
      .anticon-double-right,
      .anticon-double-left {
        color: var(--mainColor);
      }
      .ant-pagination-item-ellipsis {
        top: -8px;
        left: -4px;
      }
    }
  }
}

.ant-pagination.ant-pagination-mini .ant-pagination-item,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev {
  margin-inline-end: 8px;
}

.ant-popconfirm-inner-content {
  .ant-popconfirm-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

.ant-wave {
  display: none;
}

.ant-input-number-suffix {
  display: none !important;
}

.custom-resource-center-launcher-button {
  .ant-float-btn-body {
    background-color: var(--mainColor);
    height: 100%;
    border-radius: 50%;
    &:hover {
      background-color: var(--mainColor);
    }
    .ant-float-btn-content {
      .ant-float-btn-description {
        color: var(--white);
      }
    }
  }
}

.react-datepicker {
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
  font-family: 'SFPro', sans-serif;
  border: none !important;
  border-radius: 8px;

  .react-datepicker__day--keyboard-selected {
    background-color: var(--mainColor) !important;
    color: var(--white) !important;
  }
}

.react-datepicker-popper {
  left: -15px !important;

  .react-datepicker__day--selected {
    background-color: var(--mainColor) !important;
    color: var(--white) !important;
  }

  .react-datepicker__navigation--next {
    border-left-color: var(--mainColor) !important;
  }

  .react-datepicker__navigation--previous {
    border-right-color: var(--mainColor) !important;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }

  .react-datepicker__today-button {
    background-color: transparent;
    border-top: none;
  }
}

.react-datepicker-popper[data-placement^='bottom'] {
  .react-datepicker__triangle::before {
    border-bottom-color: var(--lightgrey) !important;
  }
}

.react-datepicker-popper[data-placement^='bottom'] {
  .react-datepicker__triangle {
    border-bottom-color: var(--white) !important;
  }
}

.react-datepicker-popper[data-placement^='top'] {
  .react-datepicker__triangle::before {
    border-top-color: var(--lightgrey) !important;
  }
}

.react-datepicker__time-container {
  width: 100px !important;

  .react-datepicker__time {
    .react-datepicker__time-box {
      width: 100px !important;

      ul.react-datepicker__time-list {
        width: 106% !important;

        &:hover {
          background-color: #f8faff !important;
        }
      }

      .react-datepicker__time-list-item--selected {
        background-color: var(--mainColor) !important;
        color: var(--white) !important;
      }
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.you-can-use {
  color: var(--secondaryTextColor);
  margin-bottom: 0;
  margin-top: 20px;
  font-size: 12px;
  label {
    font-size: 12px;
    font-weight: 500;
  }
}

.disabled {
  cursor: not-allowed !important;
}

.mat-block-with-label.form-item {
  label {
    margin-bottom: 0;
  }
}

.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\A0';
}

.my-assing-drop-overlay {
  overflow: hidden;
  max-height: 400px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(91, 102, 234, 0.16);
  &.full {
    .my-assing-drop-overlay-wrapper {
      width: 100%;
    }
  }
  .my-assing-drop-overlay-wrapper {
    width: 300px;
    z-index: 16000;
    background-color: var(--white);

    .ant-dropdown-menu {
      max-height: 400px !important;
      padding: 0;
      .ant-dropdown-menu-item {
        padding: 0;
      }
      .assign-search-input {
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
        padding: 0 8px;
        cursor: default;

        .svg-with-input {
          display: flex;
          align-items: center;
          width: 100%;
          background-color: var(--lighter);
          border-radius: 5px;
          padding-left: 10px;
        }

        .anticon {
          width: 12px;
          height: 12px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .mat-input-affix-wrapper {
          border-style: none;
          border-radius: 0;
          padding: 0;
          background-color: var(--lighter);
          border-radius: 5px;
          padding-right: 10px;
          height: auto;
        }

        input {
          width: 100%;
          padding: 0;
          border: none;
          color: var(--secondaryTextColor);
          background-color: var(--lighter);
          font-weight: 600;
          height: 30px;
          padding: 5px 10px;
          border-radius: 5px;
          font-size: 12px;

          &::placeholder {
            font-weight: 400;
          }
        }
      }
    }

    .select-option {
      width: 100%;
      .ant-dropdown-menu-title-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .drop-item {
        padding: 8px 12px;
        font-size: 12px;
        transition: all 0.3s ease-in-out;
        width: 100%;
        color: var(--secondaryTextColor);
        background-color: var(--white);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .drop-item-text {
          white-space: nowrap;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.activ-option {
          .drop-item-text {
            color: var(--mainColor);
          }

          .option-icon {
            svg {
              fill: var(--mainColor);
            }
          }
        }

        &.assignToMe {
          .drop-item-text {
            color: var(--green);
          }

          .option-icon {
            svg {
              fill: var(--green);
            }
          }
        }

        &:hover {
          background-color: #f8faff;
          color: var(--mainColor);
        }
      }

      .disabled-option {
        background-color: var(--lighter);
        width: 100%;
        padding: 5px 10px;
        cursor: default;
        color: var(--secondaryTextColor);
        font-weight: 600;
        height: 30px;
        display: flex;
        align-items: center;
        font-size: 12px;
      }

      .option-icon {
        display: flex;
      }
    }
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    border-radius: 14px;
    padding: 14px 18px;
    background-color: rgba(14, 14, 14, 0.8);
  }
}

[data-theme='dark'] {
  .ant-tabs-dropdown {
    .ant-tabs-dropdown-menu {
      background-color: var(--purple);
      border: 1px solid var(--darkPurple);
      padding: 4px;
      .ant-tabs-dropdown-menu-item {
        color: var(--secondaryTextColor);
        border-radius: 8px;
        padding: 10px 15px;
        &:hover {
          background-color: rgba(91, 102, 234, 0.3);
        }
      }
    }
  }

  .ant-empty-normal {
    .ant-empty-image {
      svg {
        path + path,
        ellipse {
          fill: var(--secondaryTextColor);
        }
      }
    }
    .ant-empty-description {
      color: var(--secondaryTextColor);
    }
  }

  .ant-tooltip {
    .ant-tooltip-inner {
      background-color: rgba(7, 1, 33, 0.8);
    }
  }
}

@keyframes borderAnimation {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 200%;
  }
}
