.my_default_input_number {
  &.grey {
    background-color: var(--newBackgroundGrey);
    border: 1px solid var(--newGrey);
    border-radius: 8px;
    height: 44px;
    padding: 12px 16px;
    outline: none;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
    &:focus {
      border-color: var(--mainColor);
    }
    &.full_width {
      width: 100%;
    }
    &[disabled] {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
    }
    &::placeholder {
      font-size: 16px;
      color: var(--thridyTextColor);
      font-weight: 400;
      letter-spacing: 0;
    }
  }
}

.ant-form-item-has-error {
  .my_default_input_number {
    &.grey {
      background-color: var(--newBackgroundGrey) !important;
      box-shadow: none;
      border-color: #ff4d4f !important;
    }
  }
}
