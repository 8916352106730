.mat-table-wrapper {
  border-radius: 8px;
  .ant-table-sticky-scroll {
    bottom: -15px!important;
  }
  .ant-table-tbody {
    .ant-table-row {
      &:nth-child(even) {
        background-color: #f8f9f9;
      }
    }
    .ant-table-cell {
      padding: 24px 12px;
      border-bottom: 1px solid transparent;
      font-size: 16px;
      font-weight: 600;
      &:first-child {
        padding-left: 25px;
      }
      &:last-child {
        padding-right: 25px;
      }
    }
    > .ant-table-row {
      &:nth-child(even) {
        &:hover {
          > td {
            background-color: #f8f9f9;
          }
        }
      }
      &:nth-child(odd) {
        &:hover {
          > td {
            background-color: var(--white);
          }
        }
      }
    }
    > tr {
      > td {
        > .ant-table-wrapper {
          &:only-child {
            .ant-table {
              margin: 0px 0px 0px 32px;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      padding: 16px 12px;
      &:first-child {
        padding-left: 25px;
      }
      &:last-child {
        padding-right: 25px;
      }
    }
    > tr {
      > th {
        background-color: #ecefef;
        color: var(--thridyTextColor);
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
    }
    td {
      background-color: #ecefef !important;
      color: var(--thridyTextColor) !important;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
    th {
      &.ant-table-column-has-sorters {
        &:hover {
          background-color: #e2e2e2;
        }
      }
    }
  }
  .ant-table-filter-column,
  .ant-table-column-sorters {
    justify-content: flex-start;
  }
  .ant-table-column-title {
    flex: 0;
    flex-shrink: 0;
    white-space: nowrap;
    z-index: 0;
  }
  .ant-table-column-sorter {
    color: var(--thridyTextColor);
    .ant-table-column-sorter-up, .ant-table-column-sorter-down {
      &.active {
        color: var(--mainColor);
      }
    }
  }
  .mat-table-wrapper {
    border-radius: 0;
    border: 1px solid var(--borderLineColor);
  }
  .ant-table-expanded-row {
    .ant-table-cell {
      padding: 0;
      &:first-child {
        padding-right: 0;
      }
      &:last-child {
        padding-left: 0;
      }
    }
    .mat-table-wrapper {
      .ant-table-tbody {
        .ant-table-cell {
          padding: 16px 12px;
          &:first-child {
            padding-left: 25px;
          }
          &:last-child {
            padding-right: 25px;
          }
        }
      }
      .ant-table-thead {
        .ant-table-cell {
          padding: 16px 12px;
        }
      }
    }
  }
  .ant-table-row-expand-icon {
    border: 1px solid var(--mainColor);
    color: var(--mainColor);
  }
  + .mat-table-wrapper {
    margin-top: 32px;
  }
  .ant-table {
    background-color: transparent;
  }
  .ant-table-content {
    background-color: var(--white);
  }
}

.ant-table-filter-dropdown {
  left: 31px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: var(--mainColor);
    }
  }
  .ant-radio-inner {
    &::after {
      background-color: var(--mainColor);
    }
  }
  .ant-btn-link {
    color: var(--mainColor);
  }
  .ant-btn-primary {
    background-color: var(--mainColor);
    border-color: var(--mainColor);
    border-radius: 100px;
  }
}

.ant-table-title {
  border-radius: 0;
  padding: 0;
  background-color: var(--lighter);
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: var(--black);
}

.ant-table-title + .ant-table-container,
.mat-table-wrapper .ant-table-content {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ant-table-title + .ant-table-container table {
  border-radius: 8px;
}

.ant-table-title
  + .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}

.ant-table-title
  + .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
}

.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none;
}

.mat-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.ant-table-filter-dropdown .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background-color: var(--mainColorLighter);
  color: var(--mainColor);
}

[data-theme='dark'] {
  .mat-table-wrapper {
    .ant-table-content, .ant-table-body {
      background-color: var(--purple);
    }
    .ant-table-header {
      background-color: var(--darkPurple);
      border: 8px 8px 0 0;
    }
    .ant-table-thead {
      > tr {
        > th {
          background-color: var(--darkPurple);
          color: var(--secondaryTextColor);
          border-bottom: 1px solid rgba(229, 233, 242, 0.16);
        }
      }
      .ant-table-column-has-sorters {
        .ant-table-column-sorter {
          color: var(--secondaryTextColor);
        }
        &:hover {
          background-color: var(--purple);
          .ant-table-column-sorter {
            color: var(--secondaryTextColor);
          }
        }
      }
    }
    .ant-table-tbody {
      .ant-table-placeholder {
        &:hover {
          > td {
            background-color: var(--purple);
          }
        }
      }
      .ant-table-cell {
        border-bottom: 1px solid rgba(229, 233, 242, 0.16);
        color: var(--white);
        background-color: var(--purple);
        &.ant-table-column-sort {
          background-color: #28254B;
        }
      }
      .ant-table-row:nth-child(even) {
        background-color: var(--purple);
        &:hover {
          > td {
            background-color: var(--purple);
            &.ant-table-column-sort {
              background-color: #28254B;
            }
          }
        }
      }
      .ant-table-row:nth-child(odd) {
        &:hover {
          > td {
            background-color: var(--purple);
            &.ant-table-column-sort {
              background-color: #28254B;
            }
          }
        }
      }
    }
    .ant-table-sticky-scroll {
      background-color: rgba(163, 166, 190, 0.2);
      .ant-table-sticky-scroll-bar {
        background-color: var(--secondaryTextColor);
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .ant-table-sticky-scroll {
    display: none;
  }
}