

.mat-textarea-wrapper {
  .mat-textarea {
    font-size: 14px;
    font-weight: 400;
    color: var(--black);
    letter-spacing: 0.58px;
    border-radius: 4px;
    border: 1px solid var(--grey);
    overflow-y: auto;
  }
  .mat-textarea:disabled {
    background-color: var(--lighter);
    border-color: var(--lighter);
    color: var(--thridyTextColor);
  }
  .ant-form-item-has-error {
    .mat-textarea {
      border-color: #e05d4c;
      background-color: #ffebe9;
      box-shadow: none;
    }
  }
}

[data-theme='dark'] {
  .mat-textarea-wrapper {
    .mat-textarea {
      background-color: var(--darkPurple);
      border: 1px solid var(--darkPurple);
      color: var(--white);
      .anticon-close-circle {
        color: var(--secondaryTextColor);
      }
      &:hover {
        background-color: var(--darkPurple);
      }
      textarea {
        &::placeholder {
          color: var(--secondaryTextColor);
        }
      }
    }
  }
}
