.mat-template-select:not(.ant-select-customize-input) {
  padding: 0px !important;
  margin-right: 4px !important;
  height: 28px !important;
  .ant-select-selector {
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    padding: 7px;
    z-index: 1;
    background-color: transparent;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    .ant-select-selection-item {
      display: none;
    }
    .ant-select-selection-search {
      display: none;
    }
  }
  .ant-select-arrow {
    right: 0px;
    top: 0px;
    list-style: none;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    margin-top: 0;
  }
  .anticon {
    padding: 7px;
    border-radius: 9px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
    min-width: 28px;
    max-width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    .anticon {
      background-color: var(--lighter);
      svg {
        path {
          fill: var(--mainColor);
        }
      }
      &.only-stroke {
        svg {
          path {
            fill: transparent;
            stroke: var(--mainColor);
          }
          circle,
          ellipse,
          rect {
            stroke: var(--mainColor);
          }
        }
      }
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      border: 1px solid transparent !important;
    }
  }
  &.ant-select-open {
    .anticon {
      background-color: var(--lighter);
      svg {
        path {
          fill: var(--mainColor);
        }
      }
      &.only-stroke {
        svg {
          path {
            fill: transparent;
            stroke: var(--mainColor);
          }
          circle,
          ellipse {
            stroke: var(--mainColor);
          }
        }
      }
    }
  }
  &.ant-select-disabled {
    opacity: 0.5;
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: transparent;
      background-color: transparent;
    }
    .anticon {
      background-color: transparent;
      svg {
        path {
          fill: var(--secondaryTextColor);
        }
      }
    }
    .only-stroke {
      svg {
        path {
          fill: transparent;
          stroke: var(--secondaryTextColor);
        }
      }
    }
    &:hover {
      .only-stroke {
        svg {
          path {
            stroke: var(--secondaryTextColor);
          }
        }
      }
    }
  }
  &:hover {
    .ant-select-selector {
      border-color: transparent;
      border-right-width: 0;
      border: none !important;
    }
  }
}
