.message_right_panel {
  max-width: 336px;
  width: 100%;
  min-width: 336px;
  overflow-y: auto;
  border-top: 1px solid var(--borderLineColor);
  border-left: 1px solid var(--borderLineColor);
  padding: 12px;
  &--top-side {
    &-item {
      background-color: var(--white);
      padding: 14px;
      box-shadow: 2px 2px 40px rgba(83, 85, 128, 0.1);
      border-radius: 12px;
      margin-bottom: 10px;
    }
  }
}

.customer-modal-auto-detect-language-div {
  width: 100%;
  border-radius: 3px;
  color: var(--orange);
  background-color: rgba(248, 163, 38, 5%);
  display: flex;
  align-items: center;
  padding: 12px;
  font-weight: 400;
  .anticon {
    margin-right: 8px;
  }
}

.message_right_panel-drawer {
  z-index: 1001;
  .ant-drawer-body {
    padding: 0;
    .message_right_panel {
      margin-left: 0;
      overflow: hidden auto;
      height: 100%;
      min-width: 100%;
      &--top-side {
        border-radius: 0;
      }
      .profile-tabs {
        .ant-tabs,
        .ant-tabs-content,
        .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .message_right_panel-drawer {
    .ant-drawer-mask {
      display: none;
    }
    .ant-drawer-content-wrapper {
      box-shadow: none;
    }
  }

  .message_right_panel-drawer {
    .ant-drawer-body {
      .message_right_panel {
        max-width: 100%;
        overflow-y: visible;
      }
    }
  }
}
