.message_conversation_search {
    margin-right: 0;
    .mat-input-affix-wrapper {
        height: 42px;
        input {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 767px) {
    .message_conversation_search {
        width: 100%;
    }
}