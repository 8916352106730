.appointment-page-wrapper {
  .css-3nejl.Appointment-appointment {
    font-family: 'SFPro', sans-serif;
    background-color: var(--mainColor);
    border-radius: 15px;
    opacity: 0.96;
    &:hover {
      background-color: #4b57e1;
    }
  }
  .css-18tcpub-MuiTableCell-root.Cell-cell:focus {
    background-color: var(--mainColorLighter);
  }
}

.css-11ngvp6 .Content-title,
.css-11ngvp6 .Content-dateAndTitle,
.css-11ngvp6 .Content-text {
  font-family: 'SFPro', sans-serif !important;
}

.css-iz0jrc-MuiButtonBase-root-MuiButton-root.SaveButton-button {
  background-color: var(--mainColor) !important;
  border-radius: 8px;
  color: var(--white) !important;
  font-family: 'SFPro', sans-serif !important;
  font-size: 14px;
  height: 40px !important;
  text-transform: capitalize;
}

.css-iz0jrc-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: visible !important;
}

.css-11ngvp6 .Content-lens {
  color: var(--mainColor) !important;
}

.css-71a9mb-MuiButtonBase-root-MuiIconButton-root,
.MuiButton-textPrimary {
  color: var(--mainColor) !important;
  font-family: 'SFPro', sans-serif !important;
  text-transform: capitalize !important;
}

.Cell-selected,
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--mainColor) !important;
}

.Cell-today {
  color: var(--mainColor) !important;
}

.appointment-page-wrapper {
  .Cell-highlightedText {
    color: var(--mainColor) !important;
  }
}

.css-1tazxmh .Layout-booleanEditors,
.css-atr4zg-MuiTypography-root.Label-titleLabel,
.css-1nifpm1-MuiFormControl-root-MuiTextField-root.TextEditor-editor,
.css-11ngvp6 .Content-titleContainer,
.css-11ngvp6 .Content-contentContainer {
  display: none;
}

.appointment-page-data-wrapper {
  z-index: 10000;
}

.Layout-popover {
  overflow-x: visible !important;
  overflow-y: visible !important;
  max-width: 305px !important;
}

.appointment-title {
  background-color: #fafafa;
  font-size: 15px;
  border-radius: 12px 12px 0 0;
  color: #352a72;
  font-weight: 500;
  padding: 15px 0px 19px 15px;
  line-height: normal;
  border-style: none;
  max-width: 305px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .anticon {
    cursor: pointer;
  }
}

.Header-flexContainer {
  background-color: #fafafa;
  padding-top: 4px;
}

.MuiTableCell-root {
  .Cell-today {
    background-color: var(--mainColor) !important;
    color: var(--white) !important;
  }
}

.css-11ngvp6.Content-content {
  border-radius: 8px;
  letter-spacing: normal !important;
  padding: 13px 20px 20px !important;
}

.css-8ludsl.Header-head {
  min-height: 50px;
}

.calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.calendar-wrapper {
  background-color: #fff;
  border-radius: 20px;
  height: calc(100vh - 80px);
}

.css-1b7ni76-MuiToolbar-root.Toolbar-toolbar,
.css-18tcpub-MuiTableCell-root,
.css-4plepo .MainLayout-ordinaryHeaderBorder,
.css-sizvqo-MuiTableCell-root.Cell-cell,
.css-99cbwm .MainLayout-ordinaryHeaderBorder {
  border-bottom: 1px solid var(--borderLineColor) !important;
}

.css-qocya8-MuiTableCell-root.Cell-cell,
.css-18tcpub-MuiTableCell-root.Cell-cell,
.css-sizvqo-MuiTableCell-root.Cell-cell {
  border-right: 1px solid var(--borderLineColor) !important;
}

.css-4plepo .MainLayout-ordinaryLeftPanelBorder,
.css-1bzx5rb-MuiTableCell-root.Cell-cell {
  border: none !important;
}

.Cell-dayView {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Cell-dayOfWeek,
.Cell-dayOfMonth {
  font-size: 14px !important;
  line-height: 36px !important;
}

.Cell-dayOfMonth {
  margin-left: 7px;
}

.css-1bzx5rb-MuiTableCell-root .Cell-dayOfWeek {
  font-weight: 400 !important;
}

.css-sizvqo-MuiTableCell-root.Cell-cell {
  height: 130px !important;
}

/* Switcher */
.Switcher-inputRoot {
  border-radius: 8px !important;
  background-color: rgb(91 102 234 / 12%);
  font-family: 'SFPro', sans-serif !important;
  color: var(--mainColor) !important;
  cursor: pointer !important;
}

.Switcher-input {
  text-transform: capitalize !important;
}

.css-1rit21i-MuiInputBase-root-MuiOutlinedInput-root .Switcher-input {
  text-transform: none;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.MuiSvgIcon-root {
  color: var(--mainColor);
  fill: var(--mainColor) !important;
}

.css-8ludsl.Header-flexContainer {
  justify-content: space-between !important;
}

.css-8ludsl.Header-head {
  padding: 0 !important;
}

.Switcher-inputRoot .MuiSvgIcon-root path {
  display: none;
}

.Switcher-inputRoot::before {
  content: '';
  border: solid var(--mainColor);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  right: 12px;
  position: absolute;
  top: 38%;
}

.MuiMenuItem-root {
  font-family: 'SFPro', sans-serif !important;
  font-size: 12px;
}

.MuiList-padding {
  padding: 0 !important;
}

.MuiPaper-elevation {
  margin-top: 5px;
}

.Mui-selected {
  background-color: var(--mainColorLighter) !important;
  color: var(--mainColor) !important;
}

.MuiMenuItem-root:hover {
  color: var(--mainColor);
  background-color: var(--mainColorLighter) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.Switcher-inputRoot.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

@media screen and (max-width: 479px) {
  .Cell-dayView {
    flex-direction: column;
  }

  .Cell-dayOfMonth {
    margin-left: 0;
  }

  .Cell-dayOfWeek,
  .Cell-dayOfMonth {
    line-height: 20px !important;
  }

  .calendar {
    padding: 0;
  }

  .calendar-wrapper {
    overflow: hidden;
    height: calc(100vh - 110px);
  }
}
