* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Black.otf');
  font-weight: black;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Bold.otf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Semibold.otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Medium.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Regular.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SFPro;
  src: url('./SFPro-Light.otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Averta';
  src: url('./TW-Averta-Regular.ttf');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('./TW-Averta-Semibold.ttf');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
