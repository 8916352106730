.dashboard_tags {
    height: 24px;
    border-radius: 20px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0px 14px;
    font-size: 12px!important;
    font-weight: 600!important;
}