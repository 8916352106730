.user-online-center-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  
    .center-code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 460px;
      width: 100%;
      background-color: var(--mainColor);
      border-radius: 100px;
      padding: 8px 30px 8px 11px;
  
      .icon-with-name {
        display: flex;
        align-items: center;
  
        .online-user {
          margin-left: 15px;
          margin-top: -2px;
  
          .user-name {
            font-size: 14px;
            color: var(--white);
            font-weight: 600;
          }
  
          .page {
            font-size: 12px;
            font-weight: 400;
            color: var(--white);
          }
        }
      }
  
      .date {
        font-size: 14px;
        color: var(--white);
        font-weight: 600;
        text-align: right;
        width: 100px;
      }
    }
}