.my_default_table_switch {
  color: var(--thridyTextColor);
  font-size: 14px;
  font-weight: 400;
  .mat-switch-wrapper {
    .switch-text {
      font-size: 16px;
      font-weight: 500;
      color: var(--black);
    }
  }
}
