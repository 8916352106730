

.mat-checkbox-group {
  display: flex;
  label {
    padding-top: 15px;
  }
  .ant-checkbox {
    + span {
      padding-top: 2px;
      color: #77808d !important;
      font-weight: 500;
    }
  }
}

.mat-form-item {
  .ant-form-item-control-input {
    min-height: 0;
  }
}

.mat-checkbox-black {
  .ant-checkbox {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--green) !important;
        border: 1px solid var(--green);
      }
    }
    + span {
      padding-top: 5px;
    }
  }
}

:where(.css-dev-only-do-not-override-17sses9).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: var(--mainColor);
}

.mat-radio-group-secondary {
  .ant-radio-wrapper {
    .ant-radio {
      margin-bottom: 0;
    }
  }
}
