.my_default_add_auto_reply_message_item {
  padding: 12px;
  border-radius: 0px 12px 12px 0px;
  position: relative;
  cursor: pointer;
  &.active {
    background-color: var(--newLightGrey);
    &::before {
      content: '';
      width: 4px;
      height: 100%;
      position: absolute;
      left: 0;
      background-color: var(--mainColor);
      border-radius: 0px 4px 4px 0px;
    }
  }
  &--info {
    overflow: hidden;
    &-title {
      color: var(--black);
      font-weight: 600;
      font-size: 14px;
    }
    &-subtitle {
      color: var(--thridyTextColor);
      font-weight: 400;
      font-size: 12px;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &--percentage {
    width: fit-content;
    color: var(--white);
    font-size: 10px;
    font-weight: 600;
    padding: 0px 4px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--red);
    border-radius: 6px 6px 0 0;
    position: absolute;
    bottom: 0;
    &.orange {
      background-color: var(--orange);
    }
    &.green {
      background-color: var(--green);
    }
  }
}
