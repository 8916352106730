

.center_message_more_items_wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  &--inner {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: center;
    position: relative;
    width: 100%;
    .date {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 400;
      color: var(--thridyTextColor);
      display: flex;
      align-items: center;
    }
    .post_info {
      width: 100%;
      text-align: right;
      max-width: 680px;
      margin-right: 10px;
      .author {
        font-size: 12px;
        color: var(--mainColor);
        font-weight: 600;
      }
      .phone {
        margin-right: 10px;
        font-size: 12px;
        color: var(--thridyTextColor);
        font-weight: 400;
      }
    }
    &-block {
      max-width: 500px;
      width: 100%;
      background-color: var(--newLightGrey);
      border-radius: 16px 16px 0 16px;
      padding: 14px 16px;
      &-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: var(--black);
        margin-bottom: 12px;
      }
      &--information {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 12px;
        .information_items {
          &-title {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: var(--secondaryTextColor);
          }
          &-desc {
            padding-top: 3px;
            max-width: 200px;
            word-break: break-word;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px
          }
        }
        .separator {
          height: 30px;
          width: 1px;
          background: linear-gradient(to bottom, #e8edf3 0%, #e0e7f1 100%, #e8edf3 0%);
          margin: 0 15px;
        }
      }
      &-body {
        display: flex;
        justify-content: center;
        background-color: var(--white);
        border-radius: 12px;
        padding: 16px;
        .center_message_more_items-separation {
          opacity: 0.7;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          filter: grayscale(1);
          &.active {
            opacity: 1;
            filter: none;
          }
          &-title {
            font-size: 12px;
            color: var(--thridyTextColor);
            font-weight: 400;
            line-height: 15px;
            padding: 5px 0 9px 0;
            text-align: center;
          }
          .text {
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            color: var(--black);
          }
          + .center_message_more_items-separation {
            border-left: 1px solid var(--borderLineColor);
          }
        }
      }
    }
  }
  .mat-user-profile-letters.medium {
    margin-bottom: 20px;
    margin-left: 6px;
    margin-right: 0;
    width: 32px;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 12px;
  }
}

@media screen and (max-width: 479px) {
  .center_message_more_items_wrapper {
    &--inner {
      &-block {
        padding: 22px 15px 18px 15px;
        &--information {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 22px;
          .information_items {
            &-desc {
              max-width: 120px;
            }
          }
        }
        &-body {
          flex-wrap: wrap;
          padding: 10px;
          .center_message_more_items-separation {
            padding: 10px 5px;
            width: 50%;
            &:nth-child(3) {
              border-left: none;
              border-top: 1px solid var(--borderLineColor);
            }
            &:nth-child(4) {
              border-top: 1px solid var(--borderLineColor);
            }
          }
        }
      }
    }
    .mat-user-profile-letters {
      width: 20px;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 8px !important;
      margin-left: 5px;
    }
  }

}
