.message_center_panel {
    .message-center-type-wrapper {
        .message-center-text-input-wrapper {
            .tooltip-request-a-payment {
                cursor: not-allowed;
                display: flex;
                margin-left: 4px;
                align-items: center;
                justify-content: center;
                opacity: 0.5;
                .anticon {
                  svg {
                    path,
                    circle,
                    ellipse,
                    rect {
                      stroke: var(--secondaryTextColor);
                    }
                  }
                }
            }
        }
    }
}