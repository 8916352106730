.message-tab-pane-wrapper {
    .message-note-item-wrapper {
        width: 100%;
        .item-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .name {
            color: var(--black);
            font-size: 14px;
            font-weight: 600;
          }
    
          .date {
            font-size: 10px;
            font-weight: 600;
            color: var(--secondaryTextColor);
          }
        }
    
        .text {
          color: var(--secondaryTextColor);
          font-size: 12px;
          line-height: 16px;
          padding: 10px 0;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
        }
    
        .mat-btn {
          padding: 0;
          height: auto;
          width: auto;
          display: flex;
          border-style: none;
    
          .anticon {
            display: flex;
          }
    
          span {
            color: var(--mainColor);
          }
        }
    }
}

.my-note-modal {
    .mat-form {
      .mat-form-item {
        margin-bottom: 0;
      }
      .note-show-text {
        color: var(--black);
      }
    }
  
    .ant-form-item-control-input-content {
      .ant-input-affix-wrapper {
        height: auto;
        .mat-textarea:disabled {
          overflow-y: auto !important;
        }
      }
    }
}