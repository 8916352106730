

.matador-connect-preview-default {
  background-color: var(--lighter);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  margin-bottom: 10%;
  border-radius: 8px;
  border: 1px solid var(--newGrey);
}

#iframe-preview {
  max-width: 100%;
}

@media screen and (max-width: 1290px) {
  .matador-connect-preview-default {
    padding: 0;
    align-items: center;
  }

  .matador-connect-preview-default {
    iframe {
      position: relative !important;
    }
  }
}
