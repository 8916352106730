.my_default_select {
  &--dropdown {
    .ant-select-tree-switcher {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-select-tree-checkbox {
      margin-top: 2px !important;
      margin-inline-end: 4px !important;
      &:hover {
        .ant-select-tree-checkbox-inner {
          border-color: var(--mainColor) !important;
        }
        &.ant-select-tree-checkbox-checked {
          .ant-select-tree-checkbox-inner {
            background-color: var(--mainColor);
            border-color: var(--mainColor);
          }
        }
      }
      &.ant-select-tree-checkbox-indeterminate {
        .ant-select-tree-checkbox-inner {
          &::after {
            border-radius: 2px;
            background-color: var(--mainColor);
            width: 10px;
            height: 10px;
            top: 50%;
          }
        }
      }
      &.ant-select-tree-checkbox-checked {
        .ant-select-tree-checkbox-inner {
          background-color: var(--mainColor);
          border-color: var(--mainColor);
        }
      }
      .ant-select-tree-checkbox-inner {
        width: 20px;
        height: 20px;
        &:after {
          width: 7px;
          height: 11px;
          top: 44%;
        }
      }
    }
    .ant-select-tree-title {
      font-size: 14px;
      color: var(--black);
    }
  }
}
