.calendar_invite_blocks_wrapper {
  display: grid;
  grid-template-areas:
    'b a'
    'b d' 'c d' '. d';
  gap: 22px;
  grid-template-columns: 0fr 3fr;
  .default_block_wrapper {
    &:nth-child(1) {
      grid-area: a;
    }
    &:nth-child(2) {
      grid-area: b;
    }
    &:nth-child(3) {
      grid-area: c;
    }
    &:nth-child(4) {
      grid-area: d;
    }
  }
}

@media screen and (max-width: 1190px) {
  .calendar_invite_blocks_wrapper {
    display: flex;
    flex-direction: column;
  }
}
