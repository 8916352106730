.message_center_panel {
    .message-center-type-wrapper {
        .message-center-text-input-wrapper {
            .message-center-button-wrapper {
                .mobile-upload {
                    margin-left: 0;
                    .anticon-loading {
                      svg {
                        path {
                          fill: var(--secondaryTextColor);
                        }
                      }
                    }
                }
                .mat-upload {
                    margin-right: 0px;
                    display: flex;
          
                    .ant-upload-select {
                      border: none;
                    }
          
                    .anticon-loading {
                      svg {
                        path {
                          fill: var(--secondaryTextColor);
                        }
                      }
                    }
          
                    .icon-size {
                      margin-right: 0;
                    }
          
                    .ant-upload-disabled {
                      .icon-size {
                        cursor: not-allowed;
                      }
                    }
                }
            }
        }
    }
}