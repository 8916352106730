.workflow-edit-organization-page {
  .organization-new-list-header {
    justify-content: center;
    p {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .title-trigger {
      background: #3f8cff;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      height: 36px;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: var(--white);
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .title-small {
      color: var(--thridyTextColor);
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-top: 14px;
    }
  }
}

.workflow-list-wrapper {
  .organization-new-mat-list-header {
    border-radius: 8px 8px 0px 0px;
  }
}

.workflow-edit-form {
  max-width: 503px;
  width: 100%;
  margin: 0 auto;
}

.detect-intent-wrapper {
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 2px 2px 40px rgba(83, 85, 128, 0.1);
  padding: 25px 25px 23px 25px;
  .detect-intent-title {
    font-size: 12px;
    color: var(--textColor);
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 9px;
  }
}

.detect-intent-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &.select-and-input {
    position: absolute;
    top: -72px;
    right: 25px;
  }
  .mat-form-radio-group {
    justify-content: flex-start;
    align-items: flex-start;
    .ant-radio-button-wrapper {
      max-width: 58px;
      height: auto;
      border-radius: 10px;
      padding: 6px 7px 7px 7px;
      width: 100%;
      border-color: transparent;
      color: var(--textColor);
      border-width: 0;
      svg {
        margin-right: 0;
      }
      &:first-child {
        border-color: transparent;
      }
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        span {
          + span {
            font-size: 12px;
            font-weight: bold;
            line-height: 14.83px;
            padding-top: 6px;
          }
        }
      }
    }
  }
  .mat-form-item {
    margin-bottom: 0;
    width: auto;
  }
}

.detect-intent-actions .mat-form-radio-group .ant-radio-button-wrapper:hover,
.detect-intent-actions
  .mat-form-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: var(--newLightGrey);
  color: var(--textColor);
}

.detect-intent-actions
  .mat-form-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:hover,
.detect-intent-actions
  .mat-form-radio-group
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
  background-color: transparent;
}

.detect-intent-wait-for-reply {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  .title {
    color: var(--thridyTextColor);
    font-weight: 400;
    line-height: 14.83px;
    font-size: 12px;
    padding-right: 15px;
    white-space: nowrap;
  }
  .mat-form-select-dropdown {
    min-width: 83px !important;
  }
  .ant-form-item-explain-error {
    display: none;
  }
}

.wait-for-reply-input {
  width: auto;
  margin-right: 8px;
  .ant-form-item-control-input {
    width: 33px;
    min-height: 25px;
  }
  .mat-input-affix-wrapper {
    width: 47px;
    padding: 4px 4px 5px 4px;
    text-align: center;
    height: 25px;
    color: var(--black);
    border-color: var(--grey);
    .mat-input-affix-wrapper {
      padding: 0;
      height: auto;
    }
  }
  .ant-input-number-input {
    height: auto;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }
  .ant-form-item-control-input-content {
    display: flex;
  }
}

.wait-for-reply-input-text {
  .mat-input-affix-wrapper {
    border-color: var(--grey);
    color: var(--black);
    height: 25px;
    padding: 4px 4px 5px;
    text-align: center;
    width: 47px;
    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600;
    }
  }
}

.detect-intent-actions {
  &.select-and-input {
    .wait-for-reply-input-text {
      .mat-input-affix-wrapper {
        background-color: initial;
      }
    }
  }
}

.wait-for-reply-select {
  width: auto;
  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    min-height: 25px;
  }
  .ant-select-selector {
    padding: 0 5px 0 7px !important;
    height: 25px !important;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    line-height: 24px !important;
    font-size: 11px !important;
  }
  .ant-select-arrow {
    svg {
      width: 9px;
    }
  }
  .mat-select {
    .ant-select-arrow {
      right: 6px;
      width: 9px;
      top: 54%;
    }
  }
}

.add-workflow-action-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .mat-dropdown-trigger-container {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border: 1px dashed var(--thridyTextColor);
    border-radius: 50%;
    cursor: pointer;
  }
}

.workflow-drop-overlay {
  max-width: 453px;
  width: 100%;
  max-height: fit-content;
  background-color: transparent;
  border-radius: 16px;
  .ant-dropdown-menu {
    border-radius: 16px;
    padding: 15px 18px 15px 13px;
    box-shadow: 2px 2px 40px rgba(83, 85, 128, 0.1);
    max-height: 294px;
    overflow: auto;
    .ant-dropdown-menu-item {
      .drop-item {
        justify-content: flex-start;
        padding: 10px;
        .anticon {
          padding-right: 5px;
        }
      }
      &:hover {
        background-color: #f6f7fb;
        border-radius: 10px;
      }
      .ant-dropdown-menu-title-content:hover {
        background-color: #f6f7fb;
        border-radius: 10px;
      }
    }
  }
  .mat-drop-menu {
    .drop-item {
      &:hover {
        fill: inherit;
      }
    }
  }
}

.workflow-add-action-place {
  position: relative;
  &.positive-reply {
    bottom: 40px;
    right: 62px;
  }
  &.negative-reply {
    bottom: 20px;
  }
  &.no-reply {
    bottom: 40px;
    left: 62px;
  }
}

.mat-dropdown-trigger-container {
  &.positive-reply {
    border-color: #5bc78b;
  }
  &.negative-reply {
    border-color: #ee7b6c;
  }
  &.no-reply {
    border-color: #f9c735;
  }
}

.workflow-action-item-wrapper {
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 2px 2px 40px rgba(83, 85, 128, 0.1);
  padding: 22px;
  position: relative;
  .list-variables {
    margin-bottom: 0;
    border-top: 1px dashed var(--borderLineColor);
    margin-top: 14px;
    padding-top: 15px;
    .add-variable {
      margin: 5px 0 0 5px;
      color: var(--thridyTextColor);
      font-size: 10px;
      height: 22px;
      padding: 4px 10px;
      span {
        display: flex;
        align-items: center;
        line-height: 11.36px;
        font-weight: 600;
      }
      .variable-circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  }
}

.workflow-action-item-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 17px;
  .anticon {
    margin-bottom: 6px;
  }
}

.workflow-action-item-textarea-wrapper {
  width: 100%;
  .mat-form-item {
    margin-bottom: 5px;
  }
  .mat-textarea {
    height: 48px !important;
    min-height: 48px !important;
    max-height: 48px !important;
    line-height: 14.83px;
    font-size: 12px;
    font-weight: 400;
    color: var(--thridyTextColor);
    padding: 9px 12.6px;
    background-color: #f6f7fb;
    border-style: none;
    border-radius: 8px;
    overflow-y: auto !important;
    &.note-textarea {
      height: 71px !important;
      min-height: 71px !important;
      max-height: 71px !important;
    }
  }
  .bottom-text {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: var(--thridyTextColor);
    display: flex;
  }
  p {
    font-size: 8px;
    font-weight: 400;
    line-height: 18px;
    color: #a4a5a6;
  }
  .mat-multiple-select {
    &.mat-select-secondary {
      .ant-select-selector {
        min-height: 46px;
        margin: 1px 0;
      }
    }
  }
  .ant-form-item-has-error {
    &:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      background-color: #f6f7fb;
    }
  }
  .ant-form-item-explain {
    &.ant-form-item-explain-connected {
      min-height: 0;
      font-size: 10px;
    }
  }
}

.workflow-action-delay {
  height: 48px;
  padding: 9px 12.6px;
  background-color: #f6f7fb;
  border-radius: 8px;
  margin-bottom: 5px;
  .detect-intent-wait-for-reply {
    justify-content: flex-start;
    height: 100%;
  }
  .mat-form-item {
    margin-bottom: 0;
  }
}

.mat-input-affix-wrapper {
  &.workflow-name-input {
    background-color: var(--white);
    box-shadow: 2px 2px 40px rgba(83, 85, 128, 0.1);
    border-style: none;
    .ant-input {
      background-color: transparent;
    }
  }
}

.workflow-remove-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

@media screen and (max-width: 479px) {
  .detect-intent-wrapper {
    padding-bottom: 60px;
  }

  .detect-intent-actions {
    &.select-and-input {
      top: -45px;
      left: 26px;
      right: auto;
    }
    &.wsi {
      position: absolute;
      bottom: -40px;
    }
  }

  .mat-form {
    .row-div-container {
      &.workflow-action-item-row {
        flex-direction: row;
      }
    }
  }

  .workflow-action-item-icon {
    margin-right: 10px !important;
  }
}
