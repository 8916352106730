.coupoon_widget_wrapper {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 2px 7px 40px rgba(83, 85, 128, 0.15);
  padding: 20px;
  max-width: 320px;
  margin: 0 auto 25px;

  .widget_textarea_wrapper {
    position: relative;
    .my_custom_textarea {
      display: flex;
      position: relative;
      border: 1px dashed rgb(147, 189, 255);
      border-radius: 4px;
      color: var(--black);
      textarea {
        background-color: rgba(0, 0, 0, 0);
        box-shadow: none;
        color: rgb(71, 70, 87);
        font-size: 22px;
        font-weight: 700;
        height: 100%;
        line-height: normal;
        overflow-y: auto;
        text-align: center;
        width: 100%;
        z-index: 0;
        border-style: none;
        padding: 5px 2px;
      }
      input {
        text-align: center;
        color: var(--white);
        line-height: 20px;
        font-size: 16px;
        background-color: transparent;
        outline: none;
        border-style: none;
        font-weight: 600;
        width: 100%;
        padding-right: 25px;
        max-width: 160px;
        &::placeholder {
          color: var(--white);
        }
      }
    }
    .my_default_color_picker {
      position: absolute;
      top: -6px;
      right: -6px;
    }
    &.enable_margin {
      margin-top: 10px;
    }
  }

  .widget_button_wrapper {
    position: relative;
    margin-top: 20px;
    &--swipe {
      width: 100%;
      height: 60px;
      position: relative;
      background-color: var(--mainColor);
      border-radius: 100px;
      padding: 6px 6px;
      .anticon {
        height: 48px;
        min-width: 48px;
        background-color: var(--white);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .my_default_color_picker {
      position: absolute;
      top: -6px;
      right: -6px;
    }
  }

  .button-with-edit-icon {
    margin-top: 20px;
    position: relative;
    .swipe-to-start-button {
      width: 100%;
      height: 60px;
      position: relative;
      background-color: #5b66ea;
      border-radius: 100px;
      padding: 6px 6px;
      display: flex;
      align-items: center;
      .icon-wrapper {
        height: 48px;
        min-width: 48px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
      .text_with_edit_icon {
        margin-top: 0;
        position: relative;
        padding: 0 20px;
        .my-custom-input {
          padding: 2px;
          background-color: transparent;
          border-radius: 4px;
          input {
            text-align: center;
            color: #fff;
            line-height: 20px;
            font-size: 16px;
            background-color: transparent;
            outline: none;
            border-style: none;
            font-weight: 600;
            max-width: 135px;
            &::placeholder {
              color: #fff;
            }
          }
        }
        .anticon {
          background-color: #fff;
          width: 15px;
          height: 15px;
          border-radius: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
          position: absolute;
          top: -5px;
          right: -8px;
          z-index: 1;
          svg {
            margin-left: 1px;
            path {
              fill: #408ff0;
            }
          }
        }
      }
    }
    .color-edit {
      cursor: pointer;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.19);
      position: absolute;
      top: -2px;
      right: -4px;
      z-index: 1;
      background-color: #fff;
      border-radius: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 19px;
      height: 19px;
    }
  }
}

.edit-drop-wrapper {
  .ant-popover-content {
    .ant-popover-arrow {
      left: 3px;
    }
    .ant-popover-inner {
      margin-left: -7px;
      box-shadow: 0 2px 13px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      width: 137px;
      padding: 6px;
      .ant-popover-inner-content {
        padding: 0;
        .popup-picker-color {
          display: flex;
          align-items: center;
          position: relative;
          .ant-form-item {
            margin-bottom: 0;
            .ant-form-item-control-input {
              min-height: auto;
              .ant-form-item-control-input-content {
                display: flex;
                align-items: center;
              }
            }
            .ant-input-affix-wrapper {
              height: 100%;
              padding: 7px;
              font-size: 11px;
              .ant-input {
                font-size: 11px;
                padding-right: 25px;
              }
              .ant-input-suffix {
                display: none;
              }
            }
          }
          .picker-color {
            position: absolute;
            right: 7px;
            z-index: 2;
            .color-trigger {
              width: 17px;
              height: 17px;
              border-radius: 4px;
            }
          }
        }
        .font-size-wrapper {
          margin-top: 6px;
          .ant-form-item {
            margin-bottom: 0;
            .ant-input-number {
              padding: 7px;
              height: 33px;
              .ant-input-number-input-wrap {
                display: flex;
                height: 100%;
              }
              .ant-input-number-input {
                font-size: 11px;
                height: auto;
              }
            }
          }
        }
        .language-wrapper {
          margin-bottom: 6px;
          .ant-form-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 33px;
  padding: 0 7px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 11px;
  line-height: 33px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-arrow {
  right: 7px;
  font-size: 8px;
  top: 19px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-open.ant-select-single:not(.ant-select-customize-input)
  .ant-select-arrow {
  top: 15px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  ):not(.ant-select-customize-input)
  .ant-select-selection-search-input {
  height: 33px;
}

.edit-drop-wrapper
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .language-wrapper
  .ant-select.ant-select-single:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  ):not(.ant-select-customize-input)
  .ant-select-selection-placeholder {
  padding-right: 0;
  line-height: 33px;
  font-size: 11px;
  color: #77808d;
}

@keyframes run {
  0% {
    left: 5px;
  }
  40% {
    left: 5px;
  }
  60% {
    left: 30px;
  }
  70% {
    left: 30px;
  }
  100% {
    left: 5px;
  }
}

@media screen and (max-width: 479px) {
  .edit-drop-wrapper {
    .ant-popover-content {
      .ant-popover-arrow {
        left: auto;
        right: 3px;
      }
      .ant-popover-inner {
        margin-left: 0;
        margin-right: -7px;
      }
    }
  }
}
