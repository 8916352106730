.mat-form {
  .mat-form-item {
    margin-bottom: 25px;
    width: 100%;
    &.with-variables {
      margin-bottom: 10px;
    }
  }
  .mat-form-item-black {
    margin-bottom: 20px;
    .ant-form-item-label {
      padding: 0 0 6px !important;
      line-height: 18px !important;
      label {
        color: var(--textColor) !important;
        font-weight: 600;
        text-transform: none;
      }
    }
  }
  .ant-form-item-label {
    padding: 0 0 12px !important;
    line-height: 15px !important;
    > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      right: -14px;
      position: absolute;
      margin-right: 0;
    }
    label {
      height: auto;
      text-align: left;
      color: var(--thridyTextColor);
      text-transform: uppercase;
      opacity: 1;
      font-weight: 400;
      font-size: 12px !important;
    }
  }
}

.mat-input-affix-wrapper:not(.ant-input-number) {
  border: 1px solid var(--grey);
  border-radius: 4px;
  height: 48px;
  padding: 10px 10px 10px 17px;
  cursor: text;
  width: 100%;
  outline: none;
  &:has(.ant-input-prefix) {
    padding: 10px;
    .ant-input-prefix {
      margin-inline-end: 8px;
    }
  }
}

.ant-input-textarea-show-count .ant-input-affix-wrapper,
.ant-input-affix-wrapper:has(.mat-textarea) {
  border: 1px solid var(--grey);
  border-radius: 4px;
}

.ant-input-textarea-show-count .ant-input-affix-wrapper:hover,
.ant-input-textarea-show-count .ant-input-affix-wrapper:has(.ant-input:focus),
.ant-input-affix-wrapper:has(.mat-textarea):hover,
.ant-input-affix-wrapper:has(.mat-textarea:focus) {
  border: 1px solid var(--mainColor);
}

.ant-input-affix-wrapper:has(.mat-textarea).ant-input-affix-wrapper-focused {
  box-shadow: none;
  border: 1px solid var(--mainColor);
}

.ant-input-affix-wrapper:has(.mat-textarea) .mat-textarea {
  padding: 10px 10px 10px 17px;
  border-radius: 4px;
  overflow-y: auto !important;
}

.mat-input-affix-wrapper:not(.ant-input-number).ant-input[disabled] {
  cursor: not-allowed;
}

.mat-input-affix-wrapper.ant-input-number-affix-wrapper-focused {
  box-shadow: none;
  border-color: var(--mainColor) !important;
  background-color: var(--mainColorLighter);
}

.ant-input-number.mat-input-affix-wrapper.ant-input-number-focused {
  background-color: transparent;
}

.mat-input-affix-wrapper input {
  font-size: 14px;
  font-weight: 400;
  color: var(--textColor);
  padding: 0;
}

.mat-input-affix-wrapper input::placeholder,
.mat-textarea::placeholder,
.ant-input-textarea-show-count .ant-input::placeholder {
  color: var(--thridyTextColor) !important;
}

.mat-input-affix-wrapper input:hover,
.mat-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.mat-textarea:hover {
  border-color: var(--grey);
}

.mat-input-affix-wrapper.ant-input-affix-wrapper-focused:not(
    .ant-input-affix-wrapper-disabled
  ) {
  border-color: var(--mainColor);
  box-shadow: none;
}

.mat-input-affix-wrapper.ant-input-affix-wrapper-focused {
  background-color: var(--mainColorLighter);
  box-shadow: none;
}

.mat-input-affix-wrapper.ant-input-affix-wrapper-focused input {
  background-color: transparent;
}

.ant-form-item-has-success .mat-input-affix-wrapper {
  border-color: var(--green);
}

.ant-form-item-has-success .mat-textarea {
  border-color: var(--green);
}

.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon,
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon,
.ant-input-number-handler-wrap,
.ant-input-number-suffix {
  display: none;
}

.ant-form-item-has-feedback .mat-input-affix-wrapper .ant-input-suffix {
  padding-right: 0;
}

.ant-input-clear-icon {
  display: flex;
}

.ant-form-item-explain.ant-form-item-explain-error {
  line-height: normal;
}

.ant-form-item-has-error .mat-input-affix-wrapper input {
  background-color: transparent;
  color: #e05d4c !important;
}

.ant-input-number-focused,
.ant-picker-focused,
.ant-input-number:focus,
.ant-picker:focus,
.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus,
.mat-input-affix-wrapper.ant-input:focus,
.ant-input-focused,
.mat-textarea-wrapper {
  box-shadow: none;
  border-color: var(--mainColor);
  outline: none;
}

.mat-textarea:focus {
  border-color: var(--mainColor) !important;
  box-shadow: none !important;
}

.ant-input-number-input-wrap {
  height: 100%;
  line-height: 2px;
  padding: 0;
}

.mat-textarea textarea {
  padding: 10px 10px 10px 17px;
  font-size: 14px;
  font-weight: 400;
  color: var(--textColor);
  box-shadow: none;
  border-radius: 4px;
  outline: none;
  overflow-y: auto;
}

.mat-textarea:disabled {
  background-color: rgba(245, 245, 245, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.mat-input-affix-secondary-wrapper:not(.ant-input-number) {
  background-color: var(--newLightGrey);
  border: 1px solid var(--newGrey);
  border-radius: 8px;
  height: 50px;
}

.mat-input-affix-secondary-wrapper:not(
    .ant-input-number
  ).ant-input-affix-wrapper-disabled {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: not-allowed;
}

.mat-input-affix-secondary-wrapper:not(
    .ant-input-number
  ).ant-input-affix-wrapper-disabled
  .ant-input-disabled {
  background-color: transparent;
  cursor: not-allowed;
}

.mat-input-affix-secondary-wrapper:not(.ant-input-number) .ant-input {
  background-color: var(--newLightGrey);
}

.mat-form-item-black.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.mat-form-item-black.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper {
  background-color: var(--newLightGrey);
}

.mat-textarea-secondary {
  background-color: var(--newLightGrey);
  border: 1px solid var(--newGrey);
  border-radius: 8px;
}

.mat-input-affix-secondary-wrapper.ant-input-number {
  background-color: var(--newLightGrey);
}

.mat-input-affix-secondary-wrapper.ant-input-number .ant-input-number-input-wrap {
  display: flex;
  align-items: center;
}

.textareal-word-length {
  display: flex;
  justify-content: flex-end;
  color: rgba(0, 0, 0, 0.45);
}

.mat-input-affix-wrapper:not(.ant-input-number) .ant-input-group {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 40px;
}

.mat-input-affix-wrapper:not(.ant-input-number)
  .ant-input-group
  .ant-input-affix-wrapper:focus,
.mat-input-affix-wrapper:not(.ant-input-number)
  .ant-input-group
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.mat-input-affix-wrapper:not(.ant-input-number)
  .ant-input-group
  .ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: none;
}

.mat-input-affix-wrapper:not(.ant-input-number)
  .ant-input-group
  .ant-input-affix-wrapper {
  height: 100%;
  border-style: none;
  background-color: transparent;
  padding-left: 0;
}

.mat-input-affix-wrapper:not(.ant-input-number)
  .ant-input-group
  .ant-input-group-addon {
  background: transparent;
  border-style: none;
  padding-right: 4px;
  color: var(--thridyTextColor);
}

.mat-input-affix-wrapper:not(
    .ant-input-number
  ).ant-input-group-wrapper-status-error {
  border-color: #e05d4c;
  background-color: #ffebe9;
}

.mat-input-affix-wrapper:not(
    .ant-input-number
  ).ant-input-group-wrapper-status-error:hover
  .ant-input-affix-wrapper {
  background-color: transparent !important;
}

.mat-input-affix-wrapper:not(.ant-input-number)
  .ant-input-group
  .ant-input-affix-wrapper {
  background-color: transparent !important;
}

.ant-input-affix-wrapper-status-error {
  border-color: #e05d4c !important;
  background-color: #ffebe9 !important;
}

.ant-input-affix-wrapper-status-error input {
  background-color: #ffebe9 !important;
}

.ant-input-affix-wrapper-status-error input::placeholder {
  color: #e05d4c !important;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number {
  background: #ffebe9;
  border-color: #e05d4c !important;
  color: #e05d4c !important;
}

.ant-form-item .ant-form-item-control-input,
.ant-form-item .ant-form-vertical .ant-form-item .ant-form-item-control,
.ant-col {
  min-height: auto;
}

.mat-textarea-secondary.ant-input-outlined:hover:hover {
  background-color: var(--newLightGrey);
}

.mat-textarea-secondary.ant-input-affix-wrapper-disabled .ant-input-disabled {
  cursor: not-allowed;
}

[data-theme="dark"] {
  .mat-input-affix-secondary-wrapper:not(.ant-input-number) {
    background-color: var(--purple);
    border: 1px solid var(--darkPurple);
    .ant-input {
      background-color: var(--purple);
      caret-color: var(--secondaryTextColor);
      color: var(--secondaryTextColor);
      &::placeholder {
        color: var(--secondaryTextColor)!important;
      }
    }
    .anticon-close-circle {
      color: var(--secondaryTextColor);
    }
  }
}

@media screen and (max-width: 479px) {
  .mat-form {
    .row-div-container {
      flex-direction: column;
      > * {
        margin-right: 0;
      }
    }
    .mat-form-item {
      margin-bottom: 10px;
    }
    .input-form-antd-phone {
      margin: 0 0 10px 0;
    }
  }
}
