.mat_layout {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: var(--white);
}

.mat_layout_content {
  padding: 0px;
  height: calc(var(--app-height) - 48px);
  overflow-y: auto;
  &:has(.dashboard_users_page) {
    overflow: hidden;
  }
  &:has(.my_default_page_layout) {
    overflow-y: hidden;
  }
}

.mat_layout_content_for_extension {
  .mat_layout_content {
    height: calc(100vh - 50px);
  }
}

.mat_main_layout {
  background-color: var(--white);
}

.mat-outer-layout {
  .mat_layout_content {
    padding: 0;
  }
}

.mat_mobile_layout_content {
  height: calc(100vh - 48px);
}

.mat_mobile_outer_layout {
  height: 100vh;
}

[data-theme='dark'] {
  .mat_layout, .mat_main_layout {
    background-color: var(--darkBackground);
  }
}

@media screen and (max-width: 991px) {
  .mat_layout_content {
    padding: 0;
  }
}

@media screen and (max-width: 479px) {
  .mat_layout {
    height: 100vh;
    height: -webkit-fill-available;
    background-color: var(--white);
  }
  
  .mat_main_layout {
    background-color: var(--white);
  }

  .mat_blue_extension_outer_layout {
    .mat_layout {
      height: 100vh;
    }
  }

  [data-theme='dark'] {
    .mat_layout, .mat_main_layout {
      background-color: var(--darkBackground);
    }
  }
}
