.message_center_panel {
  .message-center-type-wrapper {
    .message-center-text-input-wrapper {
      .message-center-button-wrapper {
        .message-center-button-send {
          background-color: red;
          &:disabled {
            margin-right: 0 !important;
            .anticon {
              svg {
                path {
                  stroke: transparent;
                  fill: var(--secondaryTextColor);
                }
              }
            }
            &:hover {
              svg {
                path {
                  fill: var(--secondaryTextColor) !important;
                }
              }
            }
          }
          &:not(:disabled) {
            background-color: var(--lighter);
            .anticon {
              svg {
                path {
                  fill: var(--mainColor);
                }
              }
            }
          }
        }
        .send-button-with-dropdown-wrapper {
          display: flex;
          align-items: center;
          border-radius: 8px;
          background-color: var(--mainColor);
          margin-right: 4px;
          .message-center-button-send {
            background-color: transparent;
            padding: 0;
            min-width: 28px !important;
            height: 28px !important;
            border-radius: 8px 0 0 8px;
            .anticon {
              svg {
                path {
                  fill: var(--white);
                }
              }
            }
            .anticon-loading {
              svg {
                path {
                  fill: var(--white);
                  opacity: 0.8 !important;
                }
              }
            }
          }
          .message-center-button-send:disabled {
            .anticon {
              svg {
                path {
                  stroke: transparent;
                  fill: var(--white);
                  opacity: 0.2;
                }
              }
            }
            &:hover {
              svg {
                path {
                  fill: var(--white) !important;
                  opacity: 0.2;
                }
              }
            }
          }
          .ant-divider-vertical {
            margin: 0;
            border-left: 1px solid rgba(255, 255, 255, 0.6);
          }
          .mat-select-wrapper {
            margin-right: 0;
            width: 50%;
            .mat-select-sub-wrapper {
              background-color: transparent;
              margin-right: 0;
              border-radius: 0 8px 8px 0;
              border: none;
              height: 28px;
              width: 28px;
              .mat-select {
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-select-selector {
                  padding: 0;
                  border: none;
                  position: absolute;
                  width: 100%;
                  z-index: 1;
                  .ant-select-selection-item {
                    .anticon {
                      display: none;
                    }
                  }
                }
                .ant-select-arrow {
                  position: relative;
                  top: 3px;
                  right: 0px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  color: var(--white);
                }
              }
            }
          }
          .mat-select-dropdown {
            padding: 0px;
            width: 250px !important;
            inset: auto 0px 23px auto !important;
            .ant-select-item {
              .press_enter_icon_wrapper {
                width: 16;
                height: 16;
                justify-content: center;
                align-items: center;
                display: flex;
              }
              &:first-child {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
              }
              &:last-child {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
              }
              .ant-select-item-option-content {
                display: flex;
                align-items: center;
                .not-show {
                  margin-left: 7px;
                  white-space: normal;
                }
                .default {
                  background-color: rgba(0, 203, 133, 0.1);
                  font-size: 10px;
                  color: var(--green);
                  border-radius: 100px;
                  padding: 0 10px;
                  margin-left: 15px;
                }
              }
              &:hover {
                .ant-select-item-option-content {
                  .anticon {
                    svg {
                      path {
                        fill: var(--mainColor);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .message_center_panel-drawer {
    .message_center_panel {
      .message-center-type-wrapper {
        .message-center-text-input-wrapper {
          .message-center-button-wrapper {
            .message-center-button-send:not(:disabled) {
              margin-right: 4px!important;
              .anticon {
                padding-right: 1px;
              }
            }
            .ant-btn > .anticon + span,
            .ant-btn > span + .anticon {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
}
