.my_default_datepicker {
  border: 1px solid var(--newGrey);
  height: 44px;
  padding: 12px 12px 12px 16px;
  border-radius: 8px;
  .ant-picker-input {
    input {
      font-size: 16px;
      font-weight: 500;
      color: var(--black);
    }
  }
  &.grey {
    background-color: var(--newBackgroundGrey);
  }
  &.white {
    background-color: var(--white);
  }
  &:hover,
  &:focus,
  &:focus-within {
    border-color: var(--newGrey);
    box-shadow: none;
  }
}

.my_default_datepicker_dropdown {
  z-index: 10500;
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: var(--mainColor) !important;
    }
  }
}
