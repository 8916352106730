.my_default_rangepicker {
    padding: 12px 12px 12px 16px !important;
    border-radius: 8px;
    height: 44px;
    border: 1px solid var(--newGrey);
    .ant-picker-input {
        input {
            color: var(--black);
            font-size: 16px;
            font-weight: 500;
            &::placeholder {
                color: var(--thridyTextColor);
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
    &.grey {
        background-color: var(--newBackgroundGrey);
    }
    &.white {
        background-color: var(--white);
    }
    &:hover,
    &:focus,
    &:focus-within {
      border-color: var(--newGrey);
      box-shadow: none;
    }
    .ant-picker-active-bar {
        background-color: var(--mainColor);
    }
}

.my_default_rangepicker_dropdown {
    .ant-picker-cell {
        .ant-picker-cell-inner {
            width: 30px;
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
            line-height: 30px;

        }
        &.ant-picker-cell-today {
            .ant-picker-cell-inner {
                &::before {
                    border: 1px solid var(--mainColor);
                    border-radius: 50%;
                }
            }
        }
        &.ant-picker-cell-range-start, &.ant-picker-cell-range-end {
            .ant-picker-cell-inner {
                background-color: var(--mainColor)!important;
                border-start-start-radius: 50%!important;
                border-end-start-radius: 50%!important;
                border-start-end-radius: 50%!important;
                border-end-end-radius: 50%!important;
            }
            &::before {
                height: 30px;
                background-color: var(--mainColorLighter)!important;
            }
        }
        &.ant-picker-cell-in-range {
            &::before {
                background-color: var(--mainColorLighter)!important;
                height: 30px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .my_default_rangepicker_dropdown {
        .ant-picker-date-panel {
            .ant-picker-body {
                padding: 4px 3px;
            }
        }
        .ant-picker-date-panel {
            width: 175px;
        }
        .ant-picker-cell {
            width: 22px;
            max-width: 22px;
        }
        .ant-picker-cell {
            .ant-picker-cell-inner {
                width: 22px;
                min-width: 22px;
                height: 22px;
                line-height: 22px;
                font-size: 12px;
            }
            &.ant-picker-cell-range-start, &.ant-picker-cell-range-end {
                &::before {
                    height: 22px;
                }
            }
            &.ant-picker-cell-in-range {
                &::before {
                    background-color: var(--mainColorLighter)!important;
                    height: 22px;
                }
            }
        }
    }
}