.nps-score-rate-wrapper {
    padding: 12px 0 0;
    margin-top: 12px;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--borderLineColor);
    .anticon {
      &:nth-child(1) {
        margin-right: 10px;
      }
      &:nth-child(3) {
        margin-left: 10px;
      }
    }
    .nps-line-gradient {
      position: relative;
      height: 11px;
      border-radius: 100px;
      background: linear-gradient(90deg, #ee7b6c 0%, #f6cd47 50%, #5bc78b 100%);
      width: 100%;
      border-radius: 100px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .nps-line-transparent {
        background-color: var(--lighter);
        height: 100%;
        border-radius: 100px;
        position: absolute;
        right: -2px;
      }
      .nps-line-circle-wrapper {
        width: 16px;
        height: 16px;
        border-radius: 50px;
        background-color: var(--white);
        top: -3px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 6px 1px rgb(83 85 128 / 10%);
        position: absolute;
        z-index: 1;
        .nps-line-circle {
          background-color: #e2ba64;
          min-width: 8px;
          min-height: 8px;
          border-radius: 50%;
        }
      }
      .nps-score-circle {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--white);
      }
      .nps-score-circle-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
      }
    }
}