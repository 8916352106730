.message_center_panel {
  &--center-list {
    .message {
      margin-top: 25px;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      &--inner-item {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      &:has(.message--inner-item--buttons .mat-btn) {
        .mat-user-profile-letters {
          margin-bottom: 58px;
        }
      }
      .mat-user-profile-letters {
        margin-bottom: 23px;
        margin-right: 6px;
        width: 32px;
        min-width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
      }
      .message--inner-item--images {
        display: flex;
        flex-wrap: wrap;
        width: 165px;
        justify-content: flex-start;
        .ant-image-mask {
          border-radius: 6px;
        }
      }
      .post_info {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .author {
          font-size: 12px;
          color: var(--black);
          font-weight: 600;
        }
        .phone {
          margin-left: 10px;
          font-size: 12px;
          color: var(--thridyTextColor);
          font-weight: 400;
        }
        img {
          margin-left: 7px;
        }
        .incoming_lead_type {
          margin-left: 10px;
          background-color: var(--mainColorLighter);
          color: var(--mainColor);
          border-radius: 100px;
          padding: 2px 10px;
          font-size: 10px;
          font-weight: 700;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: auto;
          max-width: 200px;
        }
      }
      .post {
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
        border-radius: 16px 16px 16px 0;
        background: var(--white);
        border: 1px solid var(--borderLineColor);
        word-break: break-word;
        position: relative;

        .text {
          color: var(--black);
          text-align: left;
          font-weight: 500;
          font-size: 14px;
          white-space: pre-line;
        }

        .date {
          margin-top: 5px;
          font-size: 12px;
          font-weight: 400;
          color: var(--thridyTextColor);
        }
      }
      .message--inner-item--source {
        display: flex;
        align-items: center;
        max-width: 66%;
        width: fit-content;

        .matador_live_chat_123789_lead_page {
          white-space: pre-wrap;
          .matador_live_chat_123789_hr {
            padding: 0;
            margin-top: 10px;
            border-top: 1px solid rgba(163, 166, 190, 0.1);
            margin-bottom: 12px;
          }
        }

        > svg {
          margin-right: 10px;
          min-width: 20px;
          max-width: 20px;
        }
      }
      .message--inner-item--buttons {
        display: flex;
        align-items: center;
        .mat-btn {
          height: 32px;
          font-size: 12px;
          margin-top: 3px;
          + .mat-btn {
            margin-left: 8px;
          }
          &.mark_as_read_btn {
            background-color: var(--green);
          }
        }
      }
      .date {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 400;
        color: var(--thridyTextColor);
        display: flex;
        align-items: center;

        .anticon {
          margin-left: 5px;
        }
      }
      &.email_message {
        max-width: 50%;

        .post_info {
          &.email {
            justify-content: space-between;
            width: 100%;
            margin-right: 0;

            .post_info {
              margin-bottom: 0;
              justify-content: space-between;
              width: 100%;
              .phone {
                margin: 0;
              }
            }

            &-ellipsis {
              max-width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .post {
          width: 100%;

          .post_email {
            border-bottom: 1px solid var(--borderLineColor);
            margin-bottom: 7px;
            .post_email_subject {
              font-size: 10px;
              color: var(--thridyTextColor);
              opacity: 0.7;
              font-weight: 400;
              display: flex;
              margin-bottom: 2px;
            }

            .post_email_subject-body {
              font-size: 14px;
              color: var(--black);
              white-space: pre-wrap;
            }
          }

          .post_email-body {
            font-size: 14px;
            color: var(--black);
            white-space: pre-wrap;
          }
        }

        .date {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .email_reply {
            cursor: pointer;
            font-size: 13px;
            color: var(--mainColor);
            font-weight: 500;
            display: flex;
            align-items: center;

            .anticon {
              margin-right: 4px;
              width: 18px;
              height: 18px;
              background: var(--mainColor);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.2s;
              margin-left: 0;
            }

            &:hover {
              .anticon {
                transform: scale(1.1);
              }
            }
          }
        }
      }
      &.right {
        justify-content: flex-end;
        flex-direction: row;
        .mat-user-profile-letters {
          margin-right: 0px;
          margin-left: 6px;
        }
        .post_info {
          flex-direction: row-reverse;
          margin-right: 10px;
          .author {
            color: var(--mainColor);
          }
          .phone {
            margin-right: 10px;
          }
        }
        .post {
          background-color: var(--mainColor);
          border-radius: 16px 16px 0 16px;
          border: 1px solid transparent;

          .text {
            color: var(--white);
            a {
              color: var(--white);
              font-weight: 900;
              &:hover {
                opacity: 0.5;
              }
            }
          }

          .date {
            color: var(--secondaryTextColor);
            text-align: right;
          }

          &.assign-post {
            flex-direction: row;
            align-items: center;

            .text {
              text-align: right;
            }

            svg {
              margin-right: 0;
              margin-left: 10px;
              min-width: 27px;
              max-width: 27px;
            }
          }
        }
        .message--inner-item--source,
        .message--inner-item,
        .message--inner-item--images {
          align-items: flex-end;
          justify-content: flex-end;
        }
        &.email_message {
          margin-left: auto;

          .post {
            .post_email {
              .post_email_subject {
                color: var(--white);
                justify-content: flex-end;
              }

              .post_email_subject-body {
                color: var(--white);
                text-align: right;
                white-space: pre-wrap;
              }
            }

            .post_email-body {
              color: var(--white);
              text-align: right;
              white-space: pre-wrap;
            }
          }
          .date {
            flex-direction: row-reverse;
          }
        }
      }
    }
    .ant-list-footer {
      padding: 0;
    }
  }
}

@media screen and (max-width: 479px) {
  .message_center_panel {
    &--center-list {
      .message {
        .message--inner-item--source {
          max-width: 100%;
        }
        &.email_message {
          max-width: 80%;
        }
      }
    }
  }
}
