.mismatch_block_wrapper {
  width: 100%;
  padding: 14px 15px;
  .mismatch_block {
    border-radius: 12px;
    padding: 24px;
    border: 1px solid var(--blue);
    background-color: var(--blueLighter);
    &--title {
      font-size: 16px;
      font-weight: 500;
      color: var(--black);
    }
    &--old_value {
      font-size: 14px;
      font-weight: 500;
      color: var(--thridyTextColor);
    }
    &--new_value {
      font-size: 14px;
      font-weight: 500;
      color: var(--black);
    }
    &--mismatch_warning {
      border-radius: 12px;
      border: 1px solid var(--red);
      background-color: var(--lightRedSecondary);
      padding: 8px 12px;
      margin-top: 16px;
      font-size: 14px;
      font-weight: 500;
      color: var(--red);
      display: flex;
      align-items: center;
      gap: 9px;
    }
    &--buttons_wrapper {
      padding-top: 24px;
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      gap: 12px;
    }
  }
}
