.my_default_assign_user_dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding: 2px 0px;
      background-color: transparent !important;
      .ant-dropdown-menu-title-content {
        font-weight: 500;
        color: var(--textColor);
        font-size: 14px;
        padding: 8px;
        border-radius: 8px;
        width: 100%;
        flex: none;
        padding-right: 21px;
      }
      &:has(.ant-dropdown-menu-item-extra) {
        .ant-dropdown-menu-title-content {
          background-color: var(--mainColorLighter);
          color: var(--mainColor);
        }
      }
    }
    .ant-dropdown-menu-item-selected,
    .ant-dropdown-menu-item-active {
      background-color: transparent !important;
      .ant-dropdown-menu-title-content {
        background-color: var(--mainColorLighter);
        color: var(--mainColor);
      }
    }
  }
}
