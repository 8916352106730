.dashboard_influenced_sales {
    background: linear-gradient(133deg, #FDFEFF, #B8BFFF);
    .dashboard_block--header {
        margin-bottom: 10px;
    }
    .dashboard_block--header--right {
        span {
            color: var(--darkBlack);
        }
    }
    &--text {
        color: var(--textColor);
        font-weight: 400;
        line-height: 18px;
    }
    .mat-btn {
        margin-top: 18px;
    }
}