.message_center_panel {
    .center-sms-drip-wrapper {
        padding: 5px 15px 0px 15px;
        .ant-collapse-content-active {
          border-top: 1px solid var(--borderLineColor);
        }
        .center-sms-drip-collapse {
          border: 1px solid var(--borderLineColor);
          background-color: var(--white);
          .ant-collapse-header {
            align-items: center;
          }
          .scheduled-message-type {
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            letter-spacing: 0.2px;
            color: var(--mainColor);
            background-color: var(--mainColorLighter);
            border-radius: 20px;
            padding: 3px 8px 4px 8px;
            margin-right: 12px;
          }
          .label-wrapper {
            display: flex;
            justify-content: space-between;
            .label-and-icon-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              span {
                line-height: normal;
              }
              .collapse-title-text {
                color: var(--black);
                font-size: 12px;
                margin-right: 8px;
                font-weight: 600;
              }
              .collapse-title-text-mobile {
                color: var(--black);
                font-size: 12px;
                margin-right: 12px;
                font-weight: 600;
                margin-left: 6px;
              }
              .date-time-text {
                margin-left: 4px;
                color: var(--thridyTextColor);
                font-size: 12px;
                font-weight: 500;
                line-height: normal;
              }
              .date-time-text-mobile {
                color: var(--thridyTextColor);
                font-size: 12px;
                font-weight: 500;
              }
            }
            .mat-btn {
              padding: 3px 12px;
              border-radius: 60px;
              font-size: 12px;
              border: none;
              margin-right: 12px;
              color: var(--white);
              height: 24px;
            }
          }
          .children-wrapper {
            padding-top: 5px;
            .title-wrapper {
              display: flex;
              .title {
                font-size: 12px;
                font-weight: 500;
                min-width: 50px;
                margin-right: 4px;
              }
              .value {
                color: var(--thridyTextColor);
                font-size: 12px;
                font-weight: 400;
              }
            }
            .children-footer-mobile {
              margin-top: 2px;
              .date-time-text {
                margin-left: 4px;
                color: var(--thridyTextColor);
                font-size: 12px;
                font-weight: 500;
              }
              .mat-btn {
                padding: 3px 12px;
                border-radius: 60px;
                width: 100%;
                margin-top: 8px;
              }
            }
          }
        }
    }
}

body:has(.skip-scheduled-message-drawer) {
  .message_center_panel-drawer.ant-drawer-open {
    .ant-drawer-content-wrapper {
      transform: none !important;
    }
  }
}

.skip-scheduled-message-drawer {
  .ant-drawer-header {
    padding: 20px 15px 0;
    border-bottom: none;
    .ant-drawer-close {
      display: flex;
    }
  }
  .ant-drawer-body {
    padding: 16px 20px;
    .message_center_panel {
      overflow-y: auto;
      max-height: 300px;
    }
    .center-sms-drip-wrapper {
      padding: 0;
      .center-sms-drip-collapse {
        border-style: none;
        border-radius: 0;
        .mat-btn + .mat-btn {
          margin-left: 0;
        }
        .children-footer-mobile {
          .row-div-container {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .message_center_panel-drawer {
    .message_center_panel {
      .center-sms-drip-wrapper {
        .center-sms-drip-collapse {
          .ant-collapse-header {
            cursor: pointer;
          }
          .children-wrapper {
            .title-wrapper {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
  .message_center_panel-drawer-for-mobile {
    .center-sms-drip-wrapper {
      padding: 5px 0;
    }
  }
}