.message_right_panel {
  &--top-side {
    .message_contest_wrapper_block {
      &--header {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          color: var(--mainColor);
          margin-bottom: 16px;
          .anticon {
            margin-right: 4px;
          }
      }
      &--body {
        position: relative;
        &-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: center;
          padding-top: 0;
          margin-top: 12px;
          h6 {
            font-size: 10px;
            color: var(--mainColor);
            margin: 0;
            line-height: normal;
            width: 100%;
            text-align: left;
            padding-top: 3px;
          }
          .opt-out-btn {
            background-color: var(--mainColorLighter);
            color: var(--mainColor);
            &:hover {
              background-color: var(--mainColorLighter);
              color: var(--mainColor);
            }
          }
          .mat-btn {
            width: 85px;
          }
          .consent-btns-wrappers {
            padding-left: 5px;
            .mat-btn + .mat-btn {
              margin-left: 0;
              margin-top: 8px;
            }
          }
          &-text {
            p {
              font-size: 10px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%;
              text-align: left;
              color: var(--thridyTextColor);
              letter-spacing: 0.2px;
            }
          }
        }
        .consent_info_buttons {
          width: 75px;
          height: 25px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          padding: 4px 8px;
          gap: 10px;
          border-radius: 100px;
          border: 1px solid var(--newGrey);
          p {
            margin-bottom: 0;
            font-size: 12px;
            line-height: 14px;
            font-weight: 600;
            color: var(--thridyTextColor);
          }
          &.green {
            background-color: var(--green);
            border: 1px solid var(--green);
            p {
              color: var(--white);
            }
          }
          &.yellow {
            background-color: var(--yellow);
            border: 1px solid var(--yellow);
            p {
              color: var(--white);
            }
          }
          &.red {
            background-color: var(--red);
            border: 1px solid var(--red);
            p {
              color: var(--white);
            }
          }
        }
      }
      & + .message_contest_wrapper_block {
          margin-top: 16px;
          padding-top: 16px;
          border-top: 1px solid var(--borderLineColor);
      }
    }
  }
}