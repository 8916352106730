.my_default_switch {
  border-bottom: 1px solid var(--borderLineColor);
  padding: 12px 0;
  gap: 8px;
  &--inner {
    display: flex;
    align-items: center;
    gap: 15px;
    .title {
      font-size: 12px;
      font-weight: 500;
      color: var(--black);
    }
    .mat-form-item {
      margin-bottom: 0;
      line-height: normal;
      width: auto;
      margin-left: 15px;
      margin-right: 0 !important;
      .ant-form-item-control-input {
        min-height: auto;
      }
    }
    &.left_icon {
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 8px;
    }
    .icon {
      display: flex;
    }
    &.switch_left_side {
      .title {
        font-size: 14px;
        font-weight: 500;
        color: var(--secondaryTextColor);
        cursor: pointer;
      }
      small {
        color: var(--green);
        font-weight: 500;
        font-size: 14px;
      }
    }
    .my_default_input_number {
      margin: 0 8px;
      max-width: 55px;
      padding: 8px;
    }
  }
  &.last {
    border-bottom: none;
    padding-bottom: 0;
  }
  &.first {
    padding-top: 0;
  }
  &.no_border {
    border-bottom: none;
  }
  &.hasAdditionalDetails {
    border-bottom: none;
    padding-bottom: 16px;
  }
  &.disable_padding {
    padding: 0;
  }
  &.full_width {
    width: 100%;
  }
}
