

.livechat-announcements-wrapper {
  .my-page {
    max-width: none;
    &:first-child {
      margin-bottom: 50px;
    }
  }
  .list-container {
    .list-item {
      + .list-item {
        margin-top: 10px;
      }
    }
  }
  .livechat-announcement-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    .left-content {
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        color: var(--secondaryTextColor);
        font-weight: 400;
        margin-right: 5px;
      }
      span {
        background-color: #f8a326;
        color: var(--white);
        padding: 2px 10px;
        border-radius: 100px;
        font-weight: 700;
      }
    }
    .right-content {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.livechat-announcement-item-wrapper {
  border: 1px solid var(--borderLineColor);
  border-radius: 9px;
  padding: 10px;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 16%);
  }
  .ant-image {
    margin-right: 10px;
  }
  .announcement-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  .announcement-title {
    p {
      font-weight: 500;
      font-size: 14px;
      color: var(--textColor);
      word-break: break-word;
      overflow-wrap: break-word;
      &:last-child {
        font-weight: 400;
        font-size: 12px;
        color: var(--secondaryTextColor);
      }
    }
  }
  .announcement-item-actions {
    width: fit-content;
  }
  .btn-block-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    cursor: pointer;
    margin-right: 0;
    &:hover {
      background-color: var(--mainColorLighter);
    }
  }
}

.first-part-announcement-item {
  display: flex;
}
