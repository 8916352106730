.mat-radio-group {
  .ant-radio-wrapper {
    border: 1px solid var(--grey);
    border-radius: 4px;
    width: 100%;
    padding: 15px 16px;
    line-height: normal;
    background-color: var(--white);
    display: flex;
    align-items: center;
    .ant-radio {
      top: 0;
      + span {
        color: var(--thridyTextColor);
        font-size: 16px;
        font-weight: 600;
      }
      .ant-radio-inner {
        width: 20px;
        height: 20px;
        border-color: var(--grey);
        &::after {
          width: 32px;
          height: 32px;
          top: 1px;
          left: 1px;
          background-color: var(--mainColor);
        }
      }
    }
    &.ant-radio-wrapper-checked {
      border: 1px solid var(--mainColor);
      background-color: var(--mainColorLighter);
      .ant-radio {
        .ant-radio-inner {
          border-color: var(--mainColor);
        }
        + span {
          color: var(--mainColor);
        }
      }
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: transparent;
    }
  }
}

.radio-label {
  height: auto;
  text-align: left;
  letter-spacing: 0.58px;
  color: var(--thridyTextColor);
  text-transform: uppercase;
  opacity: 1;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 15px;
  padding-bottom: 12px;
}

.mat-radio-group-secondary {
  margin: 20px 0px;
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: var(--mainColor);
      background-color: transparent;
      &:after {
        background-color: var(--mainColor);
      }
    }
  }
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-radio-wrapper .ant-radio {
  margin-bottom: 7px;
}

.ant-radio-checked {
  .ant-radio-inner {
    border-color: var(--mainColor) !important;
    &:after {
      background-color: var(--mainColor);
    }
  }
}

.ant-radio:hover {
  .ant-radio-inner {
    border-color: var(--mainColor);
  }
}

.ant-radio-inner {
  width: 20px;
  height: 20px;
}
