.popup_main-container {
  font-family: 'Averta CY';

  .popup_container {
    display: flex;

    .popup_right-side {
      flex: 1 1 40.073529411%;
      display: flex;
      position: relative;
      border-radius: 0px 8px 8px 0px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .overlay {
        content: '\A';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.2;
        border-radius: 0px 8px 8px 0px;
        background-color: #2fac65;
      }
    }

    .popup_left-side {
      flex: 1 1 41%;
      padding-left: 84px;
      padding-bottom: 25px;
      padding-top: 138px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .popup_subtitle {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #2fac65;
        margin-bottom: 8px;
      }

      .popup_header {
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        color: #0e0e0e;
        margin-bottom: 4px;
      }

      .popup_description {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #77808d;
        margin-bottom: 26px;
        max-width: 235px;
      }

      .popup_button {
        background-color: #2fac65;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        width: 321px;
        height: 46px;
        border: none;
        border-radius: 8px;
        margin-bottom: 13px;
        margin-top: 12px;
        width: 100%;
      }

      .popup_terms {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #abaeb3;
        max-width: 302px;

        a {
          text-decoration: underline;
          color: #abaeb3;
        }
      }

      .popup_input {
        font-size: 16px;
        line-height: 20px;
        color: black;
        padding-top: 14px;
        padding-bottom: 14px;
        height: 46px;
        border-color: #e5e9f2;
        border-radius: 8px;
        width: 100%;

        &.popup_input_flag {
          background: none;
          border-right-color: #e5e9f2;
        }
      }

      .popup_logo {
        padding-top: 87px;

        img {
          width: 65px;
        }

        p {
          font-size: 10px;
          color: #77808d;
          padding-right: 5px;
        }
      }
    }
  }

  .popup_main-container--preview {
    max-width: 100%;

    .popup_container {
      .popup_left-side {
        padding-left: 16px !important;
        padding-right: 15px;
      }
    }
  }

  .popup_main-container--preview--no_image {
    width: fit-content;
    padding: 0 50px;
  }
}

@media (max-width: 768px) {
  .popup_main-container {
    .popup_container {
      .popup_left-side {
        padding-top: 80px;
        padding-left: 32px;
        .popup_terms {
          max-width: 250px;
        }
        .popup_input,
        .popup_button {
          width: 250px;
        }
        .popup_logo {
          padding-top: 40px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .popup_main-container {
    .popup_container {
      .popup_right-side {
        img {
          height: 100%;
          object-fit: cover;
        }
      }
      .popup_left-side {
        .popup_logo {
          padding-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  .popup_main-container--preview {
    .popup_container {
      .popup_left-side {
        .popup_button,
        .popup_input {
          max-width: 250px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .popup_main-container--preview {
    .popup_container {
      .popup_left-side {
        .popup_button,
        .popup_input {
          max-width: 150px;
        }
        .popup_header {
          font-size: 19x;
          line-height: 26px;
        }
        .popup_description {
          font-size: 12px;
          max-width: 150px;
        }
        .popup_terms {
          max-width: 150px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .popup_main-container--preview {
    .popup_left-side {
      padding-left: 10px !important;
      padding-right: 10px;
    }
  }
}

@media (max-width: 992px) {
  .popup_main-container--preview {
    .popup_left-side {
      .popup_button,
      .popup_input {
        max-width: 120px;
      }
      .popup_header {
        font-size: 18px;
        line-height: 26px;
      }
      .popup_description {
        font-size: 12px;
        max-width: 150px;
        line-height: 12px;
      }
      .popup_terms {
        max-width: 120px;
      }
    }
  }
}
