.page-edit-wrapper {
  .my-page {
    max-width: 550px;
    width: 100%;
    padding-right: 50px;
    border-right: 1px solid var(--borderLineColor);
    height: 100%;
  }
}

.user-template-org-wrapper {
  .list-item {
    + .list-item {
      margin-top: 10px;
    }
  }
}

.template-edit-image-upload {
  .ant-upload {
    width: 102px !important;
    height: 102px !important;
  }
}

@media screen and (max-width: 767px) {
  .page-edit-wrapper {
    .my-page {
      max-width: 100%;
      border-right: 0px solid transparent;
      padding-right: 0px;
    }
  }
}
