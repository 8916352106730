.main-steps-wrapper {
    height: calc(100% - 20px);
    background-color: var(--white);
    margin: 20px 50px 0px 42px;
    border-radius: 20px 20px 0 0;
    .steps-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      border-radius: 20px 20px 0 0;
      background-color: var(--white);
      overflow: hidden;
      .steps-block {
        max-width: 650px;
        width: 100%;
      }
      .create-campaign-types {
        height: auto;
        margin: 48px 0 0 0;
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        width: 100%;
      }
    }
}

.mobile-steps-wrapper {
  .my-responsive-steps-wrapper {
      display: flex;
      align-items: center;
      padding: 20px 0;
      .my-responsive-step-icon {
          width: 30px;
          height: 30px;
          border: 1px solid var(--secondaryTextColor);
          border-radius: 50%;
      }
      .my-responsive-step-text-wrapper {
          padding-left: 15px;
          .my-responsive-step-text-steps {
              display: flex;
              align-items: center;
              div {
                  font-size: 12px;
                  font-weight: 400;
                  &:nth-child(1) {
                      color: var(--black);
                      text-transform: uppercase;
                      font-weight: bold;
                  }
                  &:nth-child(2) {
                      color: var(--thridyTextColor);
                  }
              }
          }
          .my-responsive-step-text-info {
              display: flex;
              align-items: center;
              div {
                  font-size: 14px;
                  font-weight: 400;

                  &:nth-child(1) {
                      color: var(--black);
                      font-weight: bold;
                  }

                  &:nth-child(2) {
                      color: var(--thridyTextColor);
                      margin-left: 5px;
                      font-size: 12px;
                  }
              }
          }
      }
  }
  .my-responsive-steps-progress {
      height: 6px;
      width: 100vw;
      background-color: var(--lighter);
      margin-left: -20px;
      .my-responsive-steps-progress-line {
          background-color: var(--green);
          height: 100%;
          border-radius: 0 100px 100px 0;
      }
  }
}

@media screen and (max-width: 991px) {
  .main-steps-wrapper {
      margin: 0;
      height: 100%;
      .steps-wrapper {
          border-radius: 0;
          padding: 20px;
      }
  }
}

@media screen and (max-width: 767px) {
  .main-steps-wrapper {
      .steps-wrapper {
          .steps-block {
              max-width: 100%;
          }
      }
  }
}

@media screen and (max-width: 479px) {
  .main-steps-wrapper {
      .steps-wrapper {
          .create-campaign-types {
              .create-campaign-types-block {
                  padding: 0;
              }
          }
      }
  }
}