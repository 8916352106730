.message_center_panel {
  background-color: var(--white);
  width: 100%;
  margin-left: 0px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0;
  border-top: 1px solid var(--borderLineColor);
  .anticon,
  svg {
    overflow: visible;
  }

  &--center-list  {
    overflow-x: hidden;
    width: 100%;
    height: calc(100% - 170px);
    .ant-list-items {
      padding: 20px 15px;
    }
  }
}

.message-list-center-no-conversation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.message_center_panel-drawer {
  overflow-y: auto;
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
    .message_center_panel {
      margin-left: 0;
      border-top: 1px solid var(--borderLineColor);
      border-radius: 0;
      &--center-list {
        height: calc(100vh - 195px);

        .track-horizontal {
          overflow: hidden !important;
          height: 0 !important;
        }

        &-ios {
          height: calc(100vh - 195px);
        }
        &-ios12 {
          height: calc(100vh - 270px);
        }
      }
    }
  }
}

@media screen and (max-height: 740px) {
  .message_center_panel-drawer {
    .ant-drawer-body {
      .message_center_panel {
        &--center-list {
          height: calc(100vh - 200px);
        }
      }
    }
  }
}

@media screen and (max-height: 736px) {
  .message_center_panel-drawer {
    .ant-drawer-body {
      .message_center_panel {
        &--center-list {
          height: calc(100vh - 300px);
        }
      }
    }
  }
}

@media screen and (max-height: 667px) {
  .message_center_panel-drawer {
    .ant-drawer-body {
      .message_center_panel {
        &--center-list {
          height: calc(100vh - 265px);
        }
      }
    }
  }
}

@media screen and (max-height: 400px) {
  .message_center_panel-drawer {
    .ant-drawer-body {
      .message_center_panel {
        &--center-list {
          height: calc(100vh - 100px);
        }
      }
    }
  }
}
