.mat-footer {
  background-color: var(--white);
  padding: 12px 32px;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  border-top: 1px solid rgba(229, 233, 242, 1);
  gap: 12px;
}

@media screen and (max-width: 991px) {
  .mat-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 590px) {
  .mat-footer {
    padding-right: 20px;
  }
}
