.time-picker-block-wrapper {
  display: flex;
  justify-content: space-between;
  .with-select {
    display: flex;
    align-items: center;
  }
  .time-picker-block {
    display: flex;
    align-items: center;
    border: 1px solid var(--borderLineColor);
    border-radius: 8px;
    .point {
      display: flex;
      padding: 0 5px;
    }
    input {
      border: 2px solid transparent;
      border-radius: 8px;
      height: 42px;
      padding: 5px;
      width: 39px;
      text-align: center;
      font-size: 14px;
      &:focus {
        border: 2px solid var(--mainColor);
        font-weight: bold;
      }
    }
  }
  .mat-select-wrapper {
    .mat-select-sub-wrapper {
      height: 44px;
      margin-left: 12px;
      border-radius: 8px;
      border-style: none;
      width: 76px;
      margin-bottom: 0;
      padding-bottom: 0;
      .mat-select {
        &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid transparent !important;
          border-radius: 8px;
          border-style: none !important;
          background-color: var(--mainColorLighter);
          .ant-select-selection-item {
            line-height: 44px;
            color: var(--mainColor);
            padding-right: 8px;
          }
        }
        .ant-select-arrow {
          top: 50%;
          color: var(--mainColor);
        }
      }
    }
  }
  .mat-btn {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
    }
  }
}
