.create-campaign-types-modal {
  margin: 0 0 30px !important;
  .create-campaign-form-wrapper {
    .content-mat-textarea-wrapper {
      .textarea-with-upload-message {
        .ant-form-item-explain-error {
          bottom: -27px;
        }
      }
      .mat-textarea {
        .ant-input-data-count {
          bottom: -30px;
        }
      }
    }
  }
}

.create-campaign-types {
  height: calc(100% - 20px);
  background-color: var(--white);
  margin: 20px 50px 0px 42px;
  border-radius: 20px 20px 0 0;
  &.first-create-campaign-page {
    background-color: transparent;
    box-shadow: none;
    height: calc(100% - 68px);
    .create-campaign-types-block {
      background-color: transparent;
    }
  }
  .create-campaign-types-block {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-radius: 20px 20px 0 0;
    background-color: var(--white);
    overflow: hidden;
    .create-campaign-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 600px;
      text-align: center;
      margin-bottom: 36px;

      h2 {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--black);
        font-size: 26px;
        line-height: 32px;
      }

      span {
        font-size: 16px;
        margin-top: 15px;
        color: var(--thridyTextColor);
        font-weight: 400;
        line-height: 20px;
      }
      a {
        color: var(--mainColor);
      }
    }
    .create-campaign-body {
      max-width: 700px;
      width: 100%;
      .exclude-switch-with-input {
        display: flex;
        align-items: center;
        .mat-custom-input-number-wrapper {
          width: 90px;
          padding: 10px;
        }
      }
      .exclude-switch-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin: 20px 0;
        .switch-label {
          padding-bottom: 0;
          padding-left: 10px;
        }
      }
      .create-campaign-form-wrapper {
        max-width: 520px;
        width: 100%;
        margin: 0 auto;
        .radio-group-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 25px;
          .ant-radio-group {
            display: flex;
            width: 100%;
            .ant-radio-wrapper {
              margin-right: 20px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .switch-row {
          .mat-switch-wrapper {
            margin-right: 12px;
          }
          .switch-text {
            right: 12px;
            font-size: 14px;
            font-weight: 400;
            top: 0px;
            right: 0px;
          }
        }
        .mat-rangepicker-wrapper {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .create-campaign-types {
    margin: 0;
    border-radius: 0;
    height: 100%;
    .create-campaign-types-block {
      padding: 20px;
    }
  }
}
