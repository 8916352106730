.crm_items_block {
  display: flex;
  align-items: center;
  background-color: var(--lightgrey);
  padding: 4px 8px;
  border-radius: 100px;
  margin-left: 8px;
  &--title {
    color: var(--black);
    font-weight: 500;
    font-size: 12px;
    border-right: 1px solid var(--newGrey);
    padding-right: 8px;
    margin-right: 8px;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .mat-btn {
    background-color: transparent;
    + .mat-btn {
      margin-left: 8px;
    }
  }
}