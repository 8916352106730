.my_default_organization_opening_hours_responsive {
  border: 1px solid var(--borderLineColor);
  background-color: transparent;
  border-radius: 10px !important;
  &-item {
    background-color: var(--newBackgroundGrey);
    border-radius: 10px !important;
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
    .ant-collapse-header {
      align-items: center !important;
    }
  }
}
