.mat-select-tree-dropdown {
  padding: 0;
  border: 1px solid var(--grey);
  border-radius: 8px;
  box-shadow: 4px 7px 40px fade(#000, 5%);

  .ant-select-tree-node-content-wrapper {
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 5px;
    color: var(--black);
    background-color: var(--white);
    font-weight: 500;
    font-size: 14px;

    &:hover {
      background-color: var(--mainColorLighter);
    }
  }

  .ant-select-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: var(--mainColor);
    color: var(--white);
  }
}
