.list-variables {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .add-variable {
    color: var(--mainColor);
    background-color: var(--mainColorLighter);
    border: none;
    margin-left: 5px;
    margin-bottom: 5px;
    font-size: 13px;
  }
  .add-variable:hover {
    color: var(--mainColor) !important;
  }
  .add-variable.add-variable-secondary {
    border: 1px solid transparent;
    border-radius: 100px;
    background-color: var(--blueLighter);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    font-size: 14px;
    font-weight: 600;
    color: var(--blue);
  }
}
