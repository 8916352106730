.message_date_picker_dropdown {
  &.mat-modal {
    .ant-modal-body {
      padding: 20px 16px;
      .modal-btn {
        .mat-btn + .mat-btn {
          margin-left: 0;
          margin-top: 10px;
        }
        .mat-btn {
          width: 100%;
          background-color: var(--newLightGrey);
          border: 1px solid var(--newGrey);
          color: var(--thridyTextColor);
        }
      }
    }
    .modal-content-wrapper {
      padding: 0;
    }
  }
  input:invalid {
    border: var(--red) solid 2px;
  }
  .react-datepicker {
    width: 100%;
    border-bottom: 1px solid var(--borderLineColor) !important;
    box-shadow: none;
    padding: 0px 0 20px 0;
    margin-bottom: 20px;
    display: flex;
    border-radius: 0;
    .react-datepicker__navigation {
      background: none;
      line-height: 1.7rem;
      text-align: center;
      cursor: pointer;
      position: absolute;
      top: 10px;
      width: 0;
      padding: 0;
      border: 0.45rem solid transparent;
      z-index: 1;
      height: 10px;
      width: 10px;
      text-indent: -999em;
      overflow: hidden;
    }
    .react-datepicker__navigation--previous {
      left: 10px;
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous {
      right: 10px;
    }
    .react-datepicker__navigation-icon::before {
      border-color: transparent;
    }
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
      top: 6px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      border-style: none;
      background-size: contain;
    }
    .react-datepicker__navigation--next {
      background-image: url('../../../../../icons/arrow-right.png');
    }
    .react-datepicker__navigation--previous {
      background-image: url('../../../../../icons/arrow-left.png');
    }
    .react-datepicker__month-container {
      width: 100%;
      .react-datepicker__header {
        background-color: transparent;
        border-style: none;
        padding: 0;
      }
      .react-datepicker__current-month {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--mainColor);
      }
      .react-datepicker__day-names {
        display: flex;
        justify-content: space-around;
      }
      .react-datepicker__month {
        margin: 0;
      }
      .react-datepicker__day-name {
        font-weight: 600;
        font-size: 14px;
        color: var(--secondaryTextColor);
      }
      .react-datepicker__week {
        display: flex;
        justify-content: space-around;
      }
      .react-datepicker__day {
        width: 42px;
        height: 42px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
      }
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--selected {
        background-color: var(--mainColorLighter) !important;
        color: var(--mainColor) !important;
        border: 1px solid var(--mainColor);
      }
    }
  }
  .ant-popover-title {
    background-color: var(--lightgrey);
    font-size: 16px;
    color: var(--purple);
    font-weight: 500;
    padding: 15px 19px;
    line-height: normal;
    border-style: none;
    max-width: 326px;
    border-radius: 8px 8px 0 0;
  }
  .ant-popover-inner {
    padding: 0;
  }
  .ant-popover-inner-content {
    padding: 12px 16px;
  }
  .close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
}

.date-time-showed-wrapper {
  border-top: 1px solid var(--borderLineColor);
  margin-top: 20px;
  padding-top: 20px;
  span.anticon {
    margin-right: 12px;
  }
}

.send_message_later_icon {
  &.mat-btn.icon-size[disabled] {
    .anticon {
      svg {
        path {
          stroke: transparent;
          fill: var(--mainColor) !important;
        }
      }
    }
  }
}
