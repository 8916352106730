.dashboard_reputation_pulse {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    gap: 20px;
    .dashboard_block {
        width: 100%;
    }
    .dashboard_metric_block {
        .dashboard_block--header {
            margin-bottom: 20px;
        }
        .ant-divider-horizontal {
            margin: 10px 0 0;
            border-block-start: none!important;
        }
        .dashboard_block--tooltip-progress {
            width: 100%;
            margin-top: 13px;
            .ant-progress {
                .ant-progress-outer {
                    width: 100%!important;
                }
            }
        }
    }
}

@media screen and (max-width: 1090px) {
    .dashboard_reputation_pulse {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 767px) {
    .dashboard_reputation_pulse {
        grid-template-columns: auto;
        .dashboard_block{
            grid-column: 1 / 3;
        }
    }
}