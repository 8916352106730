.messages_left_panel {
  &--header {
    .conversation_slider_with_edit_button {
      position: relative;
      padding: 0 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      gap: 4px;
      .message_page_toggle {
        background-color: transparent;
        border: 1px solid #DEE0FA;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 8px;
        color: var(--black);
        padding: 5px;
        text-align: center;
        transition: 0.5s;
        display: flex!important;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 8px 12px;
        border-radius: 12px;
        min-height: 40px;
        width: 100%;
        box-shadow: 2px 2px 40px 0px rgba(83, 85, 128, 0.10);
        &.active {
          border: 1px solid var(--mainColor);
          color: var(--white);
          background-color: var(--mainColor);
          &.action_required {
            border: 1px solid var(--mainColor);
            .counter {
              border: 1px solid var(--red);
              background-color: var(--red);
              color: var(--white);
            }
          }
        }
        .counter {
          border: 1px solid var(--red);
          border-radius: 100px;
          margin-left: 6px;
          font-size: 10px;
          color: var(--red);
          font-weight: 600;
          min-width: 20px;
          padding: 2px 3px 2px 2px;
          height: 20px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .toggle-text {
          text-align: left;
          line-height: 8px;
          overflow: visible;
          line-height: 14px;
          white-space: nowrap;
        }
        &.action_required {
          grid-column: 1 / span 2;
          box-shadow: 2px 2px 40px 0px rgba(83, 85, 128, 0.10);
          border: 1px solid rgba(224, 93, 76, 0.33);
        }
      }
      .select_all_toggle {
        background-color: var(--mainColor);
        border-radius: 20px;
        color: var(--white);
        width: 114px;
        height: 32px;
        text-align: center;
        border: none;
        margin-right: 8px;
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .messages_left_panel--header {
    .conversation_slider_with_edit_button {
      .message_page_toggle {
        width: 100%;
      }
    }
  }
}

