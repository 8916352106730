.users-page-wrapper {
  height: auto;
  padding: 0px 20px 20px 12px;
}

.user-page-header {
  display: flex;
  padding-bottom: 20px;
  .mat-select-wrapper-secondary {
    width: 160px;
    overflow: visible;
  }
  .inputWithSearch {
    max-width: 400px;
    background-color: var(--newLightGrey) !important;
    border: 1px solid var(--newGrey) !important;
    border-radius: 8px !important;
  }
}

.user-list-item {
  padding: 24px;
  border-radius: 12px;
  background-color: var(--white);
  .row-div-container {
    > * {
      margin-right: 0;
    }
  }
  .mat-user-profile-letters {
    margin-right: 12px;
  }
}

.user-list-fullName {
  font-size: 14px;
  font-weight: 700;
}

.user-list-edit {
  margin-left: 4px;
  cursor: pointer;
  fill: var(--secondaryTextColor);
}

.user-list-created-date {
  color: var(--secondaryTextColor);
  font-weight: 500;
  font-size: 12px;
}

.user-list-field-item {
  padding: 10px 0;
  border-bottom: 1px solid var(--borderLineColor);
  .row-div-container {
    width: 40%;
    &:nth-child(2) {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: end;
      display: block;
      svg {
        margin-right: 0 !important;
        fill: #29cc97;
      }
    }
  }
  svg {
    fill: var(--secondaryTextColor);
    margin-right: 10px !important;
  }
}

.user-list-field-show-more {
  padding-top: 15px;
  color: var(--mainColor);
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  svg {
    margin-left: 10px;
    fill: var(--mainColor);
  }
}

/* EDIT MODAL  */
.users-page-edit-modal {
  .ant-modal-body {
    padding: 10px 0 0 !important;
    .modal-content-wrapper {
      .ant-form {
        > * {
          padding: 0 30px;
        }
        .ant-collapse {
          padding: 0;
        }
      }
    }
    .users-page-shown-list-body {
      padding: 0 30px;
      .user-list-field-item {
        .row-div-container {
          width: 50%;
        }
      }
    }
  }
  .modal-content-wrapper {
    padding: 0;
  }
  .mat-select-wrapper {
    .mat-select-sub-wrapper {
      height: 48px;
    }
  }
  .dealerSocket-input,
  .vinSolution-input {
    margin-top: 20px;
  }
  .users-page-modal-checkbox-items {
    .mat-radio-group {
      display: flex;
      justify-content: space-between;
      margin: 25px 0;
      .ant-radio-wrapper {
        padding: 10px;
        border-radius: 100px;
        border: 1px solid var(--mainColor);
        width: 24%;
        margin: 0;
        .ant-radio {
          display: none;
          + span {
            font-size: 12px;
            text-align: center;
            width: 100%;
            color: var(--mainColor);
            font-weight: 500;
            padding: 0;
          }
        }
        &.ant-radio-wrapper-checked {
          background-color: var(--mainColor);
          .ant-radio {
            + span {
              color: var(--white);
            }
          }
        }
      }
    }
  }
  .notification-collapse-wrapper {
    .ant-collapse-item {
      .ant-collapse-header {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 400;
        padding-left: 30px;
        border: 1px var(--borderLineColor);
        border-style: solid none;
        font-size: 12px;
        .ant-collapse-arrow {
          right: 30px;
        }
      }
      .ant-collapse-content {
        padding-left: 14px;
        padding-right: 14px;
      }
    }
    .users-edit-checkbox-wrapper {
      margin: 15px 0;
      .mat-checkbox-wrapper {
        width: 100%;
        align-items: center;
      }
    }
  }
  .notification-item {
    margin-bottom: 25px;
    .all-orgs-checkbox {
      margin-bottom: 10px;
    }
    .select-orgs-dropdown {
      margin-bottom: 10px;
    }
    .method-checkbox {
      label {
        padding-top: 0;
      }
    }
    .switch-row {
      margin-bottom: 10px;
      .text-with-icon {
        width: 100%;
        margin-right: 0;
      }
    }
    .delay-notification {
      margin-top: 25px;
    }
  }
}

/* shared with component in V2ProfileNotification also */
.delay-notification {
  display: flex;
  align-items: center;
  background-color: #ecefefb2;
  border-radius: 8px;
  padding: 6px 12px;
  gap: 4px;
  opacity: 1;
  p {
    font-size: 12px;
    color: var(--mainColor);
    font-weight: 500;
  }
  .mat-form-item {
    margin-bottom: 0px;
    width: auto;
    .ant-form-item-control-input {
      min-height: auto;
    }
  }
  .delay-input-notification {
    height: 100%;
    width: 50px;
    margin: 0 10px;
    padding: 0;
    border-radius: 8px !important;
    input {
      text-align: center;
    }
  }
}

.users-page-show-more-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.users-page-show-more-header-item {
  .row-div-container {
    justify-content: center;
    margin-right: 0;
    text-align: center;
    width: 90%;
    .user-list-fullName {
      margin: 10px 0 0 28px;
    }
    .user-list-edit {
      margin-top: 10px;
      display: flex;
    }
  }
}

.notification-collapse-wrapper {
  .ant-collapse-header-text {
    display: flex;
    align-items: center;
    .anticon {
      margin-right: 7px;
    }
  }
}

.user-name-edit-row {
  > .row-div-container {
    justify-content: space-between;
  }
}

.user-list-item-header {
  margin-bottom: 10px;
}

.create-user-button {
  min-width: 140px;
}

.input-search-button {
  justify-content: flex-end;
}

.username-more-info-btn {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.user-more-info-btn {
  cursor: pointer;
  display: flex;
}

.user-notification-tag {
  display: inline-block;
  width: 61px;
  height: 24px;
  border-radius: 20px;
  text-align: center;
  padding: 1px;
  text-transform: capitalize;
  line-height: 21px;
  margin-right: 4px !important;
}

.user-notification-tag-smaller {
  display: inline-block;
  width: 45px;
  height: 24px;
  border-radius: 20px;
  text-align: center;
  padding: 1px;
  text-transform: capitalize;
  line-height: 21px;
  font-size: 11px;
  margin-right: 4px !important;
}

.user-select {
  > .ant-select-selector {
    height: 45px !important;
    align-items: center;
  }
}

.default-location-select {
  > .ant-select-selector {
    height: 45px !important;
    align-items: center;
  }
}

span.ant-radio {
  + * {
    padding-right: 7px !important;
    color: #77808d;
    font-weight: 500;
  }
}

.arrow-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  padding: 8px;
  background-color: #f6f7fb;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1190px) {
  .users-page-wrapper {
    padding: 0 20px 20px 20px;
  }
}

@media screen and (max-width: 767px) {
  .user-page-header {
    flex-direction: column;
    .inputWithSearch {
      padding-left: 0;
      .anticon {
        padding-left: 10px;
      }
    }
    .row-div-container {
      &:nth-child(2) {
        margin-top: 10px;
      }
    }
    .mat-select-wrapper-secondary {
      width: 100%;
    }
  }

  .user-page-header .mat-btn,
  .user-page-header .mat-select-wrapper,
  .user-page-header .inputWithSearch {
    width: 100%;
    max-width: 100%;
    min-width: auto !important;
  }
}

@media screen and (max-width: 479px) {
  .user-list-field-item {
    .row-div-container {
      width: 50%;
    }
  }
}
