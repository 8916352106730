.create-campaign-types {
    .create-campaign-types-block {
        .create-campaign-body {
            .two-block {
                background-color: var(--white);
                display: flex;
                padding: 45px 30px 45px 40px;
                box-shadow: 2px 20px 40px rgba(83, 85, 128, 5%);
                border-radius: 8px;
                align-items: center;
                justify-content: space-between;
                & + .two-block {
                    margin-top: 20px;
                }
                .icon-with-text {
                    display: flex;
                    align-items: center;
                    padding-right: 10px;
                    .text {
                      margin-left: 30px;
                      line-height: normal;
                      h2 {
                        color: var(--purple);
                        font-size: 18px;
                        font-weight: 400;
                        margin-bottom: 9px;
                      }
            
                      span {
                        color: var(--thridyTextColor);
                        font-size: 14px;
                        font-weight: 400;
                      }
                    }
                }
                .mat-btn {
                    max-width: 200px;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .create-campaign-types {
        .create-campaign-types-block {
            .create-campaign-body {
                .two-block {
                    flex-direction: column;
                    padding: 25px;
    
                    .icon-with-text {
                        padding-right: 0;
                        flex-direction: column;
    
                        .text {
                            margin-left: 0;
                            text-align: center;
                            margin: 15px 0;
                        }
                    }
                }
            }
        }
    }
}