.message_page_assign_user_list {
  display: flex;
  align-items: center;
  &--dropdown {
    max-width: 100px;
    width: auto;
    border: 1px solid var(--newGrey);
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    height: 32px;
    &-title {
      display: flex;
      align-items: center;
      font-size: 12px!important;
      color: var(--thridyTextColor);
      font-weight: 500;
      white-space: nowrap;
      .mat-user-profile-letters-wrapper + .mat-user-profile-letters-wrapper {
        margin-left: -5px;
      }
      .mat-user-profile-letters {
        margin-right: 0;
        border: 1px solid var(--white);
        line-height: normal;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        .active-icon {
          top: auto;
          bottom: 0;
          right: 0;
        }
      }
      .anticon-loading {
        display: flex;
        align-items: center;
        height: 26px;
      }
    }
    .anticon-down {
      font-size: 10px;
      color: var(--thridyTextColor);
      font-weight: 600;
      width: 24px;
      min-width: 24px;
      height: 24px;
      border: 1px solid var(--newGrey);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 3px;
    }
  }
}
.self_assign_button {
  &.mat-btn-primary {
    &:hover {
      background-color: var(--mainColor);
    }
  }
  &.mat-btn-white {
    border: 1px solid var(--newGrey);
    background-color: var(--white)!important;
    color: var(--black)!important;
    &:hover {
      background-color: var(--white);
    }
  }
}

.message_center_panel-drawer {
  .ant-drawer-body {
    .message_page_assign_user_list {
      &--dropdown {
        height: 32px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .message_page_center_panel_header {
    .self-assign-button.mat-btn.mat-btn-small {
      margin-top: 15px;
    }
  }
}