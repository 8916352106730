.my_default_table_title {
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  max-width: 200px;
  min-width: 200px;
}

@media screen and (max-width: 479px) {
  .my_default_table_title {
    font-size: 14px;
    max-width: 140px;
    min-width: 140px;
    line-height: 20px;
  }
}
