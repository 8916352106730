

.my-page-full {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin: 0 auto;
  height: 100%;
  overflow-y: auto;
  transition: padding 0.5s;
  transition-timing-function: ease-in-out;
  ::-webkit-scrollbar {
    display: none;
  }
}

.side-menu-closedV2 {
  transition: padding 0.5s;
  transition-timing-function: ease-in-out;
}
