.ai_chat_list_modal {
  .mat-modal {
    max-width: 1100px;
    padding: 0 16px;
    .ant-modal-body {
      padding: 0 !important;
    }
    .modal-content-wrapper {
      padding: 20px 15px !important;
      height: 600px;
      .ai_chat_list_item {
        height: 100%;
        #matador-ai-chat-iframe {
          height: 96%;
        }
      }
    }
    .ant-modal-header {
      margin-bottom: 0;
    }
  }
}

.ai_chat_list_item {
  width: 100%;
  &--left {
    width: 55%;
    display: grid;
    gap: 18px;
    padding: 0 2px 0 5px;
    border-right: 1px solid var(--borderLineColor);
    .mat-block-with-label {
      padding-top: 12px;
      margin-top: -3px;
      .mat-label {
        padding: 0 0 12px;
        &.black {
          label {
            font-weight: 400;
            line-height: 18px;
            font-size: 14px;
            color: var(--secondaryTextColor);
          }
        }
      }
      .mat-user-profile-letters {
        width: 40px;
        min-width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: #eeeffc;
        color: var(--mainColor);
        font-size: 16px !important;
        font-weight: 500;
      }
      .my_default_row + .my_default_row {
        margin-top: 14px;
      }
    }
    &-caller-info {
      span {
        color: var(--black);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      p {
        margin-top: 5px;
        color: var(--secondaryTextColor);
        font-size: 12px;
        font-weight: 400;
      }
    }
    &-consent-info {
      .my_default_row {
        .ant-flex {
          color: var(--black);
          font-weight: 500;
        }
        .anticon {
          align-items: center;
          border-radius: 50%;
          display: flex;
          height: 30px;
          justify-content: center;
          width: 30px;
        }
        &:nth-child(2) {
          .anticon {
            background-color: rgba(72, 175, 128, 0.2);
          }
        }
        &:nth-child(3) {
          .anticon {
            background-color: rgba(107, 117, 236, 0.2);
          }
        }
      }
    }
    &-vehicle-info {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      &-inner {
        padding-top: 7px;
        p {
          color: var(--black);
          line-height: 18px;
          font-weight: 600;
          &:first-child {
            font-size: 14px;
          }
          &:nth-child(2) {
            font-size: 12px;
            margin-top: 10px;
          }
          &:last-child {
            font-size: 12px;
            color: var(--thridyTextColor);
            margin-top: 6px;
          }
        }
      }
    }
  }
  &--right {
    width: 45%;
    padding: 0 5px 0 20px;
    &-list-info {
      margin: 20px 0;
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(3, 1fr);
      .my_default_row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
      }
    }
    &-copy-part {
      position: relative;
      .mat-label.black {
        label {
          font-weight: 400;
          line-height: 18px;
          font-size: 14px;
        }
      }
      .mat-textarea-wrapper {
        height: 200px;
        .mat-textarea {
          border-style: none;
          height: 100% !important;
          &:hover {
            border-style: none;
          }
        }
      }
      .copy_icon {
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .ai_chat_list_item {
    flex-direction: column;
    &--left {
      width: 100%;
      border-bottom: 1px solid var(--borderLineColor);
      margin-bottom: 16px;
      border-right: 0px solid transparent;
      padding: 0 0px 12px;
      order: 2;
    }
    &--right {
      width: 100%;
      padding: 0;
      order: 1;
    }
  }
}

@media screen and (max-width: 479px) {
  .ai_chat_list_item {
    &--right {
      &-list-info {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
