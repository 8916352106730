.welcome-greeting-preview-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  .preview-box {
    border-radius: 12px;
    background: linear-gradient(264deg, #282464 4.72%, #3836a1 92.32%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 30%;
    gap: 16px;

    .header-container {
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(12px);
      padding: 12px;
      text-align: center;
      position: relative;
      max-width: 136px;
      min-width: 100px;

      .close-button {
        position: absolute;
        top: 4px;
        right: 5px;
        cursor: pointer;
        padding: 4px;

        &:hover {
          opacity: 0.8;
        }
      }

      &--title {
        color: #fff;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        word-wrap: break-word;
      }

      &--text {
        color: #fff;
        font-family: Arial;
        font-size: 12px;
        font-weight: 700;
        word-wrap: break-word;

      }
    }

    .media-preview {
      width: 136px;
      height: 231px;
      background: lightgray 50% / cover no-repeat;
      border-radius: 8px;
      overflow: hidden;

      &.mobile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video-preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        max-height: 240px;
      }
    }

    .chat-button {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 6px 15px 6px 8px;
      position: relative;
      min-width: 209px;
      border-radius: 93px;

      &--icon {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 82px;
      }

      &--text {
        color: #423b81;
        font-family: Arial;
        font-size: 17px;
        font-weight: 700;
      }
      &__badge {
        position: absolute;
        top: -7px;
        right: -4px;
        background: #f36210;
        color: #fff;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 700;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
    }
  }
}
