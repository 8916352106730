.mat-rangepicker-wrapper {
  .ant-picker .ant-picker-input input {
    text-align: center;
  }

  .ant-picker-active-bar {
    background-color: var(--mainColor);
  }

  .ant-form-item-label {
    label:after {
      display: none;
    }
  }
}

.mat-date-picker-dropdown {
  .ant-picker-decade-btn {
    display: none;
  }

  .ant-picker-year-panel {
    .ant-picker-cell {
      color: rgba(0, 0, 0, 0.85);
    }

    .ant-picker-cell-disabled .ant-picker-cell-inner {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .ant-picker-body {
    .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
        .ant-picker-cell-disabled
      ):before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
        .ant-picker-cell-disabled
      ):before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
        .ant-picker-cell-disabled
      ):before {
      background-color: var(--mainColorLighter);
    }
    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end {
      .ant-picker-cell-inner {
        border-radius: 50% !important;
        background-color: var(--mainColor) !important;
      }
    }
    .ant-picker-cell {
      .ant-picker-cell-inner {
        min-width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        &:before {
          border-radius: 50%;
        }
      }
    }
  }
}

.mat-rangepicker-secondary-wrapper {
  .ant-form-item-label {
    padding: 0 0 6px !important;
    line-height: 18px !important;
    label {
      color: var(--textColor);
      font-weight: 600;
      text-transform: capitalize;
      font-size: 12px;
    }
  }

  .mat-range-picker {
    background-color: var(--newLightGrey);
    border: 1px solid var(--newGrey);
    border-radius: 8px;
  }
}

[data-theme='dark'] {
  .mat-date-picker-dropdown {
    .ant-picker-panel-container {
      background-color: var(--purple);
      border: 1px solid var(--darkPurple);
    }
    .ant-picker-header-view,
    .ant-picker-cell,
    .ant-picker-content th,
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-prev-btn,
    .ant-picker-header-super-next-btn,
    .ant-picker-header-next-btn {
      color: var(--white);
      &:hover {
        color: var(--white);
      }
    }
    .ant-picker-header-view > button:hover {
      color: var(--white);
    }
    .ant-picker-cell-disabled {
      color: var(--secondaryTextColor);
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
        .ant-picker-cell-disabled
      ):before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
        .ant-picker-cell-disabled
      ):before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
        .ant-picker-cell-disabled
      ):before {
      background-color: rgba(246, 247, 251, 0.12);
    }
    .ant-picker-body {
      .ant-picker-cell-range-start,
      .ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          background-color: var(--mainColor) !important;
        }
      }
    }
  }
  .mat-range-picker {
    background-color: var(--purple);
    border: 1px solid var(--darkPurple);
    .ant-picker-range-separator {
      color: var(--secondaryTextColor);
    }
    input {
      color: var(--secondaryTextColor);
      &::placeholder {
        color: var(--secondaryTextColor);
        font-weight: 400;
      }
    }
    .ant-picker-suffix {
      svg {
        fill: var(--secondaryTextColor);
      }
    }
    &.ant-picker-focused {
      background-color: var(--purple);
    }
  }
}

@media screen and (max-width: 590px) {
  .mat-date-picker-dropdown {
    .ant-picker-body {
      .ant-picker-cell {
        width: 22px;
        max-width: 22px;
        .ant-picker-cell-inner {
          min-width: 22px;
          height: 22px;
          line-height: 22px;
        }
      }
    }
  }
}
