.my_default_copy_textarea {
  position: relative;
  .my_default_textarea {
    color: var(--thridyTextColor)!important;
  }
  .my_default_button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  &.enable_margin {
    margin-bottom: 24px;
  }
}
