.my_default_checkbox {
  span + span {
    color: var(--thridyTextColor);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .ant-checkbox {
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      background-color: var(--newBackgroundGrey);
      border: 1.5px solid var(--newGrey);
      &::after {
        width: 9.17px;
        height: 13.33px;
        top: 45%;
      }
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--mainColor);
        border-color: var(--mainColor);
      }
    }
  }
  &:hover {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: var(--mainColor);
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: var(--mainColor) !important;
          border-color: var(--mainColor) !important;
        }
      }
    }
  }
}
