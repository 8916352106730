// .conversational-page-ai-body {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
//   gap: 20px;
//   overflow: visible;
//   .my-page {
//     padding: 0;
//     background-color: transparent;
//     gap: 20px;
//     flex-grow: 1;
//     max-width: 640px;
//     &:last-child {
//       flex-grow: 1;
//       max-width: 420px;
//     }
//     iframe {
//       border-style: none;
//     }
//   }
// }

// .conversation-ai-auto-reply {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   background-color: var(--newLightGrey);
//   padding: 12px 16px;
//   border-radius: 8px;
//   color: var(--thridyTextColor);
//   font-weight: 400;
//   font-size: 14px;
//   + .conversation-ai-auto-reply {
//     margin-top: 12px;
//   }
//   .title {
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 18px;
//     color: var(--mainColor);
//     min-width: 90px;
//   }
//   .mat-form-item {
//     width: auto;
//     margin: 0 12px 0;
//     &:last-child {
//       margin-right: 0;
//     }
//   }
//   .ant-divider {
//     height: 21px;
//     margin: 0 12px;
//     background-color: var(--secondaryTextColor);
//   }
//   .mat-input-affix-secondary-wrapper {
//     &:not(.ant-input-number) {
//       height: 32px;
//       width: 52px;
//       padding: 0;
//       background-color: var(--white);
//     }
//     input.ant-input-number-input {
//       text-align: center;
//       color: var(--black);
//     }
//   }
// }

// .conversation-ai-switch {
//   flex-direction: row-reverse;
//   justify-content: space-between;
//   padding: 12px 16px;
//   background-color: var(--newLightGrey);
//   border-radius: 8px;
//   .mat-form-item {
//     margin-right: 0 !important;
//   }
//   .text-with-icon {
//     font-size: 14px;
//     font-weight: 500;
//     color: var(--black);
//   }
// }

// .conversation-ai-two-switchs {
//   justify-content: space-between;
//   padding: 12px 16px;
//   background-color: var(--newLightGrey);
//   border-radius: 8px;
//   .switch-row {
//     margin-bottom: 0;
//     flex-direction: row-reverse;
//     width: auto;
//     .text-with-icon {
//       margin-right: 12px;
//     }
//     .mat-form-item {
//       margin-right: 0 !important;
//     }
//   }
// }

// .conversation-ai-two-switchs-wrapper {
//   width: auto;
// }

// .conversation-ai-communication-channel {
//   .conversation-ai-switch {
//     border-radius: 0;
//     background-color: transparent;
//     .text-with-icon {
//       svg {
//         margin-right: 0;
//       }
//     }
//     .anticon {
//       border: 1px solid var(--newGrey);
//       border-radius: 8px;
//       padding: 5px;
//       margin-right: 8px;
//     }
//   }
//   .conversation-ai-switch-parent {
//     &:nth-child(4) {
//       width: 50%;
//     }
//   }
// }

// @media screen and (max-width: 991px) {
//   .conversational-page-ai-body {
//     .my-page {
//       max-width: 100%;
//       &:last-child {
//         margin: 0 auto;
//       }
//     }
//   }
// }

// @media screen and (max-width: 767px) {
//   .conversation-ai-auto-reply {
//     display: grid;
//     grid-template-columns: auto auto;
//     grid-gap: 5px;
//     align-items: center;
//     .title {
//       grid-column: 1 / span 10;
//     }
//     .mat-form-item {
//       margin: 0;
//     }
//     .ant-divider {
//       display: none;
//     }
//   }
// }

// @media screen and (max-width: 550px) {
//   .conversation-ai-auto-reply {
//     .title {
//       grid-column: 1 / span 4;
//     }
//   }
// }

// @media screen and (max-width: 479px) {
//   .mat-form .mat-block-with-label .conversation-ai-switch,
//   .mat-form .mat-block-with-label .conversation-ai-two-switchs .switch-row {
//     flex-direction: row-reverse;
//   }

//   .mat-form .mat-block-with-label {
//     .conversation-ai-two-switchs {
//       flex-direction: column;
//       align-items: flex-start;
//     }
//   }

//   .conversation-ai-two-switchs-wrapper {
//     margin-top: 10px;
//     width: 100%;
//     &.col-flex-end {
//       justify-content: space-between;
//     }
//   }

//   .conversation-ai-two-switchs {
//     .switch-row {
//       text-align: right;
//       + .switch-row {
//         margin-left: 10px;
//       }
//     }
//   }

//   .conversation-ai-communication-channel {
//     .conversation-ai-switch-parent {
//       flex-direction: column !important;
//       width: 100% !important;
//       .conversation-ai-switch {
//         padding: 12px 0;
//       }
//     }
//   }
// }

.superhuman_ai_general_settings {
  background-color: var(--newLightGrey);
  color: var(--thridyTextColor);
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  padding: 12px 16px;
  .title {
    color: var(--mainColor);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    min-width: 90px;
  }
  .ant-divider {
    background-color: var(--secondaryTextColor);
    height: 21px;
    margin: 0px;
  }
  .my_default_form_item {
    width: auto;
    margin: 0px;
  }
  .my_default_input_number {
    height: 32px;
    width: 52px;
    padding: 0px;
    text-align: center;
  }
  .my_default_switch {
    padding: 0;
  }
}

.superhuman_communication_channels_wrapper {
  .my_default_switch {
    width: 50%;
    padding: 8px 0;
    .anticon {
      border: 1px solid var(--newGrey);
      border-radius: 8px;
      padding: 5px;
    }
  }
  .my_default_block_subtitle {
    margin-bottom: 8px !important;
  }
}

.superhuman_tag_users_switch {
  span {
    font-weight: 500;
    &:first-child {
      color: var(--black);
    }
  }
}

.supehuman_intent_config_wrapper {
  .my_default_row,
  .my_default_switch {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 1635px) {
  .superhuman_menu_opened {
    flex-direction: column;
  }
}

@media screen and (max-width: 625px) {
  .superhuman_ai_general_settings {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 5px;
    align-items: center;
    .title {
      grid-column: 1 / span 4;
    }
    .my_default_form_item {
      margin: 0;
    }
    .ant-divider {
      display: none;
    }
  }
}
