.create-campaign-types {
  .create-campaign-types-block {
    .create-campaign-body {
      .create-campaign-form-wrapper {
        .deliverability-score-content {
          margin-top: 15px;
          .deliverability-score {
            background: var(--white);
            border: 1px solid var(--newGrey);
            box-sizing: border-box;
            box-shadow: 4px 7px 30px rgba(44, 52, 94, 0.1);
            border-radius: 16px;
            padding: 10px 16px 19px 16px;
            max-width: 440px;
            width: 100%;
            margin: 0 auto;
            height: fit-content;
            .deliverability {
              border-bottom: 1px solid var(--newGrey);
              display: flex;
              align-items: center;
              svg {
                margin-bottom: 30px;
              }
              .deliverability-score-header {
                margin: 0 0 9px 8px;
              }
              .deliverability-score-title {
                margin-top: 0;
                margin-bottom: 0;
                color: var(--black);
                font-size: 16px;
                line-height: 32px;
                font-weight: 700;

                .deliverability-score-number {
                  color: var(--red);
                }
              }
            }
            .text {
              margin-top: 0;
              line-height: 18px;
              font-size: 13px;
              font-weight: 400;
              color: var(--thridyTextColor);
            }
            .recomendation-list {
              margin: 11px 16px;
              .recomendations {
                margin: 0;
              }
            }
            .deliverability-score-btn-wrapper {
              flex-direction: row-reverse;
              justify-content: start !important;
              margin-top: 10px !important;
              .mat-btn.mat-btn-large {
                font-weight: 400;
                margin-left: 0;
                height: 42px;
                min-width: 125px !important;
                max-width: 180px !important;
                border-radius: 71px;
              }
              .mat-btn.mat-btn-cancel {
                border: 1px solid var(--newGrey);
                color: var(--mainColor);
                margin-left: 7px;
              }
            }
          }
        }
        .content-mat-textarea-wrapper {
          .list-variables {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .add-variable {
              padding-left: 7px;
              padding-right: 7px;
            }
          }
          .label-mat-text-area {
            margin-top: 0;
            margin-bottom: 15px;
            line-height: 18px;
            font-size: 12px;
            color: var(--thridyTextColor);
          }
          .mat-form-item {
            margin-bottom: 0;
          }
          .mat-textarea {
            height: 120px !important;
            min-height: 120px !important;
            max-height: 120px !important;
            border-radius: 8px 8px 0 0;
            line-height: 20px;
            border: 1px solid transparent;
            border-style: none;
            padding: 8px 11px;
            border: 1px solid transparent !important;
            box-shadow: none !important;
            border-radius: 8px;
            &:focus {
              background-color: transparent;
            }
            .ant-input-data-count {
              position: absolute;
              bottom: -77px;
              right: 9px;
            }
          }
          .textarea-with-upload-message {
            border: 1px solid var(--borderLineColor);
            border-radius: 8px;
            &:has(.ant-upload-list-item) {
              .ant-form-item-explain-error {
                bottom: -109px;
              }
              .mat-textarea {
                .ant-input-data-count {
                  bottom: -111px;
                }
              }
            }
            .ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn
              .ant-input-clear-icon {
              top: 0px;
              right: 0px;
            }
            .ant-input {
              padding: 0;
              overflow-y: auto !important;
              height: 102px !important;
              min-height: auto !important;
              &:focus {
                background-color: transparent;
              }
            }
            .ant-form-item-explain-error {
              padding: 0 11px;
              position: absolute;
              bottom: -74px;
              width: 90%;
              line-height: 18px;
            }
            .ant-upload-list-picture-card {
              margin-left: 10px;
              margin-bottom: 8px;
            }
            .ant-upload {
              margin: 0;
              width: 135px !important;
              height: 32px !important;
              background-color: var(--mainColor);
              border-radius: 100px;
              color: var(--white);
              border-style: none;
              .ant-upload-list-picture-card-container {
                width: 70px;
                height: 70px;
                .ant-upload-list-item {
                  border-radius: 8px;
                  padding: 0;
                  border: 1px solid var(--borderLineColor);
                  overflow: hidden;
                }
              }
            }
            .ant-upload-list-picture-card {
              .ant-upload-list-item-container {
                width: 70px;
                height: 70px;
              }
              .ant-upload-list-item-actions {
                top: 10px;
                right: 9px;
                left: auto;
                transform: translate(50%, -50%);
              }
            }
          }
        }
        .build-your-content-tab {
          .ant-tabs-tab,
          .ant-tabs-nav-list {
            width: 100%;
          }
          &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
            display: none;
          }
          .ant-tabs-ink-bar {
            background: var(--mainColor);
          }
          .ant-tabs-tab,
          .ant-tabs-tab-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            text-shadow: none;
          }
          .ant-tabs-tab {
            color: var(--thridyTextColor);
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            .new {
              color: var(--mainColor);
              background-color: var(--mainColorLighter);
              font-weight: 600;
              font-size: 12px;
              padding: 1px 10px;
              border-radius: 100px;
              margin-right: 4px;
            }
            &.ant-tabs-tab-active {
              font-weight: bold;
              .ant-tabs-tab-btn {
                color: var(--black);
              }
            }
          }
        }
        .ai-broadcast-content {
          .broadcast-ai-generate-with-button {
            display: flex;
            align-items: center;
            border-radius: 8px;
            padding: 8px 11px;
            border: 1px solid var(--borderLineColor);
            margin-bottom: 10px;
            .mat-form-item {
              margin-bottom: 0;
              margin-right: 10px;
              line-height: normal;
            }
            .mat-textarea {
              padding: 0;
              border-radius: 0;
              min-height: 16px !important;
              max-height: 152px;
              line-height: normal;
              border: 0px solid transparent;
              border-radius: 0;
              line-height: normal;
              overflow-y: auto;
              &:focus {
                background-color: transparent;
              }
            }
            .ant-input-clear-icon {
              display: flex;
              align-items: center;
              top: 0;
              position: relative;
              right: 0;
            }
          }
          .list-variables {
            .add-variable {
              font-weight: bold;
              height: 30px;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: normal;
              margin-right: 0;
              &:hover {
                color: var(--mainColor);
              }
              &.ant-tag-checkable-checked {
                background-color: var(--mainColor);
                color: var(--white);
                &:hover {
                  color: var(--white) !important;
                }
              }
            }
          }
        }
        .powerfull-text-generated {
          text-align: center;
          color: var(--secondaryTextColor);
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 15px;
        }
        .ai-generated-tooltip {
          display: flex;
          align-items: center;
        }
        .ai-genrate-content-button {
          min-width: 90px;
        }
      }
    }
  }
}
