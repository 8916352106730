.aibot-organization-page {
  padding: 40px 0;
  padding-top: 24px;
  .organization-page-body {
    margin-top: 0;
  }
}

.aibot-full-page {
  flex-direction: row;
}

.aibot-left-side {
  border-right: 1px solid var(--borderLineColor);
  grid-area: left-side;
  .mat-form-radio-group {
    flex-wrap: wrap;
    justify-content: flex-start;
    .ant-radio-button-wrapper {
      width: auto;
      font-size: 12px;
      height: 28px;
      padding: 6px 12px;
      margin-right: 5px;
      margin-bottom: 5px;
      border-radius: 100px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .sequence_status_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .sequence_status_text {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: var(--black);
      text-transform: capitalize;
    }
    .switch-row {
      margin: 0px;
    }
  }
  .sequence_type_wrapper {
    margin-bottom: 24px;
    .sequence_type_value {
      font-size: 18px;
      font-weight: 500;
      color: var(--black);
    }
    .sequence_type_title {
      font-weight: 500;
      font-size: 12px;
      color: var(--thridyTextColor);
    }
  }
}

.auto-reply-items-list-wrapper {
  grid-area: list-replies;
  border-right: 1px solid var(--borderLineColor);
}

.aibot-reply-item-panel-wrapper {
  grid-area: reply-panel;
  padding-left: 20px;
  padding-right: 20px;
  .language-switch {
    .ant-radio-button-wrapper {
      border-radius: 5px;
    }
  }
  .single-video {
    background-color: var(--lightgrey);
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img-close {
      display: flex;
      position: absolute;
      top: 5px;
      right: 5px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .mat-form-radio-group {
    flex-wrap: wrap;
    justify-content: flex-start;
    .ant-radio-button-wrapper {
      width: 135px;
      font-size: 12px;
      height: 36px;
      padding: 6px 12px;
      margin-right: 5px;
      margin-bottom: 5px;
      border-radius: 100px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.create-drip-campaign {
  display: grid;
  gap: 0px;
  grid-template-columns: 270px auto;
  grid-template-areas:
    'left-side reply-panel'
    'left-side reply-panel'
    'list-replies reply-panel'
    '. reply-panel';
}

.organization-page-body {
  .aibot-left-side {
    padding: 0 10px;
    width: 100%;
  }
}

.ai-bot-list-item-wrapper {
  border: 1px solid var(--borderLineColor);
  border-radius: 9px;
  padding: 20px;
  min-height: 40px;
  margin-top: 16px;
  background-color: var(--white);
  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 16%);
  }
  .btn-block-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    cursor: pointer;
    margin-right: 0;
    &:hover {
      background-color: var(--mainColorLighter);
    }
  }
}

.ai-bot-list-item {
  width: 50%;
  p {
    color: var(--secondaryTextColor);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    color: var(--textColor);
  }
  .divider {
    margin: 12px 0px;
    max-width: 227px;
    min-width: 50px;
  }
  .details_wrapper {
    flex-direction: 'row';
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .details_title {
      color: var(--thridyTextColor);
      margin-right: 20px;
      width: 40px;
    }
    .details_value {
      color: var(--black);
      text-align: right;
      margin-right: 12px;
    }
  }
  .info-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    .info-item {
      margin-right: 5px;
      margin-top: 5px;
      background-color: rgba(59, 89, 152, 0.2);
      border-radius: 10px;
      padding: 2px 10px;
      font-size: 10px;
      color: #3b5998;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.activity-already-present-wrapper {
  display: flex;
  align-items: center;
}

.aibot-reply-item {
  display: flex;
  align-items: center;
  padding: 0px 5px 0 0;
  cursor: pointer;
  border-radius: 4px;
  padding-right: 10px;
  position: relative;
  min-height: 36px;
  justify-content: space-between;
  margin-top: 2px;
  max-width: 310px;
  .row-div-container {
    padding-top: 15px;
    padding-left: 17px;
  }
  .ai-bot-message {
    color: var(--secondaryTextColor);
    font-size: 12px;
    font-weight: 400;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 10px;
    line-height: 12px;
    padding-top: 5px;
  }
  &.active,
  &:hover {
    background-color: var(--lightgrey);
  }
}

.ai-bot-engagement-percent {
  z-index: 5;
  color: var(--white);
  position: relative;
  width: 29px;
  min-width: 29px;
  height: 19px;
  text-align: center;
  border-radius: 4px 4px 0 0;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12.36px;
}

.ai-bot-engagement-percent-line {
  position: absolute;
  bottom: 0px;
  height: 3px;
  left: 46px;
}

.ai-bot-engagement-percent-with-icon {
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .anticon {
    display: flex;
    margin-bottom: 15px;
  }
}

.ai-bot-time-unit {
  overflow: hidden;
  .time-unit {
    line-height: 15px;
  }
}

.ai-bot-engagement-percent.orange,
.ai-bot-engagement-percent-line.orange {
  background-color: #ea9957;
}

.ai-bot-engagement-percent.green,
.ai-bot-engagement-percent-line.green {
  background-color: var(--green);
}

.ai-bot-engagement-percent.red,
.ai-bot-engagement-percent-line.red {
  background-color: var(--lightRed);
}

.aibot-reply-item.active::before,
.aibot-reply-item:hover::before {
  content: '';
  position: absolute;
  left: 0;
  width: 3px;
  background-color: var(--mainColor);
  height: 100%;
  border-radius: 0 100px 100px 0;
}

.add-aibot-reply-item {
  &.mat-btn-primary {
    font-size: 14px;
    color: var(--mainColor);
    font-weight: 600;
    padding: 10px 1px 0 1px;
    z-index: 1;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 22px;
    span {
      display: flex;
      align-items: center;
    }
  }
  .plus-circle {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: var(--mainColor);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
  }
}

.add-aibot-reply-item.mat-btn-primary:not(:disabled):not(.mat-btn-disabled):hover {
  background-color: transparent;
  color: var(--mainColor);
}

.auto-reply-items-list {
  padding-right: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 490px);
}

.aibot-auto-message-descriptions {
  display: flex;
  align-items: center;
  background-color: var(--mainColorLighter);
  border-radius: 16px;
  padding: 10px;
  margin-bottom: 10px;
  .ant-form-item-control-input {
    height: 100%;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    height: 100%;
  }
  .mat-input-affix-wrapper:not(.ant-input-number) {
    width: 42px;
    height: auto;
    align-items: center;
    font-size: 12px;
    padding: 2px;
    border-color: var(--white);
    input {
      height: 100%;
      text-align: center;
    }
    &.ant-input-number-focused {
      border-color: var(--mainColor);
    }
    &:focus {
      box-shadow: none;
      border-color: var(--grey);
    }
  }
  .ant-form-item-with-help {
    .ant-form-item-explain {
      display: none;
    }
  }
  .mat-select.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      padding: 0 10px;
      height: 36px;
      min-height: 36px;
      border-color: var(--white);
      .ant-select-selection-item {
        line-height: 34px;
      }
    }
  }
  .mat-select {
    .ant-select-arrow {
      right: 8px;
      top: 18px;
    }
  }
  .ant-row {
    &.ant-form-item {
      &.mat-form-item {
        margin-bottom: 0;
        width: auto;
        padding: 0 5px;
      }
    }
  }
}

.aibot-auto-message-descriptions-text {
  &:first-child {
    font-weight: 400;
    font-size: 12px;
  }
  &:last-child {
    font-weight: 400;
    font-size: 12px;
    padding-left: 2px;
  }
}

.ant-form-item-has-error {
  .ant-input-number,
  .ant-picker {
    border-color: #ff4d4f;
  }
}

.language-switch {
  margin-left: auto;
  margin-right: 10px;
}

.aibot-auto-message-follow-up-dropdown {
  .ant-select-item {
    padding: 5px 10px;
  }
}

.ai-bot-message-description-first-part {
  display: flex;
  align-items: center;
  .mat-form-item {
    margin-bottom: 0;
    padding: 0 5px;
    width: auto;
  }
  .ant-form-item-row {
    height: 37px;
  }
}

.video-in-ai-bot-tab {
  display: flex;
  align-items: center;
}

.video-in-ai-bot-tab .video-small-icon,
.delete-aibot-reply-item {
  display: flex;
}

.video-small-icon {
  margin-right: 10px;
  margin-top: 1px;
}

.org-ai-bot-user-action .footer-connected-account {
  line-height: 8px;
  span {
    font-size: 10px;
    font-weight: 700;
    &:first-child {
      color: var(--secondaryTextColor);
    }
    &:last-child {
      color: var(--textColor);
      margin-left: 2px;
    }
  }
}

@media screen and (max-width: 1190px) {
  .aibot-reply-item-panel-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .my-page {
    max-width: 100%;
    border-right: 0px solid transparent;
    padding-right: 0;
  }

  .aibot-reply-item-panel-wrapper {
    border-top: 1px solid var(--borderLineColor);
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .create-drip-campaign {
    grid-template-columns: 50% 50%;
    grid-template-areas:
      'left-side list-replies'
      '. reply-panel';
  }

  .aibot-left-side {
    border-right: none;
  }

  .auto-reply-items-list-wrapper {
    border-right: none;
    border-left: 1px solid var(--borderLineColor);
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .ai-bot-list-item-wrapper {
    .row-flex-start {
      flex-direction: column;
    }
  }

  .ai-bot-list-item {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .ai-bot-engagement-percent {
    margin-right: 10px !important;
  }

  .aibot-auto-message-descriptions {
    .mat-input-affix-wrapper {
      width: 50px;
      padding: 10px 5px;
    }
    .mat-select {
      .ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
          padding: 0 8px;
        }
      }
      .ant-select-arrow {
        right: 8px;
      }
    }
  }

  .create-drip-campaign {
    display: flex;
    flex-direction: column;
  }

  .aibot-full-page {
    flex-direction: column;
  }

  .aibot-auto-message-descriptions {
    flex-direction: column;
  }

  .language-switch {
    margin-right: auto;
    margin-top: 20px;
  }
}
