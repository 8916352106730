.my_default_phone_input {
  border-radius: 8px !important;
  border: 1px solid var(--newGrey) !important;
  font-size: 16px;
  color: var(--black) !important;
  font-weight: 500;
  padding-left: 58px;
  height: 44px !important;
  &.grey {
    background-color: var(--newBackgroundGrey) !important;
  }
  &:focus {
    box-shadow: none !important;
    border: 1px solid var(--mainColor) !important;
  }
  &::placeholder {
    font-size: 16px;
    color: var(--thridyTextColor);
    font-weight: 400;
    letter-spacing: 0;
  }
}

.react-tel-input {
  &:has(.my_default_phone_input) {
    .flag-dropdown {
      border-radius: 8px 0 0 8px;
      background-color: var(--white);
      border-right: 1px solid var(--newGrey);
      .selected-flag {
        display: flex;
        padding: 0;
        justify-content: center;
        width: 48px;
      }
    }
  }
}
