.dashboard_marketing_page {
    &--table {
        margin-top: 20px;
        background-color: var(--purple);
        .ant-table-column-title {
            flex: auto;
            white-space: pre-line;
        }
        .gradient_text {
            background: linear-gradient(131deg, #18F5F5 2.35%, #F5B7D2 22.49%, #E744F6 40.15%, #00DBF9 85.42%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .ant-table-container {
            width: calc(100% - 48px);
            margin: 0 auto;
        }
        .ant-table-header {
            background-color: transparent!important;
            border-radius: 0!important;
            padding-top: 24px;
        }
        .ant-table-container {
            table {
                >thead>tr {
                    &:first-child >*:first-child {
                        border-top-left-radius: 100px;
                        border-bottom-left-radius: 100px;
                    }
                    &:last-child >*:last-child {
                        border-top-right-radius: 100px;
                        border-bottom-right-radius: 100px;
                    }
                }
            }
        }
        .ant-table-thead {
            > tr {
                > th {
                    border-bottom: none!important;
                }
                .ant-table-cell {
                    position: relative;
                    background-color: #322D4F!important;
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 25%;
                        height: 50%;
                        width: 1px;
                        background-color: var(--secondaryTextColor);
                    }
                    &:last-child::after {
                        display: none;
                    }
                }
            }
        }
        .ant-table-column-sorters {
            justify-content: center;
        }
        .ant-table-tbody {
            .ant-table-cell {
                border-bottom: none!important;
                vertical-align: top;
                &:first-child {
                    padding-left: 0;
                    vertical-align: middle;
                }
            }
            .ant-table-row {
                &:last-child {
                    .ant-table-cell {
                        padding-bottom: 0;
                    }
                }
            }  
        }
        .ant-table-sticky-scroll {
            position: fixed;
            bottom: 1px!important;
        }
    }
}

@media screen and (max-width: 767px) {
    .dashboard_marketing_page {
        &--table {
            border-radius: 16px;
            .ant-table-container {
                width: 100%;
            }
            .ant-table-header {
                padding-top: 0;
            }
            .ant-table-container {
                table {
                    >thead>tr {
                        &:first-child >*:first-child {
                            border-top-left-radius: 16px;
                            border-bottom-left-radius: 0;
                        }
                        &:last-child >*:last-child {
                            border-top-right-radius: 16px;
                            border-bottom-right-radius: 0;
                        }
                    }
                }
            }
            .ant-table-tbody {
                .ant-table-cell {
                    &:first-child {
                        padding-left: 25px;
                    }
                }
            }
        }
    }
}