

.row-div-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.row-flex-start {
    align-items: flex-start;
  }

  &.col-flex-start {
    justify-content: flex-start;
  }

  &.row-flex-end {
    align-items: flex-end;
  }

  &.col-flex-end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }
}
