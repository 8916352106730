.my_default_tag_block {
  padding: 3px 8px;
  border-radius: 100px;
  margin-left: 8px;
  font-weight: 600;
  font-size: 10px;
  &.disableMargin {
    margin-left: 0;
  }
}
