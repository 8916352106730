.mat-confirm-popup {
  .ant-popover-message-icon {
    display: flex;
    align-items: flex-start;
    margin-top: 2px;
    color: var(--orange);
    font-size: 22px;
  }
  .ant-popover-message {
    border-bottom: 1px solid var(--borderLineColor);
    margin-bottom: 10px;
    padding-top: 0;
  }
  .ant-popover-inner {
    border-radius: 14px;
  }
  .ant-popover-message-title {
    font-weight: 600;
  }
  .ant-popover-inner-content {
    padding: 10px;
    width: 300px;
  }
}
