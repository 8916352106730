.my_default_purple_modal {
  .warning {
    color: var(--red);
    text-align: right;
    margin-bottom: 8px;
  }

  .my_default_modal_terms_and_conditions_textarea {
    border: 1px solid var(--secondaryTextColor);
    background-color: var(--white);
    border-radius: 8px;
    padding: 16px;
    height: 210px;
    overflow-y: auto;
    position: relative;
    width: 100%;
    word-break: break-all;
  }
  .ant-checkbox-wrapper-disabled {
    opacity: 0.5;
    .ant-checkbox {
      .ant-checkbox-inner {
        background-color: transparent;
        border: 1.5px solid var(--secondaryTextColor);
      }
    }
  }

  .scroll_down {
    border-radius: 100px;
    background-color: var(--white);
    width: fit-content;
    margin-top: 2px;
    border: 1px solid var(--mainColor);
    padding: 4px 4px 4px 12px;
    color: var(--mainColor);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    margin-left: auto;
  }
}
