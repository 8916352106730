.message_right_panel {
  &--top-side {
      .block_right_and_left_sides {
          margin-top: 0;
          border-radius: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .after_hour_sequence_ai_bot {
              display: flex;
              align-items: center;
              white-space: nowrap;
              max-width: 132px;
              background-color: var(--mainColorLighter);
              border-radius: 100px;
              padding: 2px 6px 2px 4px;
              .anticon {
                min-width: 12px;
                fill: var(--mainColor);
              }
              .title {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
                margin-left: 5px;
                color: var(--purple);
                font-size: 12px;
                font-weight: 500;
              }
          }
          button[aria-checked='false'] {
              opacity: 1;
              background-color: var(--thridyTextColor);
          }
          &--left-side {
              display: flex;
              align-items: center;
              width: 100%;

              &-label {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
              }

              .is_engaged {
                color: var(--green);
                font-size: 12px;
                margin-left: 6px;
                position: relative;
                border-radius: 100px;
                padding: 1px 8px;
                background-color: rgba(0, 203, 133, 0.1);
              }

              &-icon {
                width: 30px;
                height: 30px;
                overflow: hidden;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--mainColorLighter);
                margin-right: 10px;

                &.green {
                  background-color: rgba(72, 175, 128, 0.1);
                }

                &.orange {
                  background-color: rgba(234, 153, 87, 0.1);
                }

                &.blue {
                  background-color: #ebf3ff;
                }
              }

              span {
                &.active_bot {
                  color: var(--mainColor);
                }

                &.not_active {
                  color: var(--black);
                }
              }
          }
          & + .block_right_and_left_sides {
              border-top: none;
              padding-top: 0;
              margin-top: 12px;
          }
          &.ai_bot_superhuman_switch {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid var(--borderLineColor);
            .snoozed_item {
              background-color: #FFF8DE;
              color: var(--yellow);
              font-size: 12px;
              font-weight: 500;
              border-radius: 100px;
              padding: 1px 8px;
              margin-left: 6px;
              cursor: pointer;
            }
          }
      }
  }
}

.snoozed_item_tip {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  .default-class-btn {
    background-color: transparent;
    color: var(--red);
    padding: 2px 10px;
    font-size: 12px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
    border-style: none;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}