.dashboard_page_main_page {
    padding: 10px 15px 15px 15px;
    overflow-y: auto;
    height: 100%;
    &--tabs {
        max-width: 1300px;
        margin: 0 auto;
        .ant-tabs-tab{
            padding: 12px 0 20px 0;
            &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: var(--white);
                }
            }
            .ant-tabs-tab-btn {
                color: var(--secondaryTextColor);
                font-size: 16px;
                font-weight: 400;
            }
            +.ant-tabs-tab {
                margin: 0 0 0 24px;
            }
        }
        .ant-tabs-ink-bar {
            height: 3px;
            border-radius: 100px;
            background: var(--mainColor);
        }
        &.ant-tabs-top >.ant-tabs-nav {
            margin: 0 0 32px 0;
            &:before {
                border-radius: 100px;
                border-color: rgba(229, 233, 242, 0.12);
            }
            .ant-tabs-ink-bar {
                height: 3px;
            }
        }
        .ant-tabs-nav-operations {
            .ant-tabs-nav-more {
                color: var(--white);
                font-size: 22px;
                padding: 8px 12px;
            }
        }
    }
    &--header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 10px;
        &--selects {
            display: flex;
            align-items: center;
            gap: 10px;
            .dashboard-filter-select {
                width: 100%;
            }
        }
        .mat-rangepicker-wrapper {
          margin: 10px 0;
        }
        .mat-date-picker {
          height: 36px;
          border-radius: 10px;
        }
    }
}

@media screen and (max-width: 479px) {
    .dashboard_page_main_page {
        &--header {
            padding-bottom: 10px;
            .mat-rangepicker-wrapper {
                margin-bottom: 0;
            }
        }
        &--tabs {
            .ant-tabs-tab {
                padding: 12px 0;
                + .ant-tabs-tab {
                    margin:  0 0 0 15px;
                }
            }
            &.ant-tabs-top >.ant-tabs-nav {
                margin: 0 0 15px 0;
            }
            .ant-tabs-nav-operations {
                .ant-tabs-nav-more {
                    padding: 8px 0 8px 12px;
                }
            }
        }
    }
}