.customer-risk-wrapper {
    background-color: #ffdcdc;
    border-radius: 100px;
    padding: 8px 16px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    color: #e05d4c;
    line-height: normal;
    .anticon {
      margin-right: 4px;
    }
}