.my_default_empty {
  margin: 50px 0px;
  display: flex;
  justify-content: center;
  .default_block_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    max-width: 456px;
    width: 100%;
    h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      max-width: 300px;
      margin-bottom: 0;
      line-height: 24px;
    }
  }
}
