

.mat-toggle {
  border-radius: 100px;
  background-color: var(--white);
  font-size: 12px;
  margin-top: 10px;
  padding: 6px 9px;
  cursor: pointer;
  margin-right: 7px;
  color: var(--purple);
  font-weight: 600;
  border: 1px solid var(--purple);

  .toggle-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.active {
    background-color: var(--purple);
    color: var(--white);
    font-weight: 600;
  }
}

.mat-toggle-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  .anticon {
    margin-right: 7px;
  }
}

.mat-toggle-primary {
  border: 1px solid var(--mainColor);
  color: var(--mainColor);

  &.active {
    background-color: var(--mainColor);
  }
}

.mat-toggle-white {
  margin: 0px 10px;
  cursor: pointer;
  color: var(--thridyTextColor);
  font-weight: 400;
  font-size: 16px;

  &.active {
    border-bottom: 3px solid var(--mainColor);
    color: var(--black);
    font-weight: bold;
    font-size: 15px;
  }
}
