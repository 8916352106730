.my_default_purple_modal {
  p {
    text-align: center;
  }
  .ant-modal-header {
    background-color: transparent;
    border-bottom: 1px solid var(--white) !important;
  }
  .ant-modal-content {
    background: linear-gradient(116deg, #fdfeff -19.1%, #b8bfff 126.87%);
    .my_default_page_layout {
      height: fit-content;
      background-color: transparent;
      padding: 0;
    }
  }
  .ant-modal-footer {
    justify-content: center;
    .my_default_button_white {
      background-color: transparent;
    }
  }
}
