

// Dropdown menu styles
.mat-drop-menu {
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(91, 102, 234, 0.16);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 210px;
  background-clip: border-box;
  background-color: var(--white);

  &::before {
    position: relative;
  }

  .ant-dropdown-menu {
    border-radius: 5px;
    overflow: hidden;

    .ant-dropdown-menu-item {
      padding: 0;
    }
  }

  .drop-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    transition: all 0.3s ease-in-out;
    padding: 5px;

    .drop-item-icon-with-text {
      font-size: 14px;
      align-items: center;
      display: flex;

      svg {
        margin-right: 11px;
      }
    }

    .check-wrapper {
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--mainColor);
      border-radius: 50%;
      margin-left: 10px;

      svg {
        width: 6px;
      }
    }

    .label-item-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .ant-dropdown-menu-item:active,
  .drop-item:hover {
    background-color: var(--mainColorLighter);
    color: var(--mainColor);
  }

  .dropmenu-item {
    .check-wrapper path {
      fill: var(--white);
    }
  }
}

.mat-dropdown-trigger-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  .drop-menu-trigger-tab {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    cursor: pointer;

    .drop-menu-label-value {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;

      .drop-menu-label {
        color: var(--secondaryTextColor);
        font-weight: 700;
        font-size: 12px;
      }

      .drop-menu-value {
        font-size: 14px;
        font-weight: 500;
      }
    }

    svg {
      min-width: 30px;
    }
  }
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:hover,
:where(.css-dev-only-do-not-override-2q8sxy).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content:hover {
  background-color: var(--mainColorLighter);
  color: var(--mainColor);
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-disabled:hover {
  background-color: var(--white);
}
