.my_default_page_layout {
  .ant-tabs-nav-more {
    display: none;
  }
  .ant-tabs-tab {
    min-width: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .restriction-item {
    &:first-child {
      .organization-switch-with-icon-and-text {
        padding-top: 0;
      }
    }
    &:last-child {
      .organization-switch-with-icon-and-text {
        border-bottom: none !important;
        padding-bottom: 0px !important;
      }
    }
    .organization-switch-with-icon-and-text {
      padding: 12px 0;
      margin-bottom: 0px;
      border-top: none;
    }
    &:has(.row-div-container) {
      padding-bottom: 12px;
      border-bottom: 1px solid var(--borderLineColor);
      .organization-switch-with-icon-and-text {
        border-bottom: none;
      }
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: var(--thridyTextColor);
      padding-bottom: 10px;
    }
    .row-div-container {
      flex-wrap: wrap;
      // > * {
      //   margin-top: 6px;
      // }
    }
  }
  //   .ant-tabs-ink-bar-animated {
  //     background-color: var(--mainColor);
  //   }
  //   .ant-tabs-nav {
  //     &::before {
  //       border-bottom: 0px;
  //     }
  //   }
  //   .ant-tabs-tab {
  //     &.ant-tabs-tab-active {
  //       .ant-tabs-tab-btn {
  //         color: var(--black);
  //       }
  //     }
  //     &:hover {
  //       color: var(--black);
  //     }
  //     color: var(--thridyTextColor);
  //   }
  // }
}

@media screen and (max-width: 500px) {
  .my_default_page_layout {
    .ant-tabs-tab {
      min-width: 20px;
    }
  }
}
