.message_type--carousel {
    margin-top: 10px;
    display: flex!important;
    align-items: center;
    border-bottom: 1px solid var(--mainColorLighter);
    .slick-list {
        .slick-track {
            display: flex;
            align-items: center;
        }
    }
    .slick-arrow {
        display: flex!important;
        align-items: center;
        justify-content: center;
        opacity: 1;
        box-shadow: none;
        height: 100%;
        width: 33px;
        border-radius: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.15%, #FFF 26.45%, #FFF 100%);
        top: 4px;
        position: relative;
        .anticon {
            width: 18px;
            padding: 0px 15px;
            border-radius: 100px;
            opacity: 1;
            display: flex!important;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--borderLineColor);
            background-color: var(--white);
            position: relative;
        }
        &::before, &:after {
            display: none;
        }
        &.slick-next {
            right: 0px;
            .anticon {
                right: -5px;
            }
        }
        &.slick-prev {
            width: 22px;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.15%, #FFF 26.45%, #FFF 100%);
            left: 0px;
        }
    }
}

.message_type-item {
    background-color: transparent;
    padding: 0 14px 8px 14px;
    margin: 0;
    border-radius: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    display: flex!important;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: var(--thridyTextColor);
    border-bottom: 3px solid transparent;
    &:not(.ant-tag-checkable-checked) {
        &:hover {
            background-color: transparent;
            border-bottom: 3px solid var(--mainColor);
            color: var(--black);
        }
    }
    &.ant-tag-checkable-checked {
        border-bottom: 3px solid var(--mainColor);
        color: var(--black);
        .anticon {
            svg {
                path {
                    stroke: var(--black);
                }
            }
        }
        &:hover {
            background-color: transparent;
        }
    }
    .anticon {
        margin-right: 4px;
    }
    &--count {
        display: inline-flex;
        height: 18px;
        padding: 5px 8px;
        align-items: center;
        background-color: var(--red);
        color: var(--white);
        border-radius: 100px;
        margin-left: 4px;
    }
}