.my_default_inactive_block_wrapper {
  background-color: var(--lightRedSecondary);
  padding: 4px 12px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  flex-direction: row;
  gap: 5px;
  color: var(--red);
  font-size: 12px;
  font-weight: 600;
  &.margin {
    margin-left: 12px;
  }
}
