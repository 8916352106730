.mat-sider-menu {
  background-color: transparent;
  border-right: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 21px 12px 24px 12px;
  &.ant-menu-inline-collapsed {
    width: 100%;
    > .ant-menu-item .ant-badge + span {
      display: inline-block;
      max-width: 0;
      opacity: 0;
    }
  }
  &.mat-sider-menu-support-agent {
    .items-separation {
      display: none;
      cursor: default;
    }
  }
  .ant-menu-item {
    line-height: normal;
    display: flex;
    align-items: center;
    overflow: visible;
    padding: 8px 12px;
    border-radius: 6px;
    margin: 0;
    height: 40px;
    width: 100%;
    + .ant-menu-item {
      margin-top: 12px;
    }
    &.items-separation {
      display: flex;
      flex: 1;
      cursor: default;
      padding: 8px 12px;
      margin-top: 0;
    }
    &.line-between-item {
      background-color: var(--white) !important;
      opacity: 0.1;
      border-radius: 12px;
      height: 1px;
      padding: 0;
      margin: 18px 0 6px;
      cursor: default;
    }
    span {
      color: var(--white);
      font-size: 16px;
      font-weight: 500;
    }
    .anticon {
      min-width: 24px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 13px;
      margin-right: 0;
      + span {
        margin-left: 12px;
        width: 100%;
      }
    }
    .title-with-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .count-number {
        display: flex;
        height: 20px;
        padding: 5px 10px;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          130deg,
          #828cff 7.44%,
          #4337c1 23.59%,
          #3d0b7d 57.45%
        );
        border-radius: 100px;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.2px;
      }
    }
  }
}

.ant-layout-sider {
  background-color: var(--purple);
  height: 100vh;
  .mat-sider-menu {
    .ant-menu-item:not(.ant-menu-item-selected) {
      &:active {
        background-color: transparent;
      }
      &:hover {
        background-color: var(--mainColor);
      }
    }
  }
}

.mat-sider-menu .ant-menu-item.ant-menu-item-selected,
.mat-sider-menu .ant-menu-item:hover {
  background-color: var(--mainColor);
}

.mat_main_layout {
  .ant-layout-sider {
    background-color: var(--darkLayoutColor);
  }
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  .sider-logo {
    padding: 24px 16px 20px;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.ant-layout-sider-collapsed {
  .mat-sider-menu {
    padding: 21px 10px 24px;
    .ant-menu-item {
      width: 100%;
    }
  }
  .ant-layout-sider-children {
    .sider-logo {
      padding: 24px 14px 20px 15px;
    }
  }
}

.mat-sider-dropdown {
  display: flex;
  align-items: center;
  padding: 20px 10px 50px 10px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  white-space: nowrap;
  .account-settings-drop-icon {
    min-width: 42px;
    max-width: 42px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    margin-right: 10px;
  }
  &.mat-dropdown-link.active {
    color: var(--white);
    .account-settings-drop-icon {
      svg {
        color: var(--white);
        path {
          opacity: 1;
        }
      }
    }
  }
}

.mat-drawer-left-menu-mobile {
  z-index: 10000;
  .ant-drawer-body {
    background-color: var(--darkLayoutColor);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    .sider-logo {
      padding: 23px 17px;
      display: flex;
      padding-bottom: 0;
    }
  }
}

@media screen and (max-height: 881px) {
  .windows {
    .mat-sider-menu {
      &.ant-menu-inline-collapsed {
        .ant-menu-item {
          padding: 8px 7px;
          .anticon {
            min-width: fit-content;
            svg {
              width: 100%;
            }
          }
          &.line-between-item {
            padding: 0;
          }
        }
      }
    }
  }
  .ant-layout-sider-collapsed {
    &.windows {
      .ant-layout-sider-children {
        .sider-logo {
          padding: 24px 10px 20px 10px;
        }
      }
    }
  }
}
