.my_default_dropdown_custom_render {
  background-color: transparent;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  font-size: 14px;
  z-index: 16000;
  position: relative;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
