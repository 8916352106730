

.mat-btn {
  font-size: 14px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-shadow: none;
  border-style: none;
  width: fit-content;
  height: 40px;
  border-radius: 100px;
  box-shadow: none;
  padding: 0 15px;
  line-height: normal;
  display: flex;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-btn-icon {
    overflow: visible;
  }
  &.ant-btn-sm,
  &.ant-btn-lg {
    border-radius: 100px;
    padding: 0 15px;
  }
  .anticon {
    display: flex;
  }
  .ant-btn-loading-icon {
    display: flex;
  }
  &.mat-btn-primary {
    background-color: var(--mainColor);
    &:hover {
      background-color: var(--mainColor);
    }
    &:disabled {
      background-color: var(--mainColorLighter);
      color: var(--white);
    }
    .anticon-loading {
      color: var(--white);
    }
  }
  &.mat-btn-radius {
    background-color: var(--mainColor);
    border-radius: 8px;
    color: var(--white);
    &:hover {
      background-color: var(--mainColor);
    }
    &:disabled {
      background-color: var(--mainColorLighter);
      color: var(--white);
    }
    .anticon-loading {
      color: var(--white);
    }
  }
  &.mat-btn-white {
    background-color: var(--white);
    font-size: 12px;
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
    &:disabled {
      opacity: 0.5;
      &:hover {
        background-color: var(--white);
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
      }
    }
    &:hover {
      background-color: var(--white);
    }
  }
  &.mat-btn-cancel {
    background-color: var(--white);
    font-size: 12px;
    color: var(--secondaryTextColor);
    &:hover {
      background-color: var(--lightgrey);
    }
  }
  &.mat-btn-danger {
    background: var(--red);
    border-color: transparent;
    color: var(--white);
  }
  &.mat-btn-gmail {
    background-color: #dc4a38;
    color: var(--white);
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        background-color: #dc4a38;
        color: var(--white);
      }
    }
  }
  &.mat-btn-red {
    background-color: rgba(241, 43, 44, 0.15);
    color: var(--red);
    &:disabled {
      opacity: 0.5;
      &:hover {
        background-color: rgba(241, 43, 44, 0.15);
        color: var(--red);
      }
    }
  }
  &.mat-btn-microsoft {
    background-color: #0072c6;
    color: var(--white);
    &:disabled {
      opacity: 0.5;
      &:hover {
        background-color: #0072c6;
        color: var(--white);
      }
    }
  }
  &.mat-btn-facebook {
    background-color: #4c69ba;
    color: var(--white);
    min-width: 130px;
  }
  &.mat-btn-large {
    height: 54px;
    font-size: 16px;
  }
  &.mat-btn-small {
    height: 32px;
  }
  + .mat-btn {
    margin-left: 20px;
  }
}

.mat-btn-primary:not(:disabled):not(.mat-btn-disabled):hover {
  background-color: var(--mainColor);
}

.mat-btn.ant-btn-sm.mat-btn-radius,
.mat-btn.ant-btn-lg.mat-btn-radius {
  border-radius: 8px;
}

[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.img-crop-modal {
  .ant-btn {
    font-size: 14px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-shadow: none;
    border-style: none;
    width: fit-content;
    height: 40px;
    border-radius: 100px !important;
    box-shadow: none;
    padding: 0 15px;
    line-height: normal;
    display: flex;
    background-color: var(--white);
    font-size: 12px;
    color: var(--secondaryTextColor);
    &:hover {
      background-color: var(--lightgrey);
    }
    &.ant-btn-primary {
      background-color: var(--mainColor);
      color: var(--white);
      &:hover {
        background-color: var(--mainColor);
      }
    }
  }
}
