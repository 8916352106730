.sms-email-dropdown-wrapper {
  width: 200px !important;

  .ant-select-item {
    min-width: auto;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;

      .anticon {
        margin-right: 5px;
      }

      .not-show {
        color: var(--thridyTextColor);
      }

      .default {
        margin-left: 10px;
        background-color: var(--greenLight);
        font-size: 10px;
        color: var(--green);
        border-radius: 100px;
        padding: 0 10px;
      }
    }
    &.ant-select-item-option-selected {
      .ant-select-item-option-content {
        .not-show {
          color: var(--mainColor);
        }
      }
    }
  }
}

.message_center_panel {
  .message-center-type-wrapper {
    .message-center-text-input-wrapper {
      .message-center-button-wrapper {
        .mat-select-wrapper {
          .mat-select-sub-wrapper {
            // margin-right: 5px;
            border: 1px solid transparent;
            height: auto;

            .mat-select {
              padding: 0;
              // border-right: 1px solid var(--borderLineColor);

              .ant-select-selector {
                border: 1px solid transparent;

                .ant-select-selection-item {
                  display: flex;
                  align-items: center;
                  line-height: normal;

                  .not-show {
                    display: none;
                  }

                  .default {
                    display: none;
                  }
                }
              }

              .ant-select-arrow {
                color: var(--secondaryTextColor);
                right: 5px;
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.message_type_select_wrapper {
  border-right: 1px solid var(--borderLineColor);
  .mat-select {
    &.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        padding: 0px;
      }
    }
  }
  &.desktop {
    border: 1px solid var(--borderLineColor);
    border-radius: 100px;
    .mat-select-wrapper {
      .mat-select-sub-wrapper {
        border-radius: 100px;
      }
    }
    .mat-select {
      &.ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
          padding: 0 4px 0px 8px;
          border-radius: 100px;
        }
      }
      border-right: 1px solid var(--borderLineColor);
    }
    .tag_select_icon {
      padding: 0px 8px 0px 4px;
    }
  }
}

@media screen and (max-width: 479px) {
  .message_center_panel-drawer {
    .message_center_panel {
      .message-center-type-wrapper {
        .message-center-text-input-wrapper {
          .message-center-button-wrapper {
            .mat-select-wrapper {
              .mat-select-sub-wrapper {
                .mat-select {
                  .ant-select-selector {
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
