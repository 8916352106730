.my_default_inside_page_tabs {
  .ant-tabs-nav {
    margin-bottom: 20px;
    &::before {
      border-bottom: none;
    }
    .ant-tabs-nav-wrap {
      white-space: normal !important;
      .ant-tabs-ink-bar-animated {
        bottom: 5px;
        height: 3px;
        background-color: var(--mainColor);
      }

      .ant-tabs-tab {
        margin: 0 0 0 16px;
        .ant-tabs-tab-btn {
          color: var(--thridyTextColor);
        }
        span {
          color: var(--thridyTextColor);
        }
        &:first-child {
          margin: 0 0 0 0;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: var(--black);
        }
        span {
          color: var(--black) !important;
        }
      }
    }
  }
  &.is_first {
    .ant-tabs-tab {
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .my_default_inside_page_tabs {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        width: 100%;
      }
      .ant-tabs-tab {
        flex: 1;
        min-width: 20px;
      }
    }
  }
}
