.dashboard_reputation_pulse {
  .dashboard_block {
    &--header {
      margin-bottom: 20px;
    }
  }
}

.reputation_pulse_pie_chart {
  width: 152px !important;
  min-width: 152px !important;
  .chart_custom_tooltip {
    &--inner {
      background-color: #363050;
      border: 1px solid rgba(255, 255, 255, 0.12);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.4px;
      border-radius: 15px;
      text-align: center;
      padding: 10px 20px;
      z-index: 3;
      display: block;
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        color: var(--secondaryTextColor);
      }
      .intro {
        color: var(--white);
        font-weight: 500;
        line-height: 26px;
        font-size: 20px;
      }
    }
  }
}

.reputation_pulse_pie_chart_data {
  width: 100%;
  .my_default_row {
    position: relative;
    padding-left: 20px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
    &:nth-child(1) {
      &::before {
        background-color: var(--green);
      }
    }
    &:nth-child(2) {
      &::before {
        background-color: var(--yellow);
      }
    }
    &:nth-child(3) {
      &::before {
        background-color: var(--red);
      }
    }
    &:last-child {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
    span {
      font-size: 16px;
      line-height: 24px;
      + span {
        font-weight: 600;
      }
    }
    + .my_default_row {
      margin-top: 8px;
    }
  }
}
