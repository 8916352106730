.my_default_radio_tabs {
  display: flex;
  gap: 12px;
  align-items: center;
  .ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    border-style: none;
    border-bottom: 3px solid transparent;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-size: 12px;
      font-weight: 500;
      color: var(--black);
    }
    &-checked {
      border-bottom: 3px solid var(--mainColor);
      border-color: var(--mainColor) !important;
      &:hover {
        border-bottom: 3px solid var(--mainColor);
      }
    }

    &::before {
      display: none;
    }
  }
}
