

.mat-input:focus {
  border-color: var(--mainColor);
  background-color: var(--mainColorLighter);
  box-shadow: none;
}

.mat-input:focus:hover {
  border-color: var(--mainColor);
  background-color: var(--mainColorLighter);
  box-shadow: none;
}

.mat-input:hover {
  border-color: #d9d9d9;
}