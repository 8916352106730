.switch-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .mat-form-item {
    margin-right: 8px !important;
    margin-bottom: 0;
    width: auto;
  }
  .ant-row {
    width: auto;
    margin-bottom: 0;
    .ant-form-item-control-input {
      min-height: auto;
    }
  }
  .text-with-icon {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
}

.switch-row-secondary {
  justify-content: flex-start;
  > * {
    margin-right: 10px;
  }
  .text-with-icon {
    font-size: 12px;
    color: var(--textColor);
  }
}

.mat-switch-secondary {
  height: 20px;
  max-width: 39px;
  min-width: 39px;
  &.ant-switch-checked {
    background: var(--green) !important;
    .ant-switch-handle {
      left: calc(100% - 18px - 2px);
    }
  }
  .ant-switch-handle {
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    &:before {
      box-shadow: 3px 3px 11px rgba(127, 144, 154, 0.8);
    }
  }
}

@media screen and (max-width: 479px) {
  .switch-row-secondary {
    > * {
      margin-right: 10px !important;
    }
  }

  .mat-form {
    .switch-row {
      flex-direction: row;
    }
  }
}
