:root {
  --white: #ffffff;
  --black: #0e0e0e;
  --lightgrey: #fafafa;
  --grey: #d1d2de;
  --lighter: #f5f5f5;
  --secondaryTextColor: #a3a6be;
  --mainColor: #5b66ea;
  --textColor: #474657;
  --purple: #332c6e;
  --green: #48af80;
  --greenLight: #eaf6f1;
  --borderLineColor: rgba(208, 209, 221, 0.34);
  --red: #e05d4c;
  --lightRed: #ff7366;
  --lightRedSecondary: #ffdcd9;
  --thridyTextColor: #77808d;
  --mainColorLighter: rgba(91, 102, 234, 0.1);
  --newLightGrey: #f6f7fb;
  --newGrey: #e5e9f2;
  --blue: #3f8cff;
  --blueLighter: #ebf3ff;
  --orange: #ea9957;
  --orangeLight: #fff5ea;
  --yellow: #ffc800;
  --newBackgroundGrey: #f9fafb;
  --darkLayoutColor: #070121;
  --gradient: linear-gradient(
    135deg,
    #18f5f5 0%,
    #f5b7d2 24%,
    #e744f6 51%,
    #862dfb 71%,
    #00dbf9 99%
  );
  --whiteBgGradient: linear-gradient(var(--white), var(--white)), var(--gradient);
}

[data-theme='dark'] {
  --darkBackground: #070121;
  --black: #ffffff;
  --darkBlack: #0e0e0e;
  --purple: #242142;
  --darkPurple: #302e47;
  --borderLineColor: rgba(229, 233, 242, 0.16);
}
