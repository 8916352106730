.my_default_action_by_text {
  span {
    font-size: 14px;
    font-weight: 600;
  }
  &--action_name {
    color: var(--thridyTextColor);
  }
  &--action_by {
    color: var(--black);
  }
}
