.mat-modal {
  .ant-modal-title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    .anticon {
      margin-right: 10px;
    }
  }
  .modal-content-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: overlay;
    overflow-x: hidden;
    padding: 0 15px;
  }
  .ant-modal-content {
    border-radius: 9px !important;
    padding: 0px !important;
  }
}

.ant-modal-header {
  padding: 20px 30px !important;
  border-radius: 9px 9px 0 0 !important;
  border-bottom: 1px solid var(--borderLineColor) !important;
}

.ant-modal-body {
  padding: 20px 15px !important;
}

.ant-modal-footer {
  padding: 20px 30px !important;
  border-radius: 0 0 9px 9px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--borderLineColor) !important;
  .mat-btn {
    max-width: 130px;
  }
}

.ant-modal-root {
  .ant-modal-confirm-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.ant-modal-mask {
  z-index: 10000;
}

.ant-modal-wrap {
  z-index: 10000;
}

.Toastify__toast-container {
  z-index: 99990;
}

[data-theme='dark'] {
  .mat-modal {
    .ant-modal-content {
      background-color: var(--purple);
      border: 1px solid var(--darkPurple);
      .ant-modal-header {
        background-color: var(--purple);
        .ant-modal-title {
          color: var(--white);
        }
      }
      .ant-modal-close {
        color: var(--secondaryTextColor);
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }
}
