.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);

  .anticon {
    padding: 12px;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &:hover {
      opacity: 0.3;
    }
  }
}

.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.7);
}

.ant-image-preview-operations {
  background: rgba(0, 0, 0, 0.8);
}

.ant-image-mask-info {
  display: flex;
  align-items: center;
}

.single-img {
  width: 70px !important;
  height: 67px !important;
  background-color: var(--lightgrey);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  margin: 4px;

  img {
    width: 68px;
    height: 65px;
    border-radius: 6px;
    background-color: var(--white);
    object-fit: contain;
  }

  &:hover {
    .img-close {
      display: flex;
    }
  }

  .img-close {
    display: none;
    margin-top: 2px !important;
    margin-left: 52px !important;
    position: absolute;
  
    &:hover {
      cursor: pointer;
    }
  }
}

.single-image-with-remove {
  position: relative;
  width: fit-content;
  display: flex;

  .img-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    &:hover {
      display: flex;
    }
  }
}
