.my_default_textarea {
  border-radius: 8px;
  outline: none;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px !important;
  color: var(--black) !important;
  padding: 0;
  border: 1px solid var(--newGrey) !important;
  textarea {
    padding: 12px 16px;
    &::placeholder {
      font-size: 16px;
      color: var(--thridyTextColor);
      font-weight: 400;
      letter-spacing: 0;
    }
  }
  &.ant-input-status-error,
  &.ant-input-status-error:focus,
  &.ant-input-status-error:hover {
    background-color: var(--newBackgroundGrey) !important;
    box-shadow: none;
    border-color: #ff4d4f !important;
  }
  &.ant-input-affix-wrapper-focused {
    border-color: var(--mainColor) !important;
  }
  &.grey {
    background-color: var(--newBackgroundGrey);
  }
  .ant-input-data-count {
    bottom: -27px;
  }
}
