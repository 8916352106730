.my_default_integration_block {
  &--header {
    border-bottom: 1px solid var(--borderLineColor);
    display: flex;
    margin-bottom: 15px;
    padding-bottom: 20px;
    .anticon {
      align-items: center;
      background-color: var(--white);
      border: 1px solid var(--newGrey);
      border-radius: 7px;
      display: flex;
      height: 40px;
      justify-content: center;
      margin-right: 16px;
      min-width: 40px;
      width: 40px;
    }
    &-title {
      overflow-wrap: break-word;
      white-space: normal;
      width: 90%;
      p {
        color: var(--black);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      span {
        color: var(--thridyTextColor);
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 8px;
        min-height: 40px;
      }
    }
  }
  &--footer {
    &-left {
      display: flex;
      flex-direction: column;
      gap: 4px;
      span {
        font-size: 14px;
        line-height: 18px;
        color: var(--secondaryTextColor);
        &:last-child {
          color: var(--black);
          font-weight: 700;
        }
      }
    }
    &-right {
      .my_default_modal_btn {
        display: flex;
        .anticon {
          cursor: pointer;
        }
      }
    }
  }
}
