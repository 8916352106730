.message_conversation_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  gap: 12px;

  &--row {
    display: flex;
    flex-direction: row;
  }

  &--selected-conv {
    display: flex;
    flex-direction: row;
    &-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--newLightGrey) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      svg {
        path {
          fill: var(--mainColor);
        }
      }
    }
  }

  &--header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 39px);

    &-name {
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding-right: 5px;
      width: calc(100% - 35px);

      &-text {
        white-space: nowrap;
        overflow: hidden;
        width: auto;
        text-overflow: ellipsis;
        color: var(--black);
        line-height: 19.6px;
      }

      svg {
        display: block !important;
        margin-left: 5px;
        min-width: 18px;
      }
    }

    &-time {
      font-size: 12px;
      font-weight: 400;
      color: var(--thridyTextColor);
      white-space: nowrap;
      letter-spacing: normal;
    }
  }

  &--last-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 12px;
      font-weight: 400;
      color: var(--thridyTextColor);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--sources {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
    &-leads {
      display: flex;
      flex-direction: row;
      font-size: 10px;
      color: var(--mainColor);
      background-color: var(--mainColorLighter);
      border-radius: 100px;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 4px 10px 5px;
      font-weight: 600;
      overflow: hidden;
      letter-spacing: 0.2px;
      line-height: 14px;
      height: 24px;
      &-plus-popup {
        font-size: 13px;
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &--needs-you {
    background: var(--lightRedSecondary);
    height: 24px;
    max-height: 24px;
    border-radius: 100px;
    padding: 0 10px 0 4px;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    color: var(--black);
    white-space: nowrap;
    margin-left: auto;
  }

  &--aiReply {
    background: linear-gradient(#eeeffc, #eeeffc), var(--gradient);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 200%;
    border: 1px solid transparent;
    border-radius: 100px;
    padding: 0 10px;
    font-weight: 600;
    line-height: 14px;
    font-size: 10px;
    height: 24px;
    max-height: 24px;
    animation: borderAnimation 2s infinite linear;
    white-space: nowrap;
    margin-left: auto;
  }
  &--details_wrapper {
    margin-top: -4px;
    font-weight: 400;
    font-size: 14px;
    color: var(--thridyTextColor);
    flex-wrap: wrap;
    display: flex;
    gap: 13px;
    .divider {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.conversation_item_wrapper {
  padding: 14px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
  background-color: var(--white);
  box-shadow: 2px 2px 15px 0px rgba(83, 85, 128, 0.1);
  border-radius: 12px;
  border-right: 3px solid transparent;
  transition: box-shadow 200ms ease-in-out;
  & + .conversation_item_wrapper {
    margin-top: 8px;
  }
  &.unread {
    border-right: 3px solid var(--red);
    background-color: var(--mainColorLighter);
  }
  &.active {
    background-color: var(--mainColor);
    .message_conversation_item {
      &--header {
        &-name {
          &-text {
            color: var(--white);
          }
        }
        &-time {
          color: var(--white);
        }
      }
      &--last-message {
        p {
          color: var(--white);
        }
      }
      &--sources {
        &-leads {
          background-color: var(--newLightGrey);
        }
      }
    }
  }
  &:hover {
    box-shadow: 2px 2px 8px 0px rgba(83, 85, 128, 0.3);
  }
}

@media screen and (max-width: 479px) {
  .conversation_item_wrapper {
    padding: 15px;
  }

  .message_conversation_item {
    &--header {
      &-name {
        font-size: 16px;
      }
    }
    &--last-message {
      p {
        font-size: 14px;
      }
    }
  }
}
