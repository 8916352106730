.my_default_input {
  border-radius: 8px;
  height: 44px;
  padding: 12px 16px;
  outline: none;
  box-shadow: none;
  border: 1px solid var(--newGrey);
  &.ant-input-status-error,
  &.ant-input-status-error:focus,
  &.ant-input-status-error:hover {
    background-color: var(--newBackgroundGrey) !important;
    box-shadow: none;
    border-color: #ff4d4f !important;
  }
  &.ant-input-affix-wrapper-focused {
    border-color: var(--mainColor) !important;
    box-shadow: none;
  }
  &:hover,
  &:focus {
    border-color: var(--mainColor);
    box-shadow: none;
  }
  input {
    font-weight: 500;
    font-size: 16px !important;
    color: var(--black);
    &::placeholder {
      font-size: 16px;
      color: var(--thridyTextColor);
      font-weight: 400;
      letter-spacing: 0;
    }
  }
  &.grey {
    background-color: var(--newBackgroundGrey);
  }
  &.white {
    background-color: var(--white);
  }
}
