@font-face {
  font-family: Roboto-Mention;
  src: url('../../styles//font/Roboto-Medium.ttf');
  font-weight: 400;
  font-style: normal;
}

.mat-mention-input-wrapper {
  &__input::placeholder {
    color: var(--secondaryTextColor);
  }
  textarea {
    padding: 8px 11px;
    font-weight: 500;
    border-radius: 0;
    border-style: none;
    font-family: Roboto-Mention !important;
    overflow-y: auto !important;
  }
  &.sending {
    textarea {
      cursor: not-allowed;
    }
  }
  textarea:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    cursor: not-allowed;
  }
  &__control {
    font-size: 14px;
    min-height: 70px;
    font-weight: normal;
    background-color: transparent;
    padding: 8px 11px;
    font-size: 15px;
    font-weight: 500;
    font-family: Roboto-Mention !important;
    max-height: 120px;
  }
  &__highlighter {
    position: relative;
    z-index: 1;
    pointer-events: none;
    border-style: none !important;
    span,
    strong {
      font-family: Roboto-Mention !important;
    }
  }
  &__suggestions {
    &__list {
      overflow: hidden auto;
      max-height: 250px;
      border: 1px solid var(--borderLineColor);
      border-radius: 8px;
      box-shadow:
        0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }
    &__item {
      transition: all 0.3s ease-in-out;
      width: 100%;
      padding: 10px 15px;
      color: var(--secondaryTextColor);
      background-color: var(--white);
      font-weight: 600;
      font-size: 12px;
    }
    &__item--focused {
      background-color: var(--mainColorLighter);
      color: var(--mainColor);
    }
  }
}

.mat-message-email-input-wrapper {
  .mat-mention-input-wrapper {
    &__input {
      font-size: 14px;
      border-style: none;
      height: 100%;
      padding: 8px 11px;
    }
    &__input::placeholder {
      color: var(--secondaryTextColor);
      opacity: 1;
      font-size: 14px;
      font-weight: 500;
    }
    &__control {
      min-height: 40px;
    }
  }
}

.mat-message-email-input-wrapper {
  div:last-child {
    .mat-mention-input-wrapper {
      &__control {
        min-height: 55px;
      }
    }
  }
}

.mat-message-email-input-wrapper {
  .mat-mention-input-wrapper {
    textarea {
      right: 0;
      border-top: 1px solid var(--borderLineColor);
    }
    textarea::placeholder {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
