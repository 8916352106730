.my_default_rate {
  flex-direction: row-reverse;
  display: flex;
  justify-content: flex-end;
  margin-left: -10px;
  margin-top: 12px;
  .item {
    height: 36px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--newGrey);
    align-items: center;
    justify-content: center;
    display: flex;
    color: var(--mainColor);
    font-size: 16px;
    font-weight: 700;
  }
  .ant-rate-star {
    margin-inline-end: 0px !important;
    max-width: 36px;
    width: 100%;
    margin-left: 10px;
    &.ant-rate-star-full {
      .item {
        border: 1px solid var(--mainColor);
        background-color: #eeeffc;
      }
    }
    &.ant-rate-star-zero {
      .ant-rate-star-second {
        color: var(--mainColor);
      }
    }
  }
  &.disabled_margin {
    margin-top: 0;
  }
}
