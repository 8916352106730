.working-hours-body {
  width: 100%;
  // margin: 0 -15px;
  // padding: 0 15px 15px;
  height: auto;
  overflow: visible;
  .mat-tabs {
    width: fit-content;
    .ant-tabs-nav-list {
      width: auto;
    }
    .ant-tabs-nav {
      &::before {
        border-style: none;
      }
    }
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        font-size: 16px;
        font-weight: 500;
        color: var(--thridyTextColor);
      }
    }
    .ant-tabs-content-holder {
      background-color: transparent;
      .mat-btn {
        margin: 20px 0px 20px auto;
      }
    }
  }
  .mat-form {
    .single-workng-day-inputs {
      .mat-form-item {
        width: 200px;
      }
    }
  }
}

.single-working-day {
  padding: 20px 24px;
  border-radius: 12px;
  background-color: var(--white);
  box-shadow: 2px 2px 15px 0px rgba(83, 85, 128, 0.1);
  + .single-working-day {
    margin-top: 16px;
  }
  .days {
    min-width: 94px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .ant-divider {
    width: 15px;
    min-width: fit-content;
    border-top: 1px solid var(--thridyTextColor);
    margin: 0 10px;
  }
  .mat-form-item {
    margin-bottom: 0px !important;
    .ant-select-selector {
      height: 40px;
    }
  }
}

.single-working-day-row {
  min-width: 231px;
  width: auto;
  margin-right: 32px;
  .text-with-icon {
    margin-left: 10px;
    margin-right: 20px;
  }
  .store_open_state {
    .ant-select-selector {
      height: 40px !important;
    }
  }
}

.custom-working-days {
  min-width: 280px;
  .days {
    min-width: fit-content;
    .mat-date-picker {
      height: 40px;
      .ant-picker-clear {
        right: 0;
      }
    }
  }
}

.ant-tabs-tabpane:has(.mat-btn) {
  .single-working-day:nth-last-child(-n + 2) {
    border-bottom: none;
    // padding-bottom: 0;
  }
}

.custom-working-day-delete {
  margin-left: auto;
}

@media screen and (max-width: 1099px) {
  .single-working-day-row {
    min-width: fit-content;
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
    .days {
      margin-top: 12px;
    }
    .store_open_state {
      margin-top: 12px;
    }
    &.custom-working-days {
      flex-direction: column !important;
      align-items: flex-start;
      .days {
        width: calc(100% - 40px);
      }
    }
  }

  .single-workng-day-inputs {
    width: 100%;
  }

  .working-hours-body {
    .mat-form {
      .single-workng-day-inputs {
        .mat-form-item {
          width: 100%;
        }
      }
      .row-div-container {
        flex-direction: row;
      }
      .single-working-day {
        flex-direction: column;
        padding: 10px;
        position: relative;
        .ant-divider {
          margin: 0 5px;
        }
      }
    }
    .mat-tabs {
      width: 100%;
    }
    .custom_working_day_flex {
      margin-left: 20px;
    }
  }

  .single-workng-day-inputs {
    margin-right: 0;
  }

  .custom-working-day-delete {
    top: 18px;
    position: absolute;
    right: 16px;
  }

  .working-hours-body {
    .ant-flex {
      width: 100%;
    }
    .time-picker-block-wrapper {
      .mat-select-wrapper {
        .mat-select-sub-wrapper {
          width: 60px;
          margin-left: 5px;
          .ant-select-selector {
            padding: 0 10px;
          }
          .ant-select-selection-item {
            margin-right: 0;
          }
          .ant-select-arrow {
            right: 10px;
          }
        }
      }
      .time-picker-block {
        input {
          width: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .working-hours-body {
    .custom_working_day_flex {
      margin-left: 0px;
    }
  }
  .working-hours-body {
    .mat-form {
      .single-working-day {
        .ant-flex {
          justify-content: space-between;
        }
      }
    }
  }

  .working-hours-body {
    .mat-tabs {
      .ant-tabs-nav-list {
        width: 100%;
      }
      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          font-size: 13px;
        }
      }
    }
  }
}
