.grid-container {
  display: grid;
  grid-gap: 20px;
}
.grid-item {
  overflow: hidden;
  border-radius: 9px;
}
.list-container {
  margin-top: 35px;
}
@media only screen and (max-width: 599px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
@media only screen and (min-width: 600px) and (max-width: 959px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }
}
@media only screen and (min-width: 960px) and (max-width: 1199px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
  }
}
@media only screen and (min-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
  }
}
