.overview_and_superhuman {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .dashboard_sent_statistics {
    margin-top: 0;
    .recharts-responsive-container {
      height: 202px !important;
      margin-top: 20px;
    }
  }
  .dashboard_sent_statistics_bar_chart {
    .recharts-responsive-container {
      height: 230px !important;
      margin-top: 5px;
    }
  }
  &--overview {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    .dashboard_block {
      &--header {
        margin-bottom: 10px;
      }
      &--tooltip-progress {
        width: 100%;
        margin-top: 0px;
        .ant-progress {
          .ant-progress-outer {
            width: 100% !important;
          }
        }
      }
    }
    &--half {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(2, 1fr);
      &-items {
        display: grid;
        gap: 20px;
      }
    }
  }
  &--superhuman {
    &--title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: var(--black);
      margin-bottom: 24px;
    }
  }
  &--collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 0;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;
        }
      }
      &.ant-collapse-item-disabled {
        .ant-collapse-header {
          cursor: default;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .overview_and_superhuman {
    &.open {
      .overview_and_superhuman--overview {
        grid-template-columns: repeat(1, 1fr);
        .dashboard_block {
          &--tooltip-progress {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1190px) {
  .overview_and_superhuman {
    &--overview {
      grid-template-columns: repeat(1, 1fr);
      .dashboard_block {
        &--tooltip-progress {
          margin-top: 10px;
        }
      }
      .dashboard_sent_statistics_multiple {
        .recharts-responsive-container {
          height: 220px!important;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .overview_and_superhuman {
    &--overview {
      &--half {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
