.mat-checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  .ant-checkbox {
    height: fit-content;
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid var(--grey);
      background-color: var(--newLightGrey);
    }
    + span {
      font-size: 14px;
      color: #77808d;
      font-weight: 500;
      line-height: 20px;
    }
    &.ant-checkbox-checked {
      &::after {
        border-style: none;
      }
      .ant-checkbox-inner::after {
        top: 44%;
        left: 25%;
        width: 5px;
        height: 10px;
      }
    }
  }
}

.mat-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner,
.mat-checkbox-wrapper .ant-checkbox.ant-checkbox-checked:hover .ant-checkbox-inner {
  border: 1px solid var(--mainColor) !important;
  background-color: var(--mainColor) !important;
}

.mat-checkbox-wrapper.ant-checkbox-wrapper-disabled
  .ant-checkbox.ant-checkbox-checked
  .ant-checkbox-inner {
  border: 1px solid var(--grey);
  background-color: var(--lighter);
}

.mat-checkbox-black {
  .ant-checkbox {
    + span {
      font-size: 12px;
      color: var(--textColor);
      font-weight: 600;
      line-height: normal;
    }
  }
}
