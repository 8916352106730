.my_default_inside_page_panel {
  border-top: 1px solid var(--borderLineColor);
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .my_default_inside_page_panel {
    flex-direction: column;
  }
}
