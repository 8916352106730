.messages_left_panel {
    &--header {
        .archive_or_all_read_wrapper {
            display: block;
            width: 100%;
            background-color: var(--transparent);
            border-radius: 8px;
            padding: 8px 16px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            margin-right: 0;
            .mat-btn.mat-btn-cancel {
              font-size: 13px;
              border-radius: 5px;
              font-weight: 400;
              width: 100%;
              height: 42px;
              padding: 0 18px;
              margin: 0;
              &:nth-child(2),
              &:nth-child(3) {
                margin-top: 8px;
              }
            }
        }
        .conversation_edit_button {
          min-width: 42px;
          max-width: 42px;
          height: 42px;
          border-radius: 50%;
          box-shadow: none !important;
          margin-right: 0;
          border: 1px solid var(--borderLineColor) !important;
          margin-left: 6px;
        }
        .conversation_slider_with_edit_button {
          .conversation_edit_button {
            margin: 0 16px 0 0;
            min-width: 32px;
            max-width: 32px;
            height: 32px;
          }
        }
    }
}