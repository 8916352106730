

.my-page {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    height: fit-content;
    padding: 0 55px;
    background-color: var(--white);
}

.righ-content-my-layout .righ-content-title {
    font-size: 14px;
    color: var(--secondaryTextColor);
    font-weight: bold;
    margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
    .my-page {
        padding: 20px;
    }
}