/* Select style */

.mat-select {
  height: auto;
  .ant-select-arrow {
    color: var(--black);
    transition: all 0.1s ease-in-out;
    right: 17px;
    opacity: 1;
    .anticon {
      pointer-events: none !important;
    }
  }
  .ant-select-clear {
    top: 50%;
    right: 35px;
    background-color: transparent;
    display: flex;
    opacity: 1;
    .ant-btn {
      padding: 0;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      background-color: transparent;
      border-style: none;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .ant-select-selector {
    border-radius: 4px;
    border: 1px solid var(--grey);
    .mat-select.ant-select-open {
      .ant-select-selection-item {
        color: unset;
      }
    }
    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        background-color: var(--lightgrey);
        border-style: none;
        padding: 5px;
        border-radius: 4px;
        height: 32px;
        line-height: 32px;
        margin-right: 4px;
        .ant-select-selection-item-content {
          font-size: 14px;
          color: var(--black);
          font-weight: 400;
        }
        .ant-select-selection-item-remove {
          display: flex;
          font-size: 10px;
          .anticon {
            color: var(--black);
          }
        }
      }
    }
    .ant-select-selection-placeholder {
      color: var(--thridyTextColor);
      font-weight: 400;
      line-height: 46px;
    }
  }
  &.ant-select-open {
    .anticon-down {
      transform: rotate(180deg);
    }
    .ant-select-selector {
      border: 1px solid var(--mainColor);
      background-color: var(--mainColorLighter);
    }
  }
}

.mat-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
  border: 1px solid var(--grey);
  height: 48px;
  padding: 0 17px;
}

.mat-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 14px;
  color: var(--black);
  line-height: 48px;
}

.mat-select.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 46px;
  font-size: 14px;
}

.mat-select.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.mat-select.ant-select-focused:not(.ant-select-disabled):not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none;
  border: 1px solid var(--grey);
}

.mat-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--grey);
  border-radius: 4px;
  height: 48px;
  padding: 5px 8px;
}

.mat-select:not(.ant-select-customize-input):hover .ant-select-selector {
  border-color: var(--grey);
}

.mat-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 48px;
  padding-left: 6px;
}

.mat-select.ant-select-open.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.mat-select.ant-select-open.ant-select-focused:not(
    .ant-select-disabled
  ).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.mat-select.ant-select-focused:not(.ant-select-disabled):not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid var(--mainColor);
}

.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 4px;
}

.mat-multiple-select:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 48px;
  height: auto;
  padding-right: 30px;
}

.mat-multiple-select
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-select-selection-item {
  width: fit-content;
  border-radius: 20px;
  background-color: var(--mainColorLighter);
  display: flex;
  align-items: center;
  padding: 6px 13px;
  margin-right: 3px;
}

.mat-multiple-select
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 3px 3px 3px 0;
  border-radius: 20px;
  padding: 2px 10px;
  height: 22px;
  border-style: none;
}

.mat-multiple-select
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag
  .mat-tag-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.mat-select-secondary.mat-multiple-select
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag-blue.ant-tag,
.mat-multiple-select
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag-blue.ant-tag {
  background-color: rgba(91, 102, 234, 0.3);
  color: var(--mainColor);
}

.mat-select-secondary.mat-multiple-select
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag-red.ant-tag,
.mat-multiple-select
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag-red.ant-tag {
  background-color: var(--mainColor);
  color: var(--white);
}

.mat-multiple-select {
  .ant-select-selector {
    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        .ant-select-selection-item-content {
          font-size: 12px;
          font-weight: 600;
          color: var(--mainColor);
          white-space: nowrap;
        }
        .ant-select-selection-item-remove {
          width: 10px;
          height: 10px;
          overflow: visible;
          min-width: 8px;
        }
      }
      .ant-tag {
        .ant-tag-close-icon {
          width: 8px;
          height: 9px;
          overflow: visible;
          color: var(--white);
        }
      }
      .ant-tag-blue.ant-tag .ant-tag-close-icon {
        color: var(--mainColor);
        height: 8px;
      }
    }
  }
  .anticon-close {
    display: flex;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.mat-select-secondary.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px;
  background-color: var(--newLightGrey);
  border: 1px solid var(--newGrey);
  border-radius: 8px;
}

.mat-select-secondary.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  font-weight: 400;
}

/* Select dropdown style */

.mat-form-select-dropdown {
  padding: 0;
  border: 1px solid var(--grey);
  border-radius: 8px;
  box-shadow: 4px 7px 40px fade(#000, 5%);
  .ant-select-item {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 10px 15px;
    color: var(--black);
    background-color: var(--white);
    font-weight: 500;
    font-size: 14px;
    &:hover {
      background-color: var(--mainColorLighter);
    }
  }
  .ant-select-item-option-state {
    display: flex;
    align-items: center;
  }
  .anticon-check {
    display: flex;
    color: var(--mainColor);
  }
}

[data-theme='dark'] {
  .mat-form-select-dropdown {
    .ant-select-item {
      background-color: var(--purple);
      color: var(--secondaryTextColor);
      &:hover {
        background-color: rgba(91, 102, 234, 0.3);
        color: var(--secondaryTextColor);
      }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: rgba(91, 102, 234, 0.3);
      color: var(--secondaryTextColor);
    }
  }
  .mat-select {
    .ant-select-selector {
      .ant-select-selection-placeholder {
        color: var(--secondaryTextColor);
      }
    }
  }
}

.mat-form-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--mainColorLighter);
}

.mat-multiple-select.mat-select-secondary:not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 50px;
  height: auto;
  padding-right: 30px;
  background-color: var(--newLightGrey);
  border: 1px solid var(--newGrey);
  border-radius: 8px;
}

.mat-multiple-select.mat-select-secondary
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-select-selection-item,
.mat-multiple-select.mat-select-secondary
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag {
  height: 32px;
  background-color: var(--mainColor);
}

.mat-multiple-select.mat-select-secondary
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag {
  font-size: 12px;
}

.mat-multiple-select.mat-select-secondary
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag-blue
  .ant-tag-close-icon {
  color: var(--mainColor);
}

.mat-multiple-select.mat-select-secondary
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-tag-red
  .ant-tag-close-icon {
  color: var(--white);
}

.mat-multiple-select.mat-select-secondary
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-select-selection-item
  .ant-select-selection-item-content {
  font-size: 12px;
  color: var(--white);
}

.mat-multiple-select.mat-select-secondary
  .ant-select-selector
  .ant-select-selection-overflow-item
  .ant-select-selection-item
  .ant-select-selection-item-remove
  path {
  fill: var(--white);
}

.mat-form-select-dropdown {
  .ant-select-item-option-content {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
    text-overflow: inherit;
  }
}

.mat-select
  .ant-select-status-error:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  background-color: #ffebe9 !important;
  border-color: #e05d4c !important;
}
