.phone_icon_wrapper {
  .phone_icon {
    position: relative;
    width: 301px;
    margin: 0 auto;
    .phone_under_nav_wrapper {
      background-color: var(--white);
      border-radius: 40px;
      box-shadow: 2px 7px 40px rgba(83, 85, 128, 15%);
      padding: 7px;
      position: absolute;
      bottom: 15px;
      width: 272px;
      left: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .nav_header_border {
        background-color: #f0f1f4;
        border-radius: 9px;
        height: 4px;
        width: 32px;
      }
      .logo_item {
        margin-top: 45px;
        margin-bottom: 29px;
        width: 137px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .title_item {
        max-width: 220px;
        margin: 0 auto;
        .title {
          width: 100%;
          white-space: normal;
          word-break: break-word;
          font-weight: bold;
          line-height: 22px;
          font-size: 18px;
        }
      }
      .note_item {
        max-width: 220px;
        margin: 0 auto;
        margin-top: 11px;
        .note {
          width: 100%;
          white-space: normal;
          word-break: break-word;
          font-weight: 400;
          line-height: 20px;
          font-size: 14px;
        }
      }
      .button_item {
        width: 237px;
        margin: 0 auto;
        margin-top: 23px;
        .swipe_to_start_button {
          width: 100%;
          height: 50px;
          position: relative;
          background-color: var(--mainColor);
          border-radius: 100px;
          padding: 5px;
          display: flex;
          align-items: center;
          .icon_wrapper {
            height: 40px;
            min-width: 40px;
            background-color: var(--white);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: linear infinite;
            animation-name: run;
            animation-duration: 2.5s;
            position: absolute;
          }
          .text {
            padding: 0 20px;
            padding-left: 25px;
            text-align: left;
            text-align: center;
            color: var(--white);
            font-weight: 600;
            font-size: 16px;
            width: 100%;
          }
        }
      }
      .nav_footer {
        margin-top: 20px;
        margin-bottom: 5px;
        .footer_first_item {
          font-size: 8px;
          color: var(--black);
          font-weight: 400;
          line-height: 10px;
          a {
            margin-left: 3px;
          }
        }
        .footer_last_item {
          font-size: 8px;
          color: var(--black);
          font-weight: 400;
          line-height: 10px;
          margin-top: 5px;
          a {
            color: var(--mainColor);
            margin-left: 2px;
          }
        }
      }
    }
  }
}
