.deposit-details-top-info-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 40px;
}

.deposit-details-top-info {
  box-shadow: 2px 2px 40px rgba(83, 85, 128, 0.1);
  border-radius: 12px;
  background-color: var(--white);
  padding: 20px;
  .mat-block-with-label {
    margin-top: 24px;
    .row-div-container {
      > * {
        margin-right: 12px;
      }
    }
    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-transform: none;
    }
  }
  .ant-table-title {
    background-color: var(--white);
  }
}

.payment-details-info-with-export {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
}

.amount-with-status {
  display: flex;
  align-items: center;
  padding-right: 10px;
  .amount-currency {
    margin-right: 9px;
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    color: var(--black);
  }
}

.status-block {
  width: 54px;
  height: 29px;
  color: var(--white);
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
}

.deposit-date-info {
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: var(--thridyTextColor);
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  background-color: var(--newLightGrey);
  padding: 12px;
  border-radius: 12px;
  width: fit-content;
  .anticon {
    margin-right: 4px;
  }
}

.deposit-fee-payout-info {
  color: var(--thridyTextColor);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.deposit-to-block {
  background-color: var(--newLightGrey);
  padding: 38px 22px;
  border-radius: 12px;
}

.atb-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: var(--black);
}

.atb-numbers {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--thridyTextColor);
  margin-top: 6px;
}

.deposit-list-item-mobile-view-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 9px;
  padding: 16px;
  + .deposit-list-item-mobile-view-wrapper {
    margin-top: 12px;
  }
}

.deposit-list-item-mobile-info {
  .payment-amount {
    font-size: 16px;
    padding-bottom: 4px;
    word-break: break-all;
    padding-right: 5px;
  }
  .payment-grey-item {
    font-size: 12px;
  }
}

.deposit-arrow-icon {
  rotate: -90deg;
}

.deposit-breakdown-reference {
  span {
    line-height: 1.75;
    display: block;
  }

  span:nth-child(2) {
    text-transform: uppercase;
    font-size: 15px;
  }
}

@media screen and (max-width: 991px) {
  .deposit-details-top-info-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 580px) {
  .amount-with-status {
    flex-direction: column-reverse;
    align-items: flex-start;
    .amount-currency {
      font-size: 24px;
    }
  }

  .deposit-details-top-info {
    overflow-y: auto;
  }
}
