.center_message_item_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  text-align: center;
  position: relative;
  .icon-wrapper {
    width: 32px;
    height: 32px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-size: 16px;
    color: var(--mainColor);
    border-radius: 8px;
    border: 1px solid rgba(107, 117, 236, 0.20);
    background: rgba(107, 117, 236, 0.05);
  }
  .date {
    font-size: 12px;
    font-weight: 400;
    color: var(--thridyTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon {
      margin-left: 5px;
    }
  }
  .center_message_item_block {
    background-color: transparent;
    padding: 12px 12px 0 12px;
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
    overflow: hidden;
    &--body {
      .mat-btn {
        margin: 4px auto 0 auto;
      }
      &-text {
        font-size: 14px;
        color: var(--purple);
        font-weight: 500;
        max-width: 400px;
        i {
          font-size: 12px;
          font-weight: 400;
          color: var(--secondaryTextColor);
          font-style: normal;
        }
      }
    }
    &--footer {
      background-color: var(--lightgrey);
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
      margin-top: 8px;
      border-radius: 100px;
      gap: 8px;
      &-item {
        display: flex;
        align-items: center;
        color: var(--thridyTextColor);
        .anticon {
          margin-right: 4px;
        }
      }
    }
  }
  &--button {
    margin-top: 5px;
    .mat-btn {
      height: 32px;
      font-size: 12px;
      &.mark_as_read_btn {
        background-color: var(--green);
        &:hover {
          background-color: var(--green);
        }
      }
    }
  }
}
