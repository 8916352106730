.my_default_row {
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    &:first-child {
      color: var(--secondaryTextColor);
    }
    &:last-child {
      color: var(--black);
    }
  }
}
