

.react-tel-input {
  font-size: 14px;
  font-family: 'SFPro', sans-serif;
  .flag-dropdown {
    border-radius: 4px 0 0 4px;
    margin: 1px 0 1px 1px;
    border: 1px solid transparent;
    border-right: 1px solid var(--grey);
  }
  .form-control {
    border-radius: 4px;
    border: 1px solid var(--grey);
    &:disabled {
      background-color: var(--lighter);
    }
    &:focus {
      background-color: var(--mainColorLighter);
      box-shadow: none;
      border: 1px solid var(--mainColor);
    }
  }
}

.mat-form-item-black {
  .react-tel-input {
    height: 50px;
    .form-control {
      border-radius: 8px;
      background-color: var(--newLightGrey);
      border: 1px solid var(--newGrey);
      height: 50px !important;
    }
    .flag-dropdown {
      border-right: 1px solid var(--newGrey);
    }
  }
}
