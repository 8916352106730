/* Modal */
.inputWithSearch {
  display: flex;
  align-items: center;
  width: 40%;
  height: 46px;
  cursor: pointer;
  background-color: var(--lighter) !important;
  border-radius: 5px;
  padding-left: 10px;
  cursor: pointer;
  .ant-input-affix-wrapper {
    background-color: var(--lighter);
    border: none;
    height: 44px;
    > input.ant-input {
      background-color: transparent;
    }
  }
}

.ant-form {
  &.ant-form-vertical {
    &.ant-form-middle {
      &.mat-form {
        &.touchponts_edit_modal {
          overflow-y: hidden !important;
          height: 450px;
        }
      }
    }
  }
}

.touchponts_edit_modal {
  &--users-wrapper {
    margin-top: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 288px;
    cursor: pointer;
    &-field-title {
      background-color: var(--lighter);
      width: 100%;
      height: 30px;
      padding: 10px 0 0 8px;
      font-size: 12px;
      color: var(--thridyTextColor);
      line-height: 12px;
      cursor: auto;
    }
    &-field-users {
      .user_field {
        padding: 15px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        border-bottom: 1px solid var(--borderLineColor);
        color: var(--secondaryTextColor);
        font-size: 12px;
        &:hover {
          background-color: var(--mainColorLighter);
          color: var(--mainColor);
        }
        &.not_border_bottom {
          border-bottom: none !important;
        }
      }
      svg {
        fill: var(--mainColor);
      }
      .anticon {
        display: flex;
        min-width: 12px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.select-auto-assignement-with-search {
  justify-content: space-between;
  margin-bottom: 10px;
  width: auto;
  .auto-assignment-label {
    font-size: 14px;
    white-space: break-spaces;
    margin-right: 10px;
    text-align: left;
    color: var(--thridyTextColor);
    opacity: 1;
    font-weight: 400;
  }
  .drop-down-trigger-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100px;
    width: auto;
    border: 1px solid #5b66ea;
    border-radius: 4px;
    padding: 0 10px;
    height: 40px;
    cursor: pointer;
    .title-item {
      margin-right: 8px;
      white-space: nowrap;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 600;
      color: #5b66ea;
      padding-right: 5px;
      height: 26px;
      line-height: 26px;
      cursor: pointer;
    }
  }
  .inputWithSearch {
    max-width: 250px;
    margin-left: 10px;
    height: 40px;
    .ant-input-affix-wrapper {
      height: 39px;
    }
  }
}

.select-auto-assignment {
  width: auto;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 496px) {
  .select-auto-assignement-with-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .auto-assignment-label {
      margin-bottom: 10px;
    }
    .drop-down-trigger-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  .select-auto-assignement-with-search {
    flex-direction: column;
    align-items: flex-start;
    .inputWithSearch {
      margin-left: 0;
      max-width: 100%;
      margin-top: 10px;
    }
  }
}
