.emoji-with-icon {
  position: relative;
  margin-right: 0px !important;

  .emoji-modal {
    position: absolute;
    right: auto;
    bottom: 30px;
    z-index: 1;
    transform: translate(-38%, 0);
  }

  .icon-size {
    margin-right: 0;
    &.disabled {
      opacity: 0.5;
    }
  }
  &:hover {
    svg {
      path {
        stroke: var(--mainColor);
      }
    }
  }

  &.active-emoji {
    .message-center-button {
      background-color: var(--lighter) !important;

      svg {
        path {
          stroke: var(--mainColor);
        }
      }
    }
  }
}

.emoji-modal-hide {
  display: none;
}
