.dashboard_marketing_page {
    .dashboard_sent_statistics {
        .recharts-responsive-container {
            height: 470px!important;
        }
    }
    .chart_custom_legend {
        width: 100%;
        margin-top: 10px;
        &--items {
            padding: 9px 14px;
            border-radius: 8px;
            span + span {
                font-size: 14px;
                font-weight: 500;
            }
            &.leads {
                background-color: rgba(249, 187, 6, 0.12);
            }
            &.influenced_visits {
                background-color: rgba(63, 140, 255, 0.12);
            }
        }
    }
}