.mat-select-wrapper {
  width: 100%;
  .select-label {
    height: auto;
    color: var(--purple);
    font-weight: bold;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.5715;
    padding: 0 0 8px;
  }
  .mat-select-sub-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    height: 40px;
    background-color: var(--white);
    border-radius: 3px;
    border: 1px solid var(--grey);
    .mat-select {
      width: 100%;
      &:not(.ant-select-show-search) .ant-select-selection-search {
        display: none;
      }
      .ant-select-selector {
        padding: 0 15px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        border-style: none !important;
        height: 100% !important;
        .ant-select-selection-item {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    &.select-with-icon {
      position: relative;
      .mat-select {
        .ant-select-selector {
          padding-left: 40px;
        }
      }
      .select-left-icon {
        display: flex;
        position: absolute;
        left: 8px;
        width: 23px;
        height: 14px;
        padding-right: 8px;
        border-right: 1px solid rgba(91, 102, 234, 0.2);
        align-items: center;
        justify-content: center;
        .anticon {
          width: 100%;
          height: 100%;
        }
        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &.primary {
      background-color: var(--mainColorLighter);
      box-shadow: 0px 2px 10px var(--white);
      border-style: none;
      border-radius: 6px;
      .select-left-icon {
        svg {
          fill: var(--mainColor);
        }
      }
      .mat-select {
        .ant-select-selector {
          .ant-select-selection-item {
            color: var(--mainColor);
            font-size: 12px;
          }
        }
        .ant-select-arrow {
          path {
            fill: var(--mainColor);
          }
        }
      }
    }
    &.secondary {
      background-color: var(--white);
      box-shadow: none;
      border-radius: 10px;
      border: 2px solid #cfc8ff;
      height: 36px;
    }
  }
}

.ant-select-disabled {
  .ant-select-selection-item {
    color: var(--secondaryTextColor) !important;
  }
}

.ant-form-item-label {
  label:after {
    display: none;
  }
}

.mat-select-dropdown {
  padding: 4px;
  border-radius: 8px;
  z-index: 10000;
  .rc-virtual-list-scrollbar-show {
    display: block !important;
  }
  .ant-select-item {
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 10px 15px;
    color: var(--secondaryTextColor);
    background-color: var(--white);
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    &:hover {
      background-color: var(--mainColorLighter);
      color: var(--mainColor);
    }
    &.ant-select-item-option-selected {
      background-color: var(--mainColorLighter);
      color: var(--mainColor);
    }
  }
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}

.mat-select-wrapper-secondary,
.mat-select-wrapper-secondary .mat-select {
  width: 100%;
  height: auto;
}

.mat-select-wrapper-secondary
  .mat-select:not(.ant-select-customize-input)
  .ant-select-selector,
.mat-select-wrapper-secondary
  .mat-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
  min-height: 50px;
  background-color: var(--newLightGrey);
  border: 1px solid var(--newGrey);
  border-radius: 8px;
}

.mat-select-wrapper-secondary
  .mat-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector,
.mat-select-secondary:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: var(--mainColor);
  box-shadow: none;
}

.mat-select-wrapper-secondary {
  .mat-select {
    &:not(.ant-select-show-search) .ant-select-selection-search {
      display: none;
    }
    .ant-select-selector {
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          background-color: var(--newGrey);
          .ant-select-selection-item-content {
            color: var(--mainColor);
          }
          .ant-select-selection-item-remove {
            .anticon {
              color: var(--black);
            }
          }
        }
      }
    }
  }
}

.mat-select-wrapper-secondary {
  .select-label {
    color: var(--textColor);
    font-weight: 600;
    text-transform: capitalize;
    padding: 0 0 6px;
    line-height: 18px;
    font-size: 12px;
  }
}

.ant-select-dropdown {
  z-index: 10000;
}

.mat-select-wrapper-secondary
  .mat-multiple-select:not(.ant-select-customize-input)
  .ant-select-selector {
  min-height: 50px;
  height: auto;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-select-single {
  height: 48px;
  display: flex;
  align-items: center;
}

.ant-select {
  &:hover {
    .ant-select-arrow:not(:last-child) {
      opacity: 1;
    }
  }
}

[data-theme='dark'] {
  .mat-select-wrapper {
    .mat-select-sub-wrapper {
      &.primary {
        background-color: var(--mainColor);
        box-shadow: none;
        .select-left-icon {
          border-right: 1px solid var(--white);
          svg,
          path {
            fill: var(--white);
          }
        }
        .mat-select {
          .ant-select-selector {
            .ant-select-selection-item {
              color: var(--white);
            }
          }
          .ant-select-arrow {
            color: var(--white);
            svg,
            path {
              fill: var(--white);
            }
          }
        }
      }
      &.secondary {
        background-color: var(--purple);
        border: 1px solid var(--darkPurple);
        .mat-select {
          .ant-select-selector {
            .ant-select-selection-item {
              color: var(--secondaryTextColor);
            }
          }
          .ant-select-arrow {
            color: var(--secondaryTextColor);
          }
        }
      }
    }
  }

  .ant-select-dropdown {
    background-color: var(--purple);
    border: 1px solid var(--darkPurple);
  }
  .mat-select-dropdown {
    .ant-select-item {
      background-color: var(--purple);
      &:hover {
        background-color: rgba(91, 102, 234, 0.3);
        color: var(--secondaryTextColor);
      }
      &.ant-select-item-option-selected {
        background-color: rgba(91, 102, 234, 0.3);
        color: var(--secondaryTextColor);
      }
    }
  }

  .mat-select-wrapper-secondary {
    .mat-select:not(.ant-select-customize-input),
    .mat-select.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        background-color: var(--purple);
        border: 1px solid var(--darkPurple) !important;
        .ant-select-selection-item {
          color: var(--secondaryTextColor);
        }
      }
      &:hover {
        .ant-select-selector {
          border: 1px solid var(--darkPurple);
        }
      }
      &.mat-select.ant-select-open {
        .ant-select-selector {
          border: 1px solid var(--darkPurple);
        }
      }
      .anticon-close-circle,
      .ant-select-suffix {
        color: var(--secondaryTextColor);
      }
    }
  }
}
