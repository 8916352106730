.location_block_images {
  display: grid;
  grid-template-columns: repeat(2, minmax(calc(50% - 10px), 1fr));
  grid-gap: 20px;
  margin-top: 20px;
  .ant-image-mask-info {
    display: flex;
    align-items: center;
    .anticon {
      margin-bottom: 2px;
    }
  }
  .ant-image-mask {
    border-radius: 8px;
  }
  .ant-image-img {
    height: 212px;
    width: 100%;
    background-color: var(--newLightGrey);
    object-fit: contain;
    border-radius: 8px;
  }
  .no-image {
    background-color: var(--newLightGrey);
    width: 100%;
    height: 212px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
