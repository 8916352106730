.my_default_image {
  background-color: var(--lighter);
  border: 1px solid var(--borderLineColor);
  border-radius: 8px;
  height: 70px;
  margin-top: 5px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .ant-image-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &.disableMargin {
    margin-top: 0;
  }
}
