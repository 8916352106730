.platform-form {
  background-color: var(--mainColor);
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0 0 0 26px;
  margin-bottom: 22px;
  margin-top: 5px;
  .platform-name {
    margin-left: 8px;
    font-size: 18px;
    color: var(--white);
  }
  .payment-icon {
    display: flex;
    position: absolute;
    right: 17px;
    top: 45px;
  }
}

.payment-type {
  .invoice-field {
    border-radius: 9px 9px 0 9px;
    background-color: var(--lighter);
    margin-top: 9px;
    h2 {
      color: var(--secondaryTextColor);
      font-size: 14px;
      padding: 27px 0 10px 25px;
    }
    .invoice-notification {
      position: relative;
      overflow: hidden;
      width: 350px;
      height: 61px;
      display: flex;
      justify-content: space-between;

      &.red {
        background-color: var(--lightRed);
      }

      &.green {
        background-color: var(--green);
      }

      &.black {
        background-color: var(--textColor);
      }

      .invoice-notes {
        display: flex;
        align-items: center;
        font-size: 12px;
        .invoice-icon {
          margin: 10px;
          display: flex;
          align-items: center;
        }
        .invoice-amount {
          color: var(--white);
          z-index: 1;
          span {
            display: block;
          }
        }
        .invoice-amount-refunded {
          color: var(--secondaryTextColor);
          z-index: 1;
          span {
            display: block;
          }
        }
      }

      .payment-icon {
        right: -14px;
        top: -14px;
        position: absolute;
      }
    }
    .invoice-post {
      width: 300px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 25px;
      padding-top: 10px;

      .invoice-label {
        color: var(--secondaryTextColor);
        font-size: 14px;
      }

      label {
        font-size: 12px;
        display: block;
      }

      span {
        margin-bottom: 10px;
        width: 50%;
      }
    }
    .payment-cancel-btn {
      padding: 0 25px 27px;
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 479px) {
  .payment-type {
    .invoice-field {
      width: 100%;
      .invoice-post,
      .invoice-notification {
        width: 100%;
      }
    }
  }
}
