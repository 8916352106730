
:root {
--circleGrey: #938FA5;
}
.dashboard_marketing_circle_chart {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
  margin: 0 auto;
  &.green {
    border: 4px solid rgba(72, 175, 128, 0.2);
    .dashboard_marketing_circle_chart--inner {
      text-shadow:
        1px 1px 2px transparent,
        0 0 1em var(--green);
    }
  }
  &.red {
    border: 4px solid rgba(224, 93, 76, 0.2);
    .dashboard_marketing_circle_chart--inner {
      text-shadow:
        1px 1px 2px transparent,
        0 0 1em var(--red);
    }
  }
  &.blue {
    border: 4px solid rgba(63, 140, 255, 0.2);
    .dashboard_marketing_circle_chart--inner {
      text-shadow:
        1px 1px 2px transparent,
        0 0 1em var(--blue);
    }
  }
  &.mainColor {
    border: 4px solid rgba(91, 102, 234, 0.2);
    .dashboard_marketing_circle_chart--inner {
      text-shadow:
        1px 1px 2px transparent,
        0 0 1em var(--mainColor);
    }
  }
  &.yellow {
    border: 4px solid rgba(255, 200, 0, 0.2);
    .dashboard_marketing_circle_chart--inner {
      text-shadow:
        1px 1px 2px transparent,
        0 0 1em var(--yellow);
    }
  }
  &.circleGrey {
    border: 4px solid rgba(234, 246, 241, 0.2);
    .dashboard_marketing_circle_chart--inner {
      text-shadow:
      1px 1px 2px transparent,
      0 0 1em var(--circleGrey);
    }
  }


  &--container {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    &--outer {
      border-radius: 50%;
      background-color: var(--purple);
      width: 88%;
      height: 88%;
      display: flex;
      justify-content: center;
    }
  }
}

.dashboard_tooltip {
  .ant-tooltip-inner {
    background-color: #363050 !important;
    border: 1px solid rgba(255, 255, 255, 0.12);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.4px;
    min-width: min-content;
    max-width: max-content;
    width: 45px;
    border-radius: 5px;
    text-align: center;
    padding: 6px 8px;
  }
  .ant-tooltip-arrow {
    bottom: 1px !important;
    &::before {
      border-radius: 0;
      background-color: #363050;
    }
    &::after {
      border-radius: 0;
      border: 1px solid rgba(255, 255, 255, 0.12);
    }
  }
}
