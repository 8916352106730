.dashboard_sent_statistics {
  background-color: var(--purple);
  border: 1px solid var(--darkPurple);
  color: var(--white);
  overflow: hidden;
  border-radius: 16px;
  margin-top: 20px;
  padding: 20px 20px 20px 10px;
  position: relative;
  .dashboard_sent_statistics_headers {
    display: grid;
    grid-template-columns: 1fr 0.1fr 1fr;
    .ant-divider-vertical {
      height: 100%;
    }
  }
  &-bg {
    width: 50%;
    height: 150px;
    border-radius: 100px;
    opacity: 0.2;
    filter: blur(70px);
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: 0;
    left: 50%;
    &.blue {
      background-color: #1daca9;
    }
    &.purple {
      background-color: var(--mainColor);
    }
    &.green {
      background-color: #50c08d;
    }
    &.electric_blue {
      background-color: #3F8CFF;
    }
  }
  &--header {
    margin-bottom: 10px;
    margin-left: 10px;
    span {
      + span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
  &--information {
    margin-left: 10px;
    article {
      &[level='1'] {
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.32px;
        color: var(--white);
        margin-right: 12px;
      }
      &[level='2'] {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: var(--green);
        margin-right: 8px;
        span {
          transform: rotate(-45deg);
          display: inline-block;
          position: relative;
          top: 1px;
        }
      }
      &[level='3'] {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: var(--secondaryTextColor);
        display: flex;
        align-items: center;
      }
    }
    &--hours {
      margin-top: 20px;
      .ant-col {
        min-width: auto;
      }
      &-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: var(--secondaryTextColor);
      }
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.2px;
        margin-top: 4px;
        width: fit-content;
      }
      .ant-divider {
        margin: 0 15px 0 30px;
      }
    }
  }
  .recharts-responsive-container {
    height: 450px !important;
    margin-top: 32px;
    .recharts-cartesian-axis {
      line {
        stroke: transparent;
      }
      .recharts-cartesian-axis-ticks {
        text {
          fill: var(--secondaryTextColor);
        }
      }
    }
    .recharts-tooltip-wrapper {
      z-index: 2;
    }
    .recharts-legend-wrapper {
      display: flex;
      justify-content: center;
      padding-left: 10px;
      .recharts-default-legend {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid rgba(255, 255, 255, 0.12);
          border-radius: 100px;
          padding: 2px 12px!important;
          width: fit-content;
          .recharts-legend-item {
              display: flex!important;
              align-items: center;
              margin-right: 16px!important;
              .recharts-surface {
                  margin-right: 8px!important;
              }
              &:last-child {
                  margin-right: 0!important;
              }
          }
      }
  }
    .chart_custom_tooltip {
      &--inner {
        background-color: #363050;
        border: 1px solid rgba(255, 255, 255, 0.12);
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.4px;
        border-radius: 15px;
        text-align: center;
        padding: 10px 20px;
        z-index: 3;
        display: block;
        &::after,
        &::before {
          content: '';
          position: absolute;
          top: 99%;
          left: 50%;
          border-style: solid;
        }
        &::after {
          margin-left: -10px;
          border-width: 10px;
          border-color: rgba(255, 255, 255, 0.18) transparent transparent transparent;
        }
        &::before {
          border-width: 9px;
          margin-left: -9px;
          z-index: 1;
          border-color: #363050 transparent transparent transparent;
        }
        p {
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          color: var(--secondaryTextColor);
        }
        .intro {
          color: var(--white);
          font-weight: 500;
          line-height: 26px;
          font-size: 20px;
        }
      }
      &.isTabletPortraitOrMobile {
        .chart_custom_tooltip--inner {
          padding: 10px;
          .ant-flex {
            flex-direction: column;
            align-items: flex-start;
            gap: 0!important;
            &:last-child {
              margin-top: 5px;
            }
          }
          .intro {
            font-size: 16px;
          }
        }
      }
    }
  }
  &-chart-info {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: var(--secondaryTextColor);
  }
}

@media screen and (max-width: 767px) {
  .dashboard_sent_statistics {
    padding: 15px 15px 15px 5px;
    .dashboard_sent_statistics_headers {
      grid-template-columns: 1fr;
      gap: 12px;
      .ant-divider-vertical {
        height: 1px;
        width: 100%;
        background-color: #38334D;
        margin-inline: 0;
      }
    }
  }
}
