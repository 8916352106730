.my_default_upload_circle_wrapper {
  .ant-upload-select {
    border: 1px solid var(--borderLineColor) !important;
    width: 64px !important;
    height: 64px !important;
    .ant-image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &:hover {
      border-color: var(--mainColor) !important;
    }
  }
  &--image_item {
    height: 100%;
    width: 100%;
    position: relative;
    .pencil {
      width: 28px;
      height: 28px;
      background-color: var(--newGrey);
      border-radius: 50%;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
