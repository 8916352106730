.choose-tags-for-broadcast-wrapper {
    background-color: var(--lighter);
    margin-bottom: 25px;
    padding: 10px;
    border-radius: 7px;
    .row-div-container {
      align-items: flex-start;
      .mat-form-item {
        margin-bottom: 0;
        &:first-child {
          width: 100%;
          max-width: 17%;
          margin-right: 10px;
          .ant-select-selector {
            padding: 0 11px;
            height: 48px;
          }
          .mat-select {
            .ant-select-arrow {
              right: 10px;
            }
          }
        }
      }
      & + .row-div-container {
        margin-top: 10px;
      }
    }
}