.my_default_input_search {
  max-width: 327px;
  width: 100%;
  height: 44px;
  border: 1px solid var(--newGrey);
  background-color: var(--white);
  padding: 10px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .my_default_input {
    padding: 0;
    border-radius: 0;
    border-style: none;
    height: 42px;
    font-size: 16px;
    background-color: transparent;
  }
  .ant-input-affix-wrapper-focused {
    background-color: transparent;
  }
  &.grey {
    background-color: var(--newBackgroundGrey);
    .my_default_input_search--input {
      background-color: var(--newBackgroundGrey);
    }
  }
  &.white {
    background-color: var(--white);
  }
}
