.default_block_wrapper {
  .my_default_block_row {
    .voice_ai_block_row_right_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      &--block {
        padding: 3px 14px;
        border-radius: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          background-color: var(--greenLight);
          color: var(--green);
        }
        &.inactive {
          background-color: var(--lightRedSecondary);
          color: var(--red);
        }
      }
    }
  }
  .voice_ai_working_hours_wrapper {
    margin-top: 24px;
    .single-working-day {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      align-items: flex-end;
      .time-picker-block-wrapper {
        .mat-select-sub-wrapper {
          .ant-select-single {
            .ant-select-selector {
              background-color: var(--newBackgroundGrey) !important;
              border: 1px solid var(--borderLineColor) !important;
              .ant-select-selection-item {
                color: var(--black) !important;
                font-size: 16px;
                font-weight: 500;
              }
            }
          }
          .ant-select-arrow {
            color: var(--black) !important;
          }
        }
        .time-picker-block {
          background-color: var(--newBackgroundGrey);
          input {
            background-color: var(--newBackgroundGrey);
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
      .single-working-day-row {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: auto;
        margin-bottom: 0;
        min-width: 0;
        .my_default_select {
          width: 166px !important;
          min-width: auto !important;
        }
        .store_open_state {
          margin-top: 0;
          .ant-select-selector {
            height: 44px !important;
          }
        }
      }
      + .single-working-day {
        margin-top: 24px;
      }
      .days {
        font-size: 16px;
        font-weight: 500;
        grid-column: 1 / span 10;
        margin-top: 0;
      }
    }
    .voice_ai_single_working_day_tablet {
      .ant-collapse-header {
        display: flex;
        align-items: center;
        padding: 10px 12px 10px 16px;
      }
      .ant-collapse-content {
        padding-top: 6px;
        .time-picker-block-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          .time-picker-block {
            width: 50%;
            input {
              width: 50%;
              height: 44px;
              background-color: var(--newBackgroundGrey);
              font-size: 16px;
              font-weight: 500;
            }
          }
          .mat-select-wrapper {
            width: 50%;
            .mat-select-sub-wrapper {
              width: 100%;
              margin-left: 0;
              .ant-select-selector {
                background-color: var(--newBackgroundGrey);
                border: 1px solid var(--borderLineColor) !important;
                span {
                  color: var(--black);
                  font-size: 16px;
                  font-weight: 500;
                }
              }
              .ant-select-arrow {
                color: var(--black) !important;
              }
            }
          }
          .with-select {
            gap: 8px;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .default_block_wrapper {
    .my_default_block_title {
      .voice_ai_block_call_transfers_additional_header {
        width: 100%;
        margin-top: 24px;
        .my_default_button {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
