.organization-new-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: var(--textColor);
    margin-right: 12px;
  }
  .title-row {
    display: flex;
    align-items: center;
  }
  .subtitle {
    color: var(--secondaryTextColor);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 4px;
  }
}

.organization-new-list-right-part {
  display: flex;
  align-items: center;
  .button-with-dropdown-wrapper {
    margin-right: 0px;
  }
}

.organization-new-mat-list-wrapper {
  width: 100%;
  box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
  border-radius: 8px;
  background-color: var(--white);
  margin-top: 0;
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    .organization-new-image-title,
    .organization-new-list-part {
      padding: 13px;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 90px;
    }
    .organization-new-list-part {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 14px;
        height: 33px;
        background: #edf4fe;
        border-radius: 20px;
        color: var(--thridyTextColor);
        font-size: 14px;
        font-weight: 600;
        margin-right: 5px;
        margin-top: 5px;
      }
    }
    .organization-new-image-title {
      .item-image {
        height: 50px;
        width: 74px;
        border-radius: 13px;
        object-fit: contain;
        background-color: var(--lighter);
      }
    }
    .organization-new-action-container {
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 10%;
    }
    .edit-delete-panel {
      display: flex;
      align-items: center;
      margin-left: 10px;
      width: auto;
      .edit-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin-right: 0px;
        &:hover {
          background-color: var(--mainColorLighter);
        }
      }
    }
    :last-child {
      .organization-new-list-one-item-wrapper {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.organization-new-mat-list-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--newGrey);
  background-color: #ecefef;
}

.organization-new-mat-list-header-item {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  padding: 13px;
  color: var(--thridyTextColor);
  &:last-child {
    width: 10%;
  }
}

.organization-new-list-edit-delete {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.organization-new-list-one-item-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.organization-new-greeting-default {
  white-space: nowrap;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  &.current {
    cursor: default;
    color: var(--green);
  }
  &.active {
    color: var(--mainColor);
  }
}

.organization-new-list-dot-drop-menu {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        .drop-item {
          .drop-item-icon-with-text {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.organization-layout-block {
  box-shadow: 2px 2px 40px 0px rgba(83, 85, 128, 0.1);
  background-color: var(--white);
  border-radius: 16px;
  padding: 24px 20px;
}

.organization-layout-block-label {
  margin-bottom: 24px;
  label {
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
  }
}

@media screen and (max-width: 1190px) {
  .organization-new-mat-list-wrapper {
    .list-item {
      .organization-new-image-title {
        word-break: break-all;
      }
    }
  }
}

@media screen and (max-width: 429px) {
  .organization-new-list-right-part {
    .button-with-dropdown-wrapper {
      .mat-btn {
        max-width: 150px;
      }
    }
  }
}

@media screen and (max-width: 767px) {

  .organization-new-mat-list-wrapper {
    .list-item {
      .organization-new-image-title {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      .organization-new-image-title,
      .organization-new-list-part {
        padding: 10px;
      }
      .organization-new-action-container {
        flex-direction: column-reverse;
        align-items: flex-end;
        position: relative;
        right: 20px;
        .mat-dropdown-trigger-container {
          justify-content: flex-end;
        }
      }
      .organization-new-greeting-default {
        margin-right: 0px;
        margin-top: 10px;
      }
    }
  }

  .organization-new-mat-list-header-item {
    padding: 10px;
  }
}
