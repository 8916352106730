.extension-profile-info-wrapper {
  background-color: var(--white);
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  overflow-y: auto;
  .extension_location_wrapper {
    display: flex;
    align-items: center;
    padding: 7px 15px;
    background-color: var(--white);
    justify-content: flex-end;
    .extension_user_location {
      width: 50%;
      .mat-select-wrapper {
        margin-right: 0;
      }
      + .extension_user_location {
        padding-left: 10px;
      }
      .mat-select-wrapper {
        .mat-select-sub-wrapper {
          height: 32px;
          &.primary {
            .ant-select-selection-search {
              color: var(--mainColor);
            }
          }
          .ant-select-selector {
            .ant-select-selection-search-input {
              padding-left: 28px;
            }
          }
          &.select-with-icon {
            .select-left-icon {
              width: 24px;
              padding-right: 9px;
            }
          }
        }
      }
    }
  }
  .message_right_panel {
    margin: 0;
    max-width: 100%;
    min-width: 100%;
    padding: 0;
    border-top: none;
  }
  .message_center_panel {
    height: calc(100% - 48px);
    .message-center-type-wrapper {
      padding: 0px;
      .message-center-text-input-wrapper {
        border-radius: 0;
      }
      .message-count {
        font-size: 12px;
        color: var(--thridyTextColor);
      }
    }
    .superhuman_snoozed_block_wrapper {
      margin: 0 0 5px 0;
      border-radius: 0;
    }
  }
  .messages_left_conversations_list {
    padding: 0 15px;
    .extension_email_results_block {
      background-color: var(--blueLighter);
      padding: 13px 0 13px 17px;
      border-radius: 12px;
      margin-bottom: 8px;
      border: 1px solid var(--blue);
      gap: 4px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: var(--black);
      span {
        color: var(--blue);
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .message_right_panel-drawer-extension {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}
