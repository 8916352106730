.leaderboard-page-wrapper {
  padding: 10px 20px;
  background-color: var(--newBackgroundGrey);
}

.leaderboard-list-header-left {
  .left-title {
    font-size: 20px;
    font-weight: 700;
    color: var(--textColor);
  }
}

.leaderboard-list-header-wrapper {
  justify-content: space-between;
  margin-bottom: 20px;
}

.leaderboard-list-header-right {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  .mat-btn + .mat-btn {
    margin-left: 0;
  }
}

.leaderboard-list-item {
  padding: 25px 30px;
  background-color: var(--white);
  border-radius: 9px;
  height: 100%;
  .list-item-field-header {
    display: flex;
    align-items: center;
    .item-name {
      font-size: 14px;
      font-weight: 700;
      color: var(--textColor);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .fields-wrapper {
    padding-top: 20px;
    .field-item {
      padding: 10px 0;
      &:last-child {
        padding-bottom: 0;
      }
      &-left {
        align-items: center;
        color: var(--textColor);
        display: flex;
        font-weight: 700;
        padding: 5px 0;
        width: 50%;
        svg {
          fill: var(--grey);
          margin-left: 2px;
          margin-right: 10px;
          width: 20px;
        }
      }
      &-right {
        color: var(--mainColor);
        display: flex;
        font-weight: 500;
        justify-content: flex-end;
        text-align: right;
        width: 50%;
      }
    }
  }
}

.leaderboard-date-picker {
  width: 150px;
  height: 200px;
  overflow-y: auto;
}

.leaderboard-date-picker {
  .leaderboard-date-picker-item {
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 10px 15px;
    color: var(--secondaryTextColor);
    background-color: var(--white);
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background-color: var(--mainColorLighter);
      color: var(--mainColor);
    }
  }
}

@media screen and (max-width: 479px) {
  .leaderboard-list-header-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .leaderboard-list-header-right {
    width: 100%;
    justify-content: flex-start;
    .mat-form-item {
      width: 100%;
    }
  }
}
