.aibot-template-edit-organization-page {
  .aibot-left-side {
    max-width: 374px;
    width: 100%;
    padding: 36px 36px 0;
    border-right: 0px;
    box-shadow: 2px 7px 20px rgba(44, 52, 94, 0.1);
    border-radius: 8px 8px 0 0;
    .mat-form-item-black {
      .ant-form-item-label {
        label {
          color: var(--thridyTextColor);
        }
      }
    }
    .mat-form-radio-group-secondary {
      .ant-radio-button-wrapper {
        &:hover {
          border-color: transparent;
        }
      }
    }
    .switch-row-secondary {
      border-bottom: 1px solid var(--newGrey);
      padding-bottom: 20px;
    }
    .mat-form-item {
      border-bottom: 1px solid var(--newGrey);
      padding-bottom: 20px;
    }
    .sequence_type_wrapper {
      margin-bottom: 24px;
      .sequence_type_value {
        font-size: 18px;
        font-weight: 500;
        color: var(--black);
      }
      .sequence_type_title {
        font-weight: 500;
        font-size: 12px;
        color: var(--thridyTextColor);
      }
    }
    .sequence_status_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .sequence_status_text {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: var(--black);
        text-transform: capitalize;
      }
      .switch-row {
        margin: 0px;
      }
      .mat-form-item {
        border-bottom: none;
        padding-bottom: 0px;
      }
    }
  }
  .aibot-reply-item-panel-wrapper {
    width: calc(100% - 20px);
    background-color: var(--white);
    box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
    border-radius: 8px;
    padding: 36px;
    margin-left: 26px;
    .mat-form-radio-group-secondary {
      .ant-radio-button-wrapper-checked {
        .anticon {
          path {
            + path {
              fill: transparent;
            }
          }
        }
      }
      .ant-radio-button-wrapper {
        .anticon {
          path {
            fill: transparent;
            stroke: var(--thridyTextColor);
          }
        }
        &.ant-radio-button-wrapper-checked {
          .anticon {
            path {
              fill: transparent;
              stroke: var(--white);
            }
          }
        }
      }
    }
    .mat-form-radio-group {
      .ant-radio-button-wrapper {
        height: 33px;
        border-radius: 100px;
      }
    }
  }
  .create-drip-campaign {
    grid-template-columns: 374px auto;
  }
  .aibot-template-edit-organization-page {
    .aibot-left-side {
      .mat-form-radio-group-secondary {
        .ant-radio-button-wrapper {
          height: 33px;
        }
      }
    }
  }
  .auto-reply-items-list-wrapper {
    max-width: 374px;
    width: 100%;
    padding: 0 36px 17px;
    border-right: 0px;
    background-color: var(--white);
    box-shadow: 2px 10px 20px rgba(44, 52, 94, 0.1);
    border-radius: 0 0 8px 8px;
  }
  .aibot-reply-item {
    .row-div-container {
      padding-top: 0px;
      padding-left: 0px;
      height: 78px;
    }
    .ai-bot-message {
      min-height: 17px;
    }
  }
  .ai-bot-engagement-percent-with-icon {
    margin-left: 3px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .anticon {
      margin-bottom: 18px;
    }
  }
  .ai-bot-time-unit {
    width: 100%;
    .time-unit {
      font-weight: 600;
    }
  }
  .add-aibot-reply-item {
    &.mat-btn-primary {
      margin-left: 0;
    }
  }
  .aibot-auto-message-descriptions {
    background-color: var(--newLightGrey);
    border-radius: 8px;
    .mat-input-affix-wrapper {
      width: 33px;
      height: 25px;
      border-color: var(--newGrey);
      font-size: 12px;
      padding: 0 2px;
      border-radius: 4px;
      .mat-input-affix-wrapper {
        height: 100%;
        padding-top: 2px;
        input {
          height: 100%;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
    .mat-select {
      &.ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
          min-height: 25px;
          height: 25px;
          border-color: var(--newGrey);
          .ant-select-selection-item {
            line-height: 25px;
            font-size: 12px;
            font-weight: 600;
            padding-right: 10px;
          }
        }
      }
      .ant-select-arrow {
        top: 12px;
        svg {
          width: 8px;
        }
      }
    }
  }
  .ai-bot-message-description-first-part {
    color: var(--thridyTextColor);
    strong {
      font-weight: 400;
    }
    .mat-form-item {
      padding: 0 2px;
      margin-bottom: -10px;
    }
  }
  .aibot-auto-message-descriptions-text {
    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 5px;
    }
  }
}

.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper:first-child
  .anticon
  path,
.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper:first-child
  .anticon
  ellipse {
  stroke: var(--thridyTextColor);
  fill: transparent;
}

.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper-checked:first-child
  .anticon
  path,
.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper-checked:first-child
  .anticon
  ellipse {
  stroke: var(--white);
  fill: transparent;
}

.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper:last-child
  .anticon
  path,
.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper:last-child
  .anticon
  ellipse,
.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper:last-child
  .anticon
  circle {
  stroke: var(--thridyTextColor);
  fill: transparent;
}

.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper-checked:last-child
  .anticon
  path,
.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper-checked:last-child
  .anticon
  ellipse,
.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-radio-group-secondary
  .ant-radio-button-wrapper-checked:last-child
  .anticon
  circle {
  stroke: var(--white);
  fill: transparent;
}

.aibot-template-edit-organization-page
  .aibot-left-side
  .switch-row-secondary
  .mat-form-item,
.aibot-template-edit-organization-page
  .aibot-left-side
  .mat-form-item.aibot-template-name {
  padding-bottom: 0;
  border-bottom: 0px solid transparent;
}

.aibot-template-edit-organization-page .aibot-reply-item.active,
.aibot-reply-item:hover {
  background-color: #f6f8f9;
  border-radius: 0 10px 10px 0;
}

.aibot-mat-block-label-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 0 16px !important;
  .language-switch {
    background-color: transparent;
    padding: 0;
    .ant-radio-button-wrapper {
      background-color: transparent;
      border-radius: 0;
      border-bottom: 2px solid transparent;
      height: 33px;
    }
    .ant-radio-button-wrapper-checked {
      border-bottom: 2px solid var(--mainColor);
      &:hover {
        color: var(--black);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .aibot-template-edit-organization-page {
    .create-drip-campaign {
      grid-template-columns: 50% 50%;
    }
    .aibot-reply-item-panel-wrapper {
      margin-left: 0;
      margin-top: 20px;
      width: 100%;
      border-top: 0px solid transparent;
    }
    .aibot-left-side {
      padding: 36px;
      border-radius: 8px 0 0 8px;
      max-width: 100%;
      height: 100%;
      .mat-form-item:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0px solid transparent;
      }
    }
    .auto-reply-items-list-wrapper {
      max-width: 100%;
      padding: 36px 26px 36px 36px;
      box-shadow: 2px 7px 20px rgb(44 52 94 / 10%);
      border-radius: 0 8px 8px 0;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 767px) {
  .aibot-template-edit-organization-page .aibot-left-side,
  .aibot-template-edit-organization-page .auto-reply-items-list-wrapper,
  .aibot-template-edit-organization-page .aibot-reply-item-panel-wrapper {
    padding: 20px;
  }
}

@media screen and (max-width: 479px) {
  .aibot-template-edit-organization-page {
    .auto-reply-items-list-wrapper {
      overflow: visible;
      box-shadow: 2px 10px 20px rgb(44 52 94 / 10%);
      border-radius: 0 0 8px 8px;
      border-left: 0px solid transparent;
      padding: 0 20px 20px;
    }
    .aibot-left-side {
      border-radius: 8px 8px 0 0;
      .mat-form-item {
        &:last-child {
          border-bottom: 1px solid var(--newGrey);
          padding-bottom: 20px;
        }
      }
    }
    .aibot-auto-message-descriptions {
      .mat-input-affix-wrapper {
        padding: 0;
      }
    }
  }
}
