.messages_left_panel {
    .settings_button {
        padding: 0px;
        background-color: transparent;
        min-width: 42px;
        max-width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: none!important;
        margin-right: 0;
        border: 1px solid var(--borderLineColor)!important;
        margin-left: 6px;
        &:not(:disabled):not(.mat-btn-disabled):hover {
          background-color: transparent;
        }

        .ant-btn-icon {
          display: flex;
          span {
            overflow: visible;
          }
        }
    
        .ant-badge-count {
          font-size: 8px;
          min-width: 16px;
          height: 16px;
          line-height: 16px;
          left: 0;
          -webkit-transform: translate(10%, -50%);
          transform: translate(50%, -50%);
          padding: 0;
          width: 16px;
        }
    
        &::after {
          box-shadow: none !important;
        }
    
        &:active,
        &:focus {
          border-color: transparent;
          box-shadow: none;
        }
        &:hover {
            background-color: transparent;
        }
    }
}

.message-filter-drawer {
  .ant-drawer-body {
    padding: 18px 15px;
    .filter-drawer-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--black);
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 37px;

      .clear-all-button {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        .mat-btn {
          margin-right: 12px;
        }
        svg {
          cursor: pointer;
        }
      }
    }
    .dropdown-with-selected-item {
      padding-bottom: 15px;
    }
    .mat-switch-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-top: 15px;
      .switch-label {
        padding: 0;
      }
    }
  }
}

.filter-broadcast-list-dropdown {
  .ant-select-item-option-content {
    white-space: pre;
    text-indent: -2px;
  }
}
