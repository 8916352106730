.default_block_wrapper {
  background-color: var(--white);
  padding: 24px;
  box-shadow: 2px 2px 15px 0px rgba(83, 85, 128, 0.1);
  max-width: 625px;
  border-radius: 12px;
  height: fit-content;
  &.margin_top {
    margin-top: 20px;
  }
}

.my_default_block_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--borderLineColor);
  padding: 12px 0;
  &.last {
    border-style: none;
    padding-bottom: 0;
  }
  span,
  .my_default_ellipsis_text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    + span {
      color: var(--thridyTextColor);
      text-align: end;
    }
  }
}

.my_default_block_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--borderLineColor);
  &--label {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: var(--black);
    min-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.my_default_block_title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--black);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my_default_block_subtitle {
  color: var(--textColor);
  font-size: 14px;
  font-weight: 500;
}

.default_block_wrapper_with_scroll {
  max-height: calc(var(--app-height) - 100px);
  overflow-y: auto;
  position: relative;
  .my_default_block_title {
    position: sticky;
    top: -24px;
    width: 100%;
    z-index: 9;
    background-color: var(--white);
    padding: 24px 0;
    margin-top: -24px;
  }
  &.disablePadding {
    .my_default_block_title {
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .my_default_block_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .my_default_block_header_additional_button {
    margin-top: 24px;
    width: 100% !important;
  }
}

@media screen and (max-width: 479px) {
  .default_block_wrapper {
    padding: 16px;
  }
}
