.payments-list-item {
  display: flex;
  background-color: var(--white);
  border: 1px solid var(--borderLineColor);
  border-radius: 9px;
  padding: 25px 30px;
  position: relative;
  .info-item {
    width: 100%;
    padding-right: 10px;
    p {
      font-size: 12px;
      color: var(--thridyTextColor);
      font-weight: 400;
      margin-bottom: 9px;
    }
    .with-icon {
      display: flex;
      align-items: center;
      margin-top: -3px;
      span {
        font-size: 14px;
        font-weight: 700;
      }
      .anticon {
        margin-right: 5px;
      }
    }
    h5 {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      word-break: break-word;
    }
  }
  .line {
    height: 100%;
    width: 3px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .modal-btn {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

.payment-page-header-right-content {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .row-div-container {
    width: 70%;
    position: relative;
    top: 27px;
    z-index: 1;
    > * {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
  .inputWithSearch {
    background-color: var(--white) !important;
    height: 42px;
    border: 1px solid var(--newGrey);
    border-radius: 8px;
    .ant-input-affix-wrapper {
      background-color: var(--white);
      height: 40px;
      border-radius: 8px;
    }
  }
  .mat-select-wrapper {
    .mat-select-sub-wrapper {
      height: 42px;
      border: 1px solid var(--newGrey);
      border-radius: 8px;
    }
  }
  .mat-form-item {
    width: 20px;
    height: 20px;
    cursor: pointer;
    .mat-date-picker {
      height: 100%;
      padding: 0;
      border-style: none;
      border-color: transparent;
      input {
        display: none;
      }
      .ant-picker-suffix {
        margin: 0;
      }
      .ant-picker-input {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.payment-wrapper-item-transaction {
  margin-bottom: 20px;
  .payments-list-item {
    + .payments-list-item {
      margin-top: 10px;
    }
    .info-item {
      h4 {
        font-size: 16px;
        font-weight: bold;
        color: var(--red);
        position: absolute;
        top: -25px;
      }
    }
  }
}

.payment-wrapper-item-transaction-title {
  font-size: 16px;
  margin-bottom: 3px;
}

.pay-info-item {
  cursor: pointer;
  display: flex;
}

.payment-modal-item-wrapper {
  .mat-timeline {
    padding-top: 10px;
    .ant-timeline-item-last {
      padding-bottom: 0;
    }
    .ant-timeline-item-head {
      background-color: transparent;
      width: auto;
      height: auto;
      border-style: none;
      inset-block-start: 11px;
      &::after {
        content: '';
        width: 9px;
        height: 5px;
        position: absolute;
        border: 2px solid #fcfff4;
        border-top: none;
        border-right: none;
        background: transparent;
        -webkit-transform: rotate(-45deg) translateY(-50%) translateX(-50%);
        transform: rotate(-45deg) translateY(-50%) translateX(-50%);
        border-radius: 0;
        top: 34%;
        left: 51%;
      }
      .green {
        background-color: #29cc97;
        border-color: #29cc97;
      }
      .red {
        background-color: #ff5b72;
        border-color: #ff5b72;
      }
      .blue {
        background-color: #1890ff;
        border-color: #1890ff;
      }
      .yellow {
        background-color: #ffde5a;
        border-color: #ffde5a;
      }
      .black {
        background-color: #474657;
        border-color: #474657;
      }
    }
    .ant-timeline-item-tail {
      left: 4px;
    }
    .ant-timeline-item-content {
      top: -1.001px;
      margin: 0 0 0 30px;
    }
  }
}

.payment-page-wrapper {
  padding: 20px 32px 75px 31px;
  background-color: var(--lighter);
  border-radius: 0;
  height: 100%;
  margin-bottom: 0;
}

.payment-page-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: var(--textColor);
  }
}

.payment-page-list-header-with-icon {
  display: flex;
  align-items: center;
  .anticon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 6px;
  }
}

.payment-page-summary-item-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.payment-page-summary-item {
  box-shadow: 2px 2px 40px rgba(83, 85, 128, 0.1);
  border-radius: 8px;
  background-color: var(--white);
  padding: 22px;
  display: flex;
  justify-content: space-between;
  .amount {
    color: var(--black);
    font-size: 32px;
    line-height: 140%;
    font-weight: 500;
  }
}

.summary-item-header {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  p {
    color: var(--thridyTextColor);
    font-size: 16px;
    line-height: 140%;
    width: 115px;
    margin-left: 10px;
  }
}

.summary-date {
  background: #3f8cff;
  border-radius: 20px;
  width: 93px;
  height: 29px;
  padding: 6px 8px;
  font-size: 12px;
  line-height: 19px;
  color: var(--white);
  text-align: center;
}

.payment-page-tab {
  .ant-tabs-tab {
    font-size: 16px;
    line-height: 19px;
    color: var(--thridyTextColor);
    font-weight: 400;
    padding: 0 0 8px 0;
    + .ant-tabs-tab {
      margin: 0 0 0 36px;
    }
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--black);
      }
    }
  }
  > .ant-tabs-nav {
    &::before {
      border-bottom: 0 solid transparent;
    }
  }
  .ant-tabs-ink-bar {
    background-color: var(--mainColor);
    border-radius: 100px;
  }
  .mat-table-wrapper {
    .ant-table-tbody {
      .ant-table-row {
        position: relative;
      }
      .ant-table-selection-column {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        opacity: 0;
        .ant-radio-wrapper {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .ant-table-tbody {
    > tr.ant-table-row-selected {
      > td {
        background-color: transparent;
      }
    }
  }
}

.mat-table-wrapper {
  .ant-table-tbody {
    .ant-table-cell {
      .with-icon {
        display: flex;
        align-items: center;
        .anticon {
          margin-right: 7px;
        }
        &:after {
          content: '';
          width: 2px;
          height: 100%;
          position: absolute;
          left: 0;
        }
        &.pending:after {
          background-color: #f6a55b;
        }
        &.canceled:after {
          background-color: #474657;
        }
        &.refunded:after {
          background-color: #7854f6;
        }
        &.paid:after {
          background-color: #48af80;
        }
        &.rejected:after {
          background-color: #ee7b6c;
        }
      }
    }
  }
}

.payment-amount {
  color: #0049c6;
  display: flex;
  align-items: center;
  .anticon {
    margin-right: 8px;
    margin-bottom: 1px;
  }
}

.payment-grey-item {
  color: #808191;
  font-weight: 400;
}

.payment-total-amount {
  color: var(--red);
  font-weight: bold;
  text {
    font-size: 14px;
  }
}

.payment-page-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .mat-btn {
    + .mat-btn {
      margin-left: 0;
    }
  }
}

.payment-page-subscription-icon {
  cursor: pointer;
  display: flex;
  border-radius: 50%;
  &:hover {
    background-color: var(--mainColorLighter);
  }
}

.white-space-nowrap-item {
  white-space: nowrap;
}

.payment-download-daily-report {
  display: flex;
  align-items: center;
  background-color: var(--white);
  height: 42px;
  border: 1px solid var(--newGrey);
  border-radius: 8px;
  width: 80%;
  padding: 0 10px;
  .text {
    line-height: 14px;
    padding-left: 8px;
    cursor: pointer;
    font-size: 12px;
  }
}

.payment-deposits-tab {
  padding-top: 42px;
}

.payment-page-header-right-content-reports {
  max-width: 340px;
}

.reports-table {
  .ant-table-cell {
    .ant-dropdown-trigger {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 1200px) {
  .payment-page-summary-item {
    flex-direction: column;
  }

  .summary-date-wrapper {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 991px) {
  .payment-page-summary-item-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .payment-page-wrapper {
    padding: 20px;
  }

  .payment-page-tab {
    > .ant-tabs-nav {
      margin: 0 0 70px 0;
    }
  }
  .payment-page-header-right-content {
    .row-div-container {
      top: 83px;
      width: 100%;
    }
    .payment-download-daily-report {
      margin-left: 0;
    }
    .payment-page-header-right-content-reports {
      top: 27px;
    }
  }

  .payment-reports-tab > .ant-tabs-nav,
  .payment-deposits-tab > .ant-tabs-nav {
    margin: 0 0 16px 0;
  }
}

@media screen and (max-width: 767px) {
  .payment-page-header-right-content {
    .row-div-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      top: 63px;
    }
    .mat-select-wrapper {
      &:nth-child(3) {
        margin-left: 0;
        margin-top: 10px;
      }
      &:nth-child(4) {
        margin-top: 10px;
      }
    }
    .payment-page-header-right-content-reports {
      top: 58px;
      max-width: 100%;
      display: flex;
      top: 7px;
      .mat-select-wrapper {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .payment-download-daily-report,
  .payment-page-header-right-content .inputWithSearch,
  .payment-page-header-right-content .mat-select-wrapper {
    width: 100%;
  }

  .payment-page-tab {
    margin-top: -80px;
    > .ant-tabs-nav {
      margin: 0 0 130px 0;
    }
  }

  .payment-reports-tab > .ant-tabs-nav,
  .payment-deposits-tab > .ant-tabs-nav {
    margin: 0 0 16px 0;
  }

  .payment-deposits-tab,
  .payment-reports-tab {
    margin-top: -28px;
  }

  .payment-reports-tab {
    > .ant-tabs-nav {
      margin: 0px 0 80px 0;
    }
  }
}

@media screen and (max-width: 479px) {
  .payment-page-summary-item-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .payment-page-summary-item {
    flex-direction: row;
  }

  .payment-page-header-right-content {
    .inputWithSearch {
      .ant-input-affix-wrapper {
        padding: 10px;
      }
    }
    .payment-page-header-right-content-reports {
      top: 65px;
      .mat-select-wrapper {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  .payment-collapse {
    background-color: transparent;
    > .ant-collapse-item {
      border-bottom: 0px solid transparent;
      overflow: hidden;
      position: relative;
      > .ant-collapse-header {
        padding: 16px;
      }
    }
    .ant-collapse-item {
      background-color: var(--white);
      border-radius: 8px;
    }
    .ant-collapse-header-text {
      padding-right: 25px;
    }
    .ant-collapse-item:has(.with-icon)::after {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 3px;
      top: 0;
    }
    .ant-collapse-item:has(.with-icon.pending)::after {
      background-color: #f6a55b;
    }
    .ant-collapse-item:has(.with-icon.canceled)::after {
      background-color: #474657;
    }
    .ant-collapse-item:has(.with-icon.refunded)::after {
      background-color: #7854f6;
    }
    .ant-collapse-item:has(.with-icon.paid)::after {
      background-color: #48af80;
    }
    .ant-collapse-item:has(.with-icon.rejected)::after {
      background-color: #ee7b6c;
    }
    .ant-collapse-content-box {
      display: flex;
      flex-direction: column;
      .ant-space-align-center {
        justify-content: space-between;
        margin-top: 12px;
        .ant-space-item {
          &:first-child {
            color: var(--thridyTextColor);
          }
          &:last-child {
            color: var(--black);
          }
          .with-icon {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            .anticon {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }

  .payment-panel-header-top,
  .payment-panel-header-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .payment-panel-header-top {
    .title {
      font-size: 16px;
    }
    .with-icon {
      display: flex;
      align-items: center;
      .anticon {
        margin-right: 6px;
      }
    }
  }

  .payment-panel-header-bottom {
    .payment-grey-item {
      font-size: 12px;
    }
  }

  .payment-page-tab {
    margin-top: -70px;
    .list-container {
      margin-top: 0;
      .list-item {
        overflow: hidden;
        border-radius: 8px;
        + .list-item {
          margin-top: 12px;
        }
        .ant-collapse-item {
          border-radius: 8px;
          + .ant-collapse-item {
            margin-top: 12px;
          }
        }
      }
    }
    > .ant-tabs-nav {
      margin: 0 0 120px 0;
    }
  }

  .collapse-item-buttons-wrapper {
    .ant-space-item {
      width: 100%;
      &:last-child {
        width: auto;
      }
      .mat-btn {
        width: 100%;
      }
    }
  }

  .payment-deposits-tab,
  .payment-reports-tab {
    margin-top: -18px;
  }

  .payment-reports-tab {
    > .ant-tabs-nav {
      margin: 0px 0 70px 0;
    }
  }

  .payment-deposits-tab {
    > .ant-tabs-nav {
      margin: 0px 0 16px 0;
    }
  }
}
