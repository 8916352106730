.dashboard_marketing_touchpoint {
    border: 1px solid var(--mainColor);
    border-radius: 100px;
    width: 186px;
    padding: 10px 24px;
    height: 50px;
    max-width: 186px;
    background-color: rgba(7, 1, 33, 0.20);
    &--inner {
        letter-spacing: -.32px;
        line-height: normal;
        text-align: left;
        font-weight: 600!important;
        font-size: 16px!important;
        color: var(--white);
    }
    &.dark {
        background-color: rgba(91, 102, 234, 0.20);
        padding: 10px 16px;
    }
}

@media screen and (max-width: 767px) {
    .dashboard_marketing_touchpoint {
        max-width: 150px;
        &--inner {
            width: 100%;
            font-size: 14px!important;
        }
    }
}