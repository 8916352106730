

.mat-time-picker {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border-color: var(--grey);
  padding: 0 17px;
  &.ant-picker-focused {
    box-shadow: none;
    border-color: var(--mainColor);
    background-color: var(--mainColorLighter);
  }
  &.ant-picker {
    &.ant-picker-disabled {
      border-color: #d9d9d9;
    }
  }
  input {
    letter-spacing: 0.58px;
    color: var(--black);
    font-size: 14px;
    &::placeholder {
      color: var(--thridyTextColor);
    }
  }
  .ant-picker-clear {
    background-color: var(--white);
    border-radius: 50%;
  }
}

.mat-time-picker.ant-picker-focused:hover,
.mat-time-picker:hover {
  border-color: var(--mainColor);
}

.mat-time-picker-dropdown {
  .ant-picker-panel-container {
    box-shadow: none;
    background-color: transparent;
    border-style: none;
  }
  .ant-picker-footer-extra {
    padding: 0;
  }
  .mat-picker-custom-drop {
    padding: 0;
    border: 1px solid var(--grey);
    border-radius: 8px;
    box-shadow: 4px 7px 40px rgba(0, 0, 0, 0.05);
    min-height: 58px;
    max-height: 300px;
    overflow-y: auto;
    background-color: var(--white);
    width: 170px;
    .time-item {
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      width: 100%;
      padding: 10px 53px;
      color: var(--black);
      background-color: var(--white);
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      white-space: nowrap;
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &:hover {
        background-color: var(--mainColorLighter);
      }
      &.disabled {
        opacity: 0.5;
        background-color: rgba(163, 166, 190, 0.1);
        &:hover {
          background-color: rgba(163, 166, 190, 0.1);
        }
      }
    }
  }
  .ant-picker-ranges {
    display: none;
  }
  .ant-picker-time-panel {
    display: none;
  }
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: rgba(163, 166, 190, 0.1);
}

.ant-picker-ok {
  width: 100%;
}

.ant-picker-panel {
  border: none;
}

.ant-picker-footer-extra:not(:last-child) {
  border-bottom: none;
}
