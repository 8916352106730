

.mat-form-radio-group {
  display: flex;
  justify-content: space-around;
  .ant-radio-button-wrapper {
    border: 1px solid var(--mainColor);
    border-radius: 100px;
    color: var(--mainColor);
    border-color: var(--mainColor);
    width: 48%;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    &.ant-radio-button-wrapper-disabled {
      opacity: 0.5;
    }
    &:first-child {
      border-color: var(--mainColor);
    }
    &:focus-within {
      box-shadow: none;
    }
    &:not(:first-child)::before {
      content: none;
    }
    svg {
      margin-right: 5px;
    }
    span {
      vertical-align: middle;
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: var(--mainColor);
    border-color: var(--mainColor);
    border-right-color: var(--mainColor);
    color: var(--white);
    &:not(
        [class*=' ant-radio-button-wrapper-disabled']
      ).ant-radio-button-wrapper:first-child {
      border-right-color: var(--mainColor);
    }
    &:hover {
      color: var(--white);
      border-color: var(--mainColor);
      border-right-color: var(--mainColor);
    }
  }
}

.circle-radio-button {
  .outside-label {
    font-size: 12px;
    color: var(--thridyTextColor);
    position: relative;
    bottom: 2px;
    font-weight: 400;
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    box-shadow: none;
    background-color: var(--radio-inner-bg-color);
    border-color: var(--radio-inner-bg-color);
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: var(--radio-inner-bg-color);
      border-color: var(--radio-inner-bg-color);
      &:after {
        width: 9px;
        height: 5px;
        position: absolute;
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        background: transparent;
        transform: rotate(-45deg) translateY(-50%) translateX(-50%);
        border-radius: 0;
        top: 21%;
        left: 51%;
        margin: 0;
      }
    }
  }
  &:hover {
    .ant-radio-inner {
      border-color: var(--mainColor);
    }
  }
}

.mat-form-radio-group-secondary {
  .ant-radio-button-wrapper {
    border: 1px solid transparent;
    border-radius: 100px;
    width: 48%;
    background-color: #edf4fe;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    &:first-child {
      border-color: transparent;
    }
    span {
      font-size: 14px;
      font-weight: 600;
      color: var(--thridyTextColor);
    }
    .anticon {
      path {
        fill: var(--thridyTextColor);
      }
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: #3f8cff;
    span {
      color: var(--white);
    }
    .anticon {
      path {
        fill: var(--white);
      }
    }
  }
}
