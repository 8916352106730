.my_default_radio_button {
  display: flex;
  gap: 12px;
  align-items: center;
  .ant-radio-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--mainColor);
    padding: 10px 18px;
    height: 40px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-size: 14px;
      font-weight: 500;
      color: var(--mainColor);
    }
    &:hover {
      color: var(--mainColor);
    }
    &-checked {
      background-color: var(--mainColor);
      border: 1px solid transparent !important;
      &:hover {
        color: var(--white);
      }
      span {
        color: var(--white);
      }
    }

    &::before {
      display: none;
    }
  }
}
