.dashboard_block {
  &.voice_ai_empty_state {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 80px 24px;
    .ant-card-body {
      padding: 0px;
      align-items: center;
    }
    .title {
      font-size: 48px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 16px;
      color: var(--white);
      line-height: 38px;
      span {
        color: var(--mainColor);
      }
    }
    .subtitle {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 32px;
      color: var(--thridyTextColor);
    }
    .description {
      font-size: 24px;
      font-weight: 500;
      color: var(--white);
      text-align: center;
      margin-bottom: 24px;
    }
    .block_items_container {
      padding: 24px;
      gap: 20px;
      display: grid;
      margin-bottom: 24px;
      grid-template-columns: repeat(2, 1fr);
      .item {
        border: 1px solid #e5e9f212;
        border-radius: 47px;
        padding: 8px 12px;
        gap: 12px;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard_block {
    &.voice_ai_empty_state {
      padding: 40px 12px;
      .block_items_container {
        grid-template-columns: 1fr;
      }
    }
  }
}
