/* List */
// .organization-list-wrapper {
//   height: auto;
//   padding: 0px 20px 20px 12px;
//   background-color: var(--newBackgroundGrey);
//   .organization-list-header {
//     margin-bottom: 10px;
//     margin-top: 10px;
//     .mat-select-wrapper {
//       width: 160px;
//     }
//     .inputWithSearch {
//       max-width: 400px;
//     }
//   }
// }

.organization-item {
  // padding: 25px 30px;
  // border-radius: 9px;
  // background-color: var(--white);
  .organization-switch-with-icon-and-text {
    .text-with-icon {
      &.left-icon {
        .icon {
          width: 24px;
          height: 24px;
          overflow: hidden;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--white);
          box-shadow: 0 2px 6px rgb(26 29 136 / 26%);
          margin-right: 10px;
          &.active {
            background-color: var(--mainColorLighter);
            box-shadow: none;
          }
        }
        .title {
          &.active {
            color: var(--mainColor);
            cursor: auto;
          }
        }
      }
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .list-item-field-header {
    display: flex;
    padding-bottom: 15px;
    .item-name {
      font-size: 14px;
      font-weight: 700;
      color: var(--textColor);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 0;
    }
    .created-date {
      margin-top: 1px;
      font-size: 12px;
      color: var(--secondaryTextColor);
    }
    .edit-icon {
      cursor: pointer;
    }
  }
  .mat-switch-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .organization-item-switch {
    margin-bottom: 0;
    border-bottom: 0px solid transparent;
  }
}

.organization-wrapper {
  .grid-item {
    background-color: var(--white);
  }
}

// @media screen and (max-width: 1190px) {
//   .organization-list-wrapper {
//     padding: 0 20px 20px 20px;
//   }
// }

// @media screen and (max-width: 767px) {
//   .organization-list-wrapper {
//     .organization-list-header {
//       flex-direction: column;
//       .mat-select-wrapper {
//         margin-left: 20px;
//         width: 100%;
//       }
//       .inputWithSearch {
//         margin-top: 10px;
//         width: 100%;
//       }
//     }
//     .row-div-container {
//       > * {
//         margin-right: 0;
//       }
//     }
//   }
// }
