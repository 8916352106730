.dashboard_block_purple {
  background: linear-gradient(111deg, #5360f8, #5b16b3);
  &.underline_animation {
    &:hover {
      box-shadow: 0px 0px 54.7px 0px rgba(211, 161, 254, 0.48);
    }
  }
  .ant-typography {
    font-size: 44px;
    margin-top: 30px;
    width: fit-content;
  }
  .tree_icon {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.dashboard_assisted_appointment {
  background: linear-gradient(121deg, #f57e4b -17.99%, #e41987 141.74%);
  border: 1px solid transparent;
  &.underline_animation {
    &:hover {
      box-shadow: 0px 0px 26.7px 0px rgba(239, 95, 93, 0.85);
    }
  }
  .ant-typography {
    font-size: 44px;
    margin-top: 50px;
    width: fit-content;
    line-height: 50px;
  }
  .calendar_icon {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.dashboard_block_purple_billed_minutes {
  .ant-typography {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) {
  .dashboard_assisted_appointment {
    .ant-typography {
      margin-top: 20px;
    }
    .calendar_icon {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
  .dashboard_block_purple_billed_minutes {
    .ant-typography {
      margin-top: 0px;
    }
  }
}
