

.spin-loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
}

.anticon-loading {
  color: var(--mainColor);
}
