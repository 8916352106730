.message_page_center_panel_header {
  border-bottom: 1px solid var(--borderLineColor);
  padding: 20px 24px;
  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .show_right_panel {
      margin-left: 7px;
    }
    &--left {
      display: flex;
      align-items: center;
      width: fit-content;
      &-name-block {
        display: flex;
        align-items: center;
        .name {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: var(--black);
        }
      }
      .anticon {
        cursor: pointer;
      }
      .customer_contact--icon {
        display: flex;
        align-items: center;
        .anticon {
          cursor: default;
        }
      }
      .drawer_close_icon {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    &--right {
      display: flex;
      flex-direction: row;
      width: fit-content;
      .mat-btn {
        display: flex;
        align-items: center;
        border-radius: 60px !important;
        height: 32px;
        padding: 2px 11px;
        background-color: var(--mainColor);
        font-size: 12px;
        color: var(--white);
        & + .mat-btn {
          margin-left: 12px;
        }
      }
    }
    .ai_summary_button {
      background-color: var(--white);
      color: var(--mainColor);
      min-width: 32px;
    }
  }
}

.message_center_panel-drawer {
  .ant-drawer-body {
    .message_page_center_panel_header {
      &--right {
        align-items: center;
      }
      &--left {
        position: relative;
        &-name-block {
          font-size: 16px;
          margin-left: 7px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .message_page_center_panel_header {
    &--header {
      flex-direction: column;
      align-items: flex-start;
      &--left {
        width: 100%;
        &-name-block {
          justify-content: space-between;
          width: 100%;
        }
      }
      &--right {
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;
        .mat-btn + .mat-btn {
          margin-left: 4px;
        }
        .crm_items_block {
          margin-left: 0;
          .mat-btn {
            background-color: transparent;
            color: var(--mainColor);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .message_page_center_panel_header {
    padding: 16px 10px;
    &--header {
      &--left {
        align-items: flex-start;
        .drawer_close_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 58px;
          background-color: var(--newLightGrey);
          border-radius: 80px;
          width: 30px;
          border: 1px solid var(--newGrey);
        }
        &-name-block {
          align-items: flex-start;
          .customer_contact {
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--secondaryTextColor);
            margin-top: 2px;
            white-space: nowrap;
            width: 100%;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            display: flex;
            align-items: center;
            gap: 4px;
          }
          .name {
            .customer_contact {
              font-size: 14px;
              font-weight: 500;
              color: var(--black);
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
