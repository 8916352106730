.message_center_panel {
  .conversation-archived-note-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
  .message-center-type-wrapper {
    padding: 5px 15px 10px 15px;
    .message-center-text-input-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: auto;
      border: 1px solid var(--borderLineColor);
      border-radius: 10px;
      position: relative;
      .message-center-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        border-top: 1px solid var(--borderLineColor);
        padding: 3px 5px;
        height: 100%;
        height: 48px;
        .row-div-container {
          width: fit-content;

          .form-item {
            margin-right: 4px;
          }
        }
        .message_type_select_wrapper {
          margin-right: 4px;
        }
        .modal-btn-active {
          .icon-size {
            background-color: var(--lighter) !important;

            .anticon {
              svg {
                path {
                  fill: var(--mainColor);
                }
              }
            }
            &.only-stroke {
              .anticon {
                svg {
                  path {
                    fill: transparent;
                    stroke: var(--mainColor);
                  }
                  circle,
                  ellipse,
                  rect {
                    stroke: var(--mainColor);
                  }
                }
              }
            }
          }
          .send-invitation-message {
            .anticon {
              background-color: var(--lighter);

              svg {
                path {
                  fill: var(--mainColor);
                }
              }
            }
          }
        }
        .modal-btn {
          margin-right: 4px;
          .icon-size {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}

.icon-size,
.mat-btn.icon-size {
  min-width: 28px;
  max-width: 28px;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  line-height: normal;
  padding: 7px;
  border-radius: 9px;
  margin-right: 4px !important;

  .anticon-spin {
    color: var(--thridyTextColor);
  }

  &:hover {
    background-color: var(--lighter) !important;

    .anticon {
      svg {
        path {
          fill: var(--mainColor);
        }
      }
    }
    &.only-stroke {
      .anticon {
        svg {
          path {
            fill: transparent;
            stroke: var(--mainColor);
          }
          circle,
          ellipse,
          rect {
            stroke: var(--mainColor);
          }
        }
      }
    }
  }

  .anticon {
    display: flex;
  }

  &[disabled],
  &.disabled {
    opacity: 0.5;
    .anticon {
      svg {
        path {
          stroke: var(--secondaryTextColor);
        }
        rect {
          stroke: var(--secondaryTextColor);
        }
      }
    }
    &:hover {
      background-color: transparent;
      .anticon {
        svg {
          path {
            fill: transparent;
          }
        }
      }
      &.only-stroke {
        .anticon {
          svg {
            path,
            circle,
            ellipse,
            rect {
              stroke: var(--secondaryTextColor);
            }
          }
        }
      }
      .icon_size_reminder {
        svg {
          path {
            fill: var(--secondaryTextColor);
          }
        }
      }
    }
    .icon_size_reminder {
      svg {
        path {
          stroke: transparent;
          fill: var(--secondaryTextColor);
        }
      }
    }
    &.anticon {
      svg {
        path {
          stroke: var(--secondaryTextColor);
        }
        rect {
          stroke: var(--secondaryTextColor);
        }
      }
    }
  }

  &.active,
  &.ant-popover-open {
    background-color: var(--lighter) !important;

    .anticon {
      svg {
        path {
          fill: var(--mainColor);
        }
      }
    }
    &.only-stroke {
      .anticon {
        svg {
          path {
            fill: transparent;
            stroke: var(--mainColor);
          }
          circle,
          ellipse {
            stroke: var(--mainColor);
          }
        }
      }
    }
  }

  &.active-scheduled-message {
    background-color: var(--green) !important;
    .anticon {
      svg {
        path {
          fill: var(--white) !important;
        }
      }
    }

    &[disabled] {
      background-color: var(--mainColorLighter) !important;
      .anticon {
        svg {
          path {
            fill: var(--secondaryTextColor) !important;
          }
        }
      }
    }
    &:hover {
      .anticon {
        svg {
          path {
            fill: var(--white);
          }
        }
      }
    }
  }
  + .mat-btn {
    margin-left: 0;
  }
}

.message_footer_small_ui_button {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(63, 140, 255, 0.1);
  margin-right: 4px !important;
  cursor: pointer;
  &.ant-popover-open {
    .anticon {
      rotate: 45deg;
    }
  }
}

.message-footer-small-ui {
  .ant-popover-inner {
    border-radius: 8px;
  }
  .ant-popover-inner-content {
    display: flex;
    align-items: center;
    .anticon {
      svg {
        min-width: 20px;
        min-height: 20px;
      }
    }
    .mat-template-select:not(.ant-select-customize-input):last-child {
      margin-right: 0 !important;
    }
  }
}

@media screen and (max-width: 479px) {
  .message_center_panel-drawer {
    .message_center_panel {
      .message-center-type-wrapper {
        padding: 0;
        .message-center-text-input-wrapper {
          border-radius: 0;
          .message-center-button-wrapper {
            .message-count {
              font-size: 12px;
              color: var(--thridyTextColor);
            }
          }
        }
      }
    }
  }

  .message_center_panel-drawer-for-mobile {
    .message-center-type-wrapper {
      padding: 0;
      .message-center-text-input-wrapper {
        border-style: none;
        border-top: 1px solid var(--borderLineColor);
        border-radius: 0;
      }
    }
  }
}
