.my_default_draggable_wrapper {
  .my_default_draggable_item {
    border-radius: 8px;
    padding: 12px 16px;
    border: 1px solid var(--newGrey);
    background-color: var(--newBackgroundGrey);
    & + .my_default_draggable_item {
      margin-top: 5px;
    }
  }
}
