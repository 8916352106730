

.mat-error-box {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--white);
  .error-image-wrapper {
    padding: 0 10px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .error-box-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 0 10px;
    h2 {
      margin: 0;
      font-size: 36px;
      color: var(--black);
    }
    h4 {
      margin: 0;
      font-size: 18px;
      color: var(--secondaryTextColor);
      font-weight: 400;
      max-width: 380px;
      text-align: center;
      margin-top: 15px;
      line-height: 28px;
    }
  }
  .message {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-size: 14px;
    color: var(--secondaryTextColor);
  }
  .actions {
    display: flex;
    width: 240px;
    margin: 0 auto;
    justify-content: center;
    margin-top: 20px;
  }
}
