.settings-transfer-wrapper {
  .settings-transfer {
    margin-left: 0px;
    max-width: 300px;
  }
}

.my-page-full {
  .side-menu-closedV2 {
    .settings-transfer {
      margin-left: 0px;
    }
  }
}
.confirm-alert-wrapper {
  width: 420px;
  .ant-modal-confirm-paragraph {
    align-items: center;
  }
  .ant-modal-confirm-btns {
    justify-content: space-between;
    gap: 6px;
  }
  .mat-btn {
    &.mat-btn-white {
      color: var(--black);
      border-color: var(--black);
      width: 100%;
    }
    &.mat-btn-primary {
      width: 100%;
    }
  }
  .ant-modal-confirm-content {
    text-align: center;
    padding: 0px 20px;
    font-size: 16px;
    font-weight: 400;
    color: var(--secondaryTextColorNew);
  }
}
