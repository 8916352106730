.my_default_page_header {
  margin-bottom: 20px;
  justify-content: space-between;
  &--left,
  &--right {
    width: 50%;
    gap: 12px;
  }
}

.my_default_page_header_advanced {
  margin-bottom: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  &--left,
  &--right {
    width: fit-content;
    gap: 12px;
    flex-wrap: wrap;
    .my_default_page_header_item {
      flex: 1;
      max-width: 100%;
    }
    &:empty {
      display: flex;
    }
  }
}

@media screen and (max-width: 1090px) {
  .my_default_page_header_advanced {
    flex-direction: column;
    &--left,
    &--right {
      flex: 1;
      width: 100%;
      &:empty {
        display: none;
      }
      .my_default_drawer_button {
        min-width: 48px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .my_default_page_header {
    flex-direction: column;
    gap: 12px;
    &--left,
    &--right {
      width: 100%;
    }
    &--right {
      .my_default_button {
        width: 100%;
      }
    }
    &--left {
      justify-content: space-between;
      .my_default_input_search {
        max-width: 100% !important;
      }
      .my_default_button {
        min-width: 48px;
      }
    }
  }
}
