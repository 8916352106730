.my_default_dropdown_button {
  width: fit-content;
  border-style: none;
  .ant-btn-primary {
    background-color: var(--mainColor);
    font-size: 16px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-shadow: none;
    border-style: none;
    width: fit-content;
    height: 44px;
    border-radius: 8px;
    box-shadow: none;
    padding: 0 15px;
    line-height: 24px;
    display: flex;
    &:hover {
      background-color: var(--mainColor) !important;
      + .ant-btn-primary {
        &::before {
          z-index: 2;
        }
      }
    }
    + .ant-btn-primary {
      &::before {
        background-color: #dee0fa !important;
        height: calc(100% - 24px) !important;
        top: auto !important;
        inset-inline-start: 0px !important;
      }
    }
    .ant-btn-icon {
      display: flex;
    }
    &:first-child {
      width: 100%;
    }
  }
}

.my_default_dropdown_button_dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      border-radius: 8px;
      padding: 8px;
      .ant-dropdown-menu-title-content {
        color: var(--thridyTextColor);
        font-size: 14px;
        font-weight: 500;
      }
    }
    .ant-dropdown-menu-item-active {
      background-color: var(--mainColorLighter) !important;
      .ant-dropdown-menu-title-content {
        color: var(--mainColor);
      }
    }
  }
}
