.my_default_send_follow_up {
  padding: 12px 20px;
  border-radius: 12px;
  background-color: var(--mainColorLighter);
  &--text {
    font-size: 14px;
    font-weight: 400;
    color: var(--black);
  }
  .my_default_form_item {
    margin-bottom: 0;
  }
  .my_default_input_number {
    width: 42px;
    padding: 0 2px;
    text-align: center;
  }
  .my_default_select {
    min-width: 100px;
    .ant-select-arrow {
      inset-inline-end: 15px;
    }
  }
  .ant-form-item-explain-error {
    display: none;
  }
}
