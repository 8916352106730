.my_default_radio_button {
  &.circle {
    .ant-radio-wrapper {
      &.ant-radio-wrapper-checked {
        .ant-radio {
          .ant-radio-inner {
            background-color: var(--white);
            &::after {
              width: 20px;
              height: 20px;
              margin-block-start: -10px;
              margin-inline-start: -10px;
            }
          }
        }
        &:hover {
          .ant-radio {
            .ant-radio-inner {
              border-color: var(--mainColor) !important;
            }
          }
        }
      }
      .ant-radio {
        .ant-radio-inner {
          border-width: 2px;
          background-color: var(--newBackgroundGrey);
          border-color: var(--newGrey);
        }
      }
      &:hover {
        .ant-radio {
          .ant-radio-inner {
            border-color: var(--newGrey) !important;
          }
        }
      }
    }
  }
}
