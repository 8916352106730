.dashboard_block_review_invites_source {
    .ant-card-body {
        gap: 20px;
        justify-content: flex-start;
    }
    .dashboard_block--header {
        margin-bottom: 0!important;
        &--right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                width: auto;
            }
            span + span {
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;  
            }
        }
    }
    .slider_with_tooltip {
        width: 100%;
    }
    .source_tooltip {
        display: flex;
    }
    .ant-slider {
        width: 100%;
        margin: 0;
        cursor: default;
        height: 16px;
        .ant-slider-rail {
            height: 8px;
        }
        .ant-slider-step {
            height: 8px;
            background-color: rgba(255, 255, 255, 0.12);
            border-radius: 100px;
        }
        .ant-slider-track {
            height: 8px;
            border-radius: 100px;
        }
        .ant-slider-handle {
            inset-block-start: 3px;
            &::after {
                cursor: default;
            }
            &:hover {
                &::after {
                    outline: none;
                }
            }
        }
        &.slider_blue_green_gradient {
            .ant-slider-track {
                background: linear-gradient(to right, #38CE60 0%, #3C7EFF 100%);
            }
            .ant-slider-handle {
                &::after {
                    box-shadow: 0 0 0 2px #6CA5FF;
                    background-color: #3C7EFF;
                }
            }
        }
        &.slider_purple_blue_gradient {
            .ant-slider-track {
                background: linear-gradient(to right, #7A4BFF 0%, #097AEA 100%);
            }
            .ant-slider-handle {
                &::after {
                    box-shadow: 0 0 0 2px #54A1FF;
                    background-color: #097AEA;
                }
            }
        }
        &.slider_yellow_green_gradient {
            .ant-slider-track {
                background: linear-gradient(to right, #FFF84B 0%, #39C057 100%);
            }
            .ant-slider-handle { 
                &::after {
                    box-shadow: 0 0 0 2px #6CE67F;
                    background-color: #39C057;
                }
            }
        }
    }
    .dashboard_block--review_source {
        &-text {
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            &:last-child {
                opacity: 0.6;
                width: 50px;
                text-align: end;
            }
        }
    }
}

@media screen and (max-width: 1090px) {
    .dashboard_block_review_invites_source {
        grid-column: 1 / 3;
    }
}