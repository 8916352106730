.message_page_center_panel_header {
  &--header {
    &--right {
        .mat-btn {
            &.archive_button {
                background-color: var(--red);
                margin-left: 12px;
      
                &:hover {
                  background-color: var(--red);
                }
      
                .anticon {
                  display: flex;
                  svg {
                    fill: var(--white);
                  }
                }
      
                &:disabled {
                  opacity: 0.5;
                }
            }
        }
    }
  }
}

@media screen and (max-width: 991px) {
  .message_page_center_panel_header {
    &--header {
      &--right {
          .mat-btn {
            &.archive_button {
              margin-left: 4px;
            }
          }
      }
    }
  }
}