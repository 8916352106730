.invitation-list-wrapper {
  padding: 10px 20px;
  background-color: var(--newBackgroundGrey);
}

.invitation-list-header-left {
  .left-title {
    font-size: 20px;
    font-weight: 700;
    color: var(--textColor);
  }
  .left-desc {
    color: var(--secondaryTextColor);
    font-weight: 600;
    margin-top: 5px;
  }
}

.invitation-list-header-wrapper {
  justify-content: space-between;
  margin-bottom: 20px;
}

.invitation-list-header-right {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  .mat-btn + .mat-btn {
    margin-left: 0;
  }
  .inputWithSearch {
    max-width: 400px;
  }
}

.invitation-item {
  padding: 25px 30px;
  background-color: var(--white);
  border-radius: 9px;
  height: 100%;
  .list-item-field-header {
    display: flex;
    align-items: center;
  }
  .list-item-field-header {
    .item-name {
      font-size: 14px;
      font-weight: 700;
      color: var(--textColor);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .created-date {
      margin-top: 1px;
      font-size: 12px;
      color: var(--secondaryTextColor);
    }
  }
  .fields-wrapper {
    .field-item {
      border-top: 1px solid var(--borderLineColor);
      padding: 15px 0;
      .field-item-left {
        align-items: center;
        color: var(--textColor);
        display: flex;
        font-weight: 700;
        padding: 5px 0;
        width: 50%;
        svg {
          fill: var(--grey);
          margin-left: 2px;
          margin-right: 10px;
          width: 20px;
        }
      }
      .field-item-right {
        color: var(--mainColor);
        display: flex;
        font-weight: 500;
        justify-content: flex-end;
        text-align: right;
        width: 50%;
      }
    }
  }
}

.invite-list-status {
  position: relative;
  min-width: 80px;
  height: 21px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  font-size: 10px;
  font-weight: 700;
  color: var(--white);
  &.with-icon {
    padding-right: 20px;
  }
  &.waiting {
    background-color: var(--secondaryTextColor);
  }
  &.success {
    background-color: var(--green);
  }
  &.danger {
    background-color: var(--red);
  }
  svg {
    position: absolute;
    right: 8px;
    fill: var(--white);
    top: 6px;
  }
  .fields-wrapper {
    padding-top: 20px;
    .field-item {
      padding: 10px 0;
    }
  }
  .mat-btn {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .invitation-list-header-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .invitation-list-header-right {
    width: 100%;
    padding-top: 10px;
  }

  .invitation-list-header-right {
    .inputWithSearch {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 479px) {
  .invitation-list-header-right {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .invitation-list-header-right {
    .inputWithSearch {
      margin-top: 15px;
    }
  }
}
