.mat-date-picker {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border-color: var(--grey);
  padding: 0 17px;
  &.ant-picker-focused {
    box-shadow: none;
    border-color: var(--mainColor);
    background-color: var(--mainColorLighter);
    &:hover {
      border-color: var(--mainColor);
    }
  }
  &:hover {
    border-color: var(--grey);
  }
  input {
    letter-spacing: 0.58px;
    color: var(--black);
    font-size: 14px;
    &::placeholder {
      color: var(--thridyTextColor);
    }
  }
  .ant-picker-clear {
    background-color: var(--white);
    border-radius: 50%;
    right: 17px;
  }
}

.mat-date-picker-dropdown {
  .ant-picker-panel-container {
    border: 1px solid var(--grey);
    border-radius: 8px;
    box-shadow: 4px 7px 40px rgba(0, 0, 0, 0.05);
    .ant-picker-cell-today {
      .ant-picker-cell-inner {
        &::before {
          border: 1px solid var(--mainColor);
        }
      }
    }
  }
  .ant-picker-cell-today {
    .ant-picker-cell-inner {
      &::before {
        border: 1px solid var(--mainColor);
      }
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: var(--mainColor);
    }
  }
}

.mat-date-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-cell-selected
  .ant-picker-cell-inner,
.mat-date-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-cell-range-end
  .ant-picker-cell-inner,
.mat-date-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-cell-range-start
  .ant-picker-cell-inner {
  background-color: var(--mainColor);
  color: var(--white);
}

.mat-date-picker-secondary {
  height: 50px;
  background-color: var(--newLightGrey);
  border: 1px solid var(--newGrey);
  border-radius: 8px;
}

@media screen and (max-width: 590px) {
  .mat-date-picker-dropdown {
    .ant-picker-panels {
      .ant-picker-content {
        table-layout: auto;
        width: 100%;
      }
      .ant-picker-date-panel {
        width: 185px;
      }
      .ant-picker-body {
        padding: 4px 3px;
      }
      .ant-picker-cell-inner {
        font-size: 12px;
      }
    }
  }
}
@media screen and (max-width: 399px) {
  .mat-date-picker-dropdown {
    .ant-picker-panels {
      .ant-picker-date-panel {
        width: 175px;
      }
    }
  }
}
