.my_default_grid_container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
}

@media screen and (max-width: 1199px) {
  .my_default_grid_container {
    grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
  }
}

@media screen and (max-width: 991px) {
  .my_default_grid_container {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }
}

@media screen and (max-width: 479px) {
  .my_default_grid_container {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
