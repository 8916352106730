.dashboard_reputation_pulse {
  .dashboard_sent_statistics {
    margin-top: 0;
    grid-column: 1 / 4;
    .dashboard_sent_statistics_headers {
      display: flex;
      .ant-divider-vertical {
        height: 80px;
        margin: 0 20px 0 30px;
      }
    }
    .recharts-responsive-container {
      height: 385px !important;
    }
  }
}

@media screen and (max-width: 1090px) {
  .dashboard_reputation_pulse {
    .dashboard_sent_statistics {
      grid-column: 1 / 3;
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard_reputation_pulse {
    .dashboard_sent_statistics {
      .dashboard_sent_statistics_headers {
        .ant-divider-vertical {
          height: 1px;
          margin: 0 10px;
        }
      }
    }
  }
}
