.my_default_page_layout {
  .create_by_upload_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    &--title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--black);
    }
    &--footer {
      display: flex;
      padding-top: 24px;
      gap: 12px;
    }
    .default_block_wrapper {
      .csv-box-button.mat-btn {
        max-width: 100%;
        border: 2px dashed #93bdff;
        background-color: #f9fbff;
        border-radius: 8px;
        height: 193px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 100%;

        svg {
          margin-bottom: 20px;
        }

        .no-file {
          color: var(--thridyTextColor);
          font-weight: 400;
          font-size: 14px;
          max-width: 160px;
          text-align: center;
          white-space: initial;
          line-height: 25px;

          strong {
            color: var(--mainColor);
          }
        }

        .file-name {
          color: var(--mainColor);
          font-size: 14px;
        }

        > span {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
      .my_default_input_number {
        &.grey {
          padding: 10px 10px;
          text-align: center;
        }
      }
    }
  }
}
